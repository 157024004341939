import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SUBSCRIPTION_TIER, SUBSCRIPTION_TYPE } from '../models/ISubscription';
import { errorMessages } from '../services/errors';
import { useSetSettingsMutation } from '../services/settings';
import { useCreatePremiumSubscriptionMutation } from '../services/subscribtion';
import { PAGES_ENUM } from '../utils/constants';
import { handleError } from '../utils/notifications';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''
);

export const useStripeRedirectToStripePage = (isOnboarding?: boolean) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [createPremiumSubscription, { isLoading: isLoadingSubscription }] =
    useCreatePremiumSubscriptionMutation();
  const [setSettings] = useSetSettingsMutation();
  const settingsBillingLink = `${PAGES_ENUM.SETTINGS}?tab=billing-and-plans`;
  const isExtensionPayPage = location.pathname.includes('extension-pay-page');
  const isLoading = isLoadingData || isLoadingSubscription;

  const onStripeRedirectToStripePage: (reqObj?: {
    type: SUBSCRIPTION_TYPE | null;
    tier: SUBSCRIPTION_TIER | null;
  }) => Promise<void> = async (reqObj = { type: null, tier: null }) => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoadingData(true);
      const queryParams = new URLSearchParams(location.search);
      const type = reqObj.type || queryParams.get('type');
      const tier =
        reqObj.tier === SUBSCRIPTION_TIER.ZERO
          ? reqObj.tier
          : reqObj.tier || queryParams.get('tier');
      const successRedirectUrl = reqObj ? '1' : '2';

      if (!type || tier === undefined || tier === null) {
        handleError('Invalid subscription type or tier');

        if (isExtensionPayPage) {
          navigate(settingsBillingLink, { replace: true });
        }

        return;
      }

      const stripe = await stripePromise;

      if (!stripe) {
        handleError('Stripe initialization failed');

        return;
      }
      const formData = new FormData();

      formData.append('type', type);
      formData.append('tier', String(tier));
      formData.append('successRedirectUrl', successRedirectUrl);
      const data = await createPremiumSubscription(formData).unwrap();

      if (!data.id) {
        if (isExtensionPayPage) {
          navigate(settingsBillingLink, { replace: true });
        }

        return;
      }

      if (isOnboarding) {
        await setSettings({ onboarding_passed: true });
        localStorage.setItem('onboarding_passed', 'true');
      }

      const { error } = await stripe.redirectToCheckout({ sessionId: data.id });

      if (error) {
        handleError(errorMessages.FAILED_PAYMENT);
        if (isExtensionPayPage) {
          navigate(settingsBillingLink, { replace: true });
        }
      }
    } catch (error) {
      handleError(errorMessages.FAILED_SUBSCRIPTION);
      if (isExtensionPayPage) {
        navigate(settingsBillingLink, { replace: true });
      }
    } finally {
      setIsLoadingData(false);
    }
  };

  return { onStripeRedirectToStripePage, isLoading };
};
