/* eslint-disable @typescript-eslint/no-var-requires */
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/main.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { ModalsRoot } from './components/Modal/ModalsRoot';
import { ExtensionEvents } from './external/extension/service/events';
import { ExtensionEventType } from './external/extension/service/events/types';
import { store } from './redux';

import './bridge/snippets';

global.Buffer = global.Buffer || require('buffer').Buffer;

const container = document.getElementById('root');
const root = createRoot(container!); // Use createRoot for React 18+

document.addEventListener('visibilitychange', () => {
  ExtensionEvents.dispatch(ExtensionEventType.StopAllVoiceInputs);
});

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? ''}
        >
          <BrowserRouter>
            <SkeletonTheme baseColor='#414346' highlightColor='#585b5f'>
              <App />
            </SkeletonTheme>
            <ModalsRoot />
            <Toaster
              containerClassName='ktulhu-toaster'
              containerStyle={{
                zIndex: 999999999999999,
                width: '100%'
              }}
              toastOptions={{
                style: {
                  border: '1px solid var(--app-light-1a)',
                  background: 'var(--app-toaster-background)',
                  color: 'var(--app-text)',
                  display: 'flex'
                }
              }}
            />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </DndProvider>
    </Provider>
  </ErrorBoundary>
);
