import './index.scss';

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import Skeleton from 'react-loading-skeleton';

import { useGetCategoriesQuery } from '../../../../../../../../services/promptLibrary';
import { ICategory, IPrompt } from '../../../../../../interfaces';
import { ICommand } from '../../../../../../service/base/interfaces';
import { useExtensionServiceContext } from '../../../../../../service/context';
import { useAsyncProcessManagerContext } from '../../../../../../tools/async/context';
import { StringTools } from '../../../../../../tools/string';
import ArrowDownIcon from '../../../../../design/assets/svg/icons/ArrowDownIcon';
import CommandIcon from '../../../../../design/assets/svg/icons/CommandIcon';
import SearchIcon from '../../../../../design/assets/svg/icons/SearchIcon';
import { SlashIcon } from '../../../../../design/assets/svg/icons/SlashIcon';
import { Dropdown } from '../../../../../design/components/dropdown';
import { SmoothVisibility } from '../../../../../design/components/smoothVisibility';

export interface ICommandCenter {
  inputValue?: string;
  visible: boolean;
  onCommandClicked: (command: ICommand) => void;
}

export function CommandCenter(props: ICommandCenter) {
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null | undefined>();
  const [commands, setCommands] = useState<ICommand[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const extensionService = useExtensionServiceContext();
  const asyncProcessManager = useAsyncProcessManagerContext();

  const user = extensionService.useExtensionUser();

  useEffect(() => {
    if (props.inputValue !== undefined) {
      setSearchQuery(props.inputValue);
    }
  }, [props.inputValue]);

  useEffect(() => {
    if (!user.profile) return;

    asyncProcessManager?.doProcess({
      name: 'Fetch commands for selected category',
      action: async () => {
        setIsLoading(true);

        if (selectedCategory) {
          const prompts =
            ((
              await extensionService.getPromptList({
                params: {
                  categories: selectedCategory.id,
                },
              })
            )?.results as IPrompt[]) ?? [];

          setCommands(
            prompts.map(prompt => ({
              id: prompt.id,
              command: prompt.name,
              related_prompt: prompt,
              prompt: '',
              input_fields: prompt.inputs,
            }))
          );
        } else {
          const result =
            selectedCategory === null
              ? await extensionService.getQuickPromptsList()
              : await extensionService.getCommandsList();

          setCommands(result ?? []);
        }

        setIsLoading(false);
      },
    });
  }, [extensionService, user.profile, selectedCategory]);

  const { data: allCategories = { results: [] } } = useGetCategoriesQuery();

  const categories = useMemo(
    () => [
      {
        kind: 'item',
        icon: <SlashIcon width="16" />,
        text: 'Saved commands',
        active: selectedCategory === undefined,
        onClick: () => setSelectedCategory(undefined),
      },
      {
        kind: 'item',
        icon: <CommandIcon size={16} />,
        text: 'Quick prompts',
        active: selectedCategory === null,
        onClick: () => setSelectedCategory(null),
      },
      { kind: 'separator' },
      ...(allCategories.results ?? []).map((category: any) => ({
        kind: 'item',
        icon: <img src={category?.white_icon} alt="" />,
        text: category?.name,
        active: selectedCategory?.id === category?.id,
        onClick: () => setSelectedCategory(category),
      })),
    ],
    [allCategories, selectedCategory]
  );

  const filteredCommands = useMemo(
    () =>
      commands
        .filter(
          command =>
            command.command.includes(searchQuery) ||
            command.command.startsWith(searchQuery) ||
            command.command === searchQuery ||
            command.related_prompt?.description?.includes(searchQuery)
        )
        ?.sort((a, b) => {
          // Check for starts with and includes
          const aStartsWith = a.command.toLowerCase().startsWith(searchQuery.toLowerCase());
          const bStartsWith = b.command.toLowerCase().startsWith(searchQuery.toLowerCase());
          const aIncludes = a.command.toLowerCase().includes(searchQuery.toLowerCase());
          const bIncludes = b.command.toLowerCase().includes(searchQuery.toLowerCase());

          // Prioritize items that start with the query
          if (aStartsWith && !bStartsWith) {
            return -1; // a comes before b
          } else if (!aStartsWith && bStartsWith) {
            return 1; // b comes before a
          }

          // Then prioritize items that include the query anywhere
          if (aIncludes && !bIncludes) {
            return -1; // a comes before b
          } else if (!aIncludes && bIncludes) {
            return 1; // b comes before a
          }

          return 0; // keep original order if neither or both match
        }) ?? [],
    [commands, searchQuery]
  );

  return (
    <SmoothVisibility visible={props.visible} className="alchemy-command-center">
      <div className="header">
        <Dropdown items={categories as any}>
          {selectedCategory ? (
            <img src={selectedCategory?.white_icon as any} alt="" />
          ) : selectedCategory === null ? (
            <CommandIcon size={16} />
          ) : (
            <SlashIcon width="16" />
          )}
          <span>
            {selectedCategory?.name ??
              (selectedCategory === null ? 'Quick prompts' : 'Saved commands')}
          </span>

          <ArrowDownIcon />
        </Dropdown>

        <div className="search-input-wrapper">
          <SearchIcon />
          <input
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            type="text"
            placeholder="Search a prompt"
          />
        </div>
      </div>
      <div className="commands">
        {!isLoading ? (
          <>
            {filteredCommands.length > 0 ? (
              filteredCommands.map((command, index) => (
                <div
                  onClick={() => {
                    props.onCommandClicked(command);
                  }}
                  className="command"
                  key={index}
                >
                  <span className="title">
                    <Highlighter
                      highlightClassName="alchemy-highlight"
                      searchWords={[searchQuery ?? '']}
                      autoEscape={true}
                      textToHighlight={command.command}
                    />
                  </span>
                  <span className="description">
                    <Highlighter
                      highlightClassName="alchemy-highlight"
                      searchWords={[searchQuery ?? '']}
                      autoEscape={true}
                      textToHighlight={StringTools.truncate(
                        command.related_prompt?.description ?? command.prompt ?? '',
                        100
                      )}
                    />
                  </span>
                </div>
              ))
            ) : (
              <span className="empty-list">
                {commands.length > filteredCommands.length
                  ? 'No commands found for your request'
                  : 'No commands found'}
              </span>
            )}
          </>
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>
    </SmoothVisibility>
  );
}
