import { useStripeRedirectToStripePage } from '../../../../hooks/useStripeRedirectToStripePage';
import { Button } from '../../../Button';
import { ModalLayout } from '../../ModalLayout';
import * as styles from './AccountDowngradeModal.module.scss';

export const AccountDowngradeModal = ({ onClose, tier, type }: any) => {
  const { onStripeRedirectToStripePage } = useStripeRedirectToStripePage();

  const handleDowngrade = () => {
    onStripeRedirectToStripePage({ tier, type });
    onClose();
  };

  return (
    <ModalLayout title='Downgrade'>
      <div className={styles.downgradeModalContent}>
        Are you sure you want to downgrade?
      </div>
      <div className={styles.downgradeModalBtnsContainer}>
        <Button className={styles.cancelBtn} onClick={onClose} isSecondary>
          Cancel
        </Button>
        <Button className={styles.confirmBtn} onClick={handleDowngrade}>
          Yes, I&apos;m sure
        </Button>
      </div>
    </ModalLayout>
  );
};
