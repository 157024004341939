import { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useNavigate } from 'react-router-dom';

import {
  ConversationType,
  IConversation,
} from '../../../../../external/extension/service/base/ai/interfaces';
import { useExtensionServiceContext } from '../../../../../external/extension/service/context';
import EditIconAlt from '../../../../../external/extension/ui/design/assets/svg/icons/EditIconAlt';
import FolderIcon from '../../../../../external/extension/ui/design/assets/svg/icons/FolderIcon';
import { TrashIcon } from '../../../../../external/extension/ui/design/assets/svg/icons/TrashIcon';

export interface IChat {
  chat: IConversation;
  isActive: boolean;
  onAddToFolder: () => void;
  onRename: () => void;
  onDelete: () => void;
}

export function Chat({ chat, isActive, ...props }: IChat) {
  const navigate = useNavigate();

  const extensionService = useExtensionServiceContext();
  const [displayedName, setDisplayedName] = useState<string>(chat.name);
  const prevNameRef = useRef<string>(chat.name);

  useEffect(() => {
    const prevName = prevNameRef.current;

    if (prevName === 'New chat' && chat.name !== 'New chat') {
      let index = 0;

      const typeNextChar = () => {
        if (index < chat.name.length) {
          setDisplayedName(chat.name.slice(0, index + 1));
          index++;
          setTimeout(typeNextChar, 10); // Настройте скорость набора здесь
        }
      };

      typeNextChar();
    } else {
      setDisplayedName(chat.name);
    }

    prevNameRef.current = chat.name;
  }, [chat.name]);

  const [, drag] = useDrag(() => ({
    type: 'CONVERSATION_ITEM',
    item: { id: chat.id, title: chat.name },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      onClick={() => {
        const id = extensionService.encryptor.encrypt(chat.id);

        navigate(
          '/' + (chat.chat_type === ConversationType.Synth ? 'synthesize' : 'chat') + '?id=' + id
        );
      }}
      className={'chat' + (isActive ? ' active' : '')}
    >
      <span className="title">{displayedName}</span>
      <div className="hover-actions">
        <button
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();

            props.onAddToFolder();
          }}
          className="folder-button"
        >
          <FolderIcon color="currentColor" />
        </button>
        <button
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();

            props.onRename();
          }}
          className="edit-button"
        >
          <EditIconAlt color="currentColor" />
        </button>
        <button
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();

            props.onDelete();
          }}
          className="delete-button"
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
}
