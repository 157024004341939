// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal-module__modalOverlay___pl14q{display:flex;justify-content:center;align-items:center;position:fixed;inset:0;background:var(--app-background-modal-overlay);backdrop-filter:blur(6px);opacity:0;transition:all .2s ease-in-out;z-index:99999}.Modal-module__modalOverlayVisible___VruvO{opacity:1}.Modal-module__modalOverlayVisible___VruvO .Modal-module__modalCloseBtn___uXjvs{display:block}.Modal-module__modalOverlayVisible___VruvO .Modal-module__modalContent___quXdZ{transform:translateY(0)}.Modal-module__modalOverlayHidden___vlDl_{opacity:0}.Modal-module__modalOverlayHidden___vlDl_ .Modal-module__modalCloseBtn___uXjvs{display:none}.Modal-module__modalOverlayHidden___vlDl_ .Modal-module__modalContent___quXdZ{display:none}.Modal-module__modalContent___quXdZ{position:relative;width:100%;max-width:452px;background:var(--app-background-modal);border-radius:12px;transition:all .2s ease-in-out;transform:translateY(25px);margin:0 32px}@media only screen and (min-width: 768px){.Modal-module__modalContent___quXdZ{min-width:444px;margin:0}}.Modal-module__modalContent___quXdZ:focus-visible{outline:none}.Modal-module__modalCloseBtn___uXjvs{position:absolute;z-index:1;top:18px;right:18px;display:none}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.scss","webpack://./src/assets/styles/mixins/_medias.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,OAAA,CACA,8CAAA,CACA,yBAAA,CACA,SAAA,CACA,8BAAA,CACA,aAAA,CAGF,2CACE,SAAA,CAEA,gFACE,aAAA,CAGF,+EACE,uBAAA,CAIJ,0CACE,SAAA,CAEA,+EACE,YAAA,CAGF,8EACE,YAAA,CAIJ,oCACE,iBAAA,CACA,UAAA,CACA,eAAA,CACA,sCAAA,CACA,kBAAA,CACA,8BAAA,CACA,0BAAA,CACA,aAAA,CClCA,0CD0BF,oCAWI,eAAA,CACA,QAAA,CAAA,CAGF,kDACE,YAAA,CAIJ,qCACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,UAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
export var modalOverlay = `Modal-module__modalOverlay___pl14q`;
export var modalOverlayVisible = `Modal-module__modalOverlayVisible___VruvO`;
export var modalCloseBtn = `Modal-module__modalCloseBtn___uXjvs`;
export var modalContent = `Modal-module__modalContent___quXdZ`;
export var modalOverlayHidden = `Modal-module__modalOverlayHidden___vlDl_`;
export default ___CSS_LOADER_EXPORT___;
