import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { ExtensionEvents } from '../../../external/extension/service/events';
import { ExtensionEventType } from '../../../external/extension/service/events/types';
import { PAGES_ENUM } from '../../../utils/constants';

export const Billing = () => {
  useEffect(() => {
    localStorage.setItem('onboarding_passed', 'true');
    setTimeout(() => {
      ExtensionEvents.dispatch(ExtensionEventType.ShowUpgradePriceWindow);
    }, 1000);
  }, []);

  return <Navigate to={PAGES_ENUM.CHAT} />;
};
