import { MainLogo } from '../../assets/svg/MainLogo';
import { MaxWidthContainer } from '../Layouts/MaxWidthContainer';
import * as styles from './DeviceRecommendation.module.scss';

export const DeviceRecommendation = () => {
  return (
    <div className={styles.deviceRecommendation}>
      <MaxWidthContainer>
        <div className={styles.deviceRecommendationContent}>
          <MainLogo className={styles.logo} />
          <p className={styles.mobileText}>Mobile version of Alchemy is coming soon.</p>
          <p className={styles.tabletText}>Tablet version of Alchemy is coming soon.</p>
          <p className={styles.deviceRecommendationText}>
            For a better experience, please use the web version.
          </p>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
