import cn from 'classnames';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { ChainIcon } from '../../assets/svg/ChainIcon';
import { MainLogo } from '../../assets/svg/MainLogo';
import { Badge } from '../../components/Badge';
import { Button } from '../../components/Button';
import PlusIcon from '../../external/extension/ui/design/assets/svg/icons/PlusIcon';
import { ShareIcon } from '../../external/extension/ui/design/assets/svg/icons/ShareIcon';
import { Avatar } from '../../external/extension/ui/design/components/avatar';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ICustomPrompt } from '../../models/IPromptLibrary';
import { selectTheme, ThemeOptions } from '../../redux/Theme';
import { errorMessages } from '../../services/errors';
import {
  useCustomPromptClickAmountUpdateMutation,
  useCustomPromptLookupsAmountUpdateMutation,
} from '../../services/promptLibrary';
import { PAGES_ENUM } from '../../utils/constants';
import { getFormattedNumber } from '../../utils/getFormattedNumber';
import { handleError, handleSuccess } from '../../utils/notifications';
import { PromptContentField } from './components/PromptContentField';
import * as styles from './ShareCustomPrompt.module.scss';

interface IButton {
  buttonText: string;
  onClick: () => void;
  plusIcon?: boolean;
  copyIcon?: boolean;
}

type Props = {
  data: ICustomPrompt;
  alchemyButton: IButton;
  promptManageButton: IButton;
  customPromptId: string;
  isLoading: boolean;
};

export const ShareCustomPrompt: FC<Props> = ({
  data,
  alchemyButton,
  promptManageButton,
  customPromptId,
  isLoading,
}) => {
  const {
    created_at,
    categories,
    name,
    description,
    user,
    prompt_template,
    prompt_markdown_template,
    click_amount,
    amount_of_lookups,
    chain_prompt,
    steps,
    inputs,
  } = data || {};
  const [selectedChainPromptStep, setSelectedChainPromptStep] = useState<number | null>(null);
  const chainSelectedPromptMarkdownTemplate = chain_prompt
    ? steps?.find(step => step.step_number === selectedChainPromptStep)?.prompt_markdown_template
    : null;
  const { avatar, first_name, username } = user || {};
  const dateToFormat = moment(created_at);
  const isValidDate = dateToFormat.isValid();
  const formattedDate = isValidDate ? dateToFormat.format('MMM Do, YYYY') : '';
  const formattedUsageNumber = getFormattedNumber(click_amount);
  const formattedLookupsNumber = getFormattedNumber(amount_of_lookups);
  const [clickAmountUpdate] = useCustomPromptClickAmountUpdateMutation();
  const [lookupsAmountUpdate] = useCustomPromptLookupsAmountUpdateMutation();
  const theme = useTypedSelector(selectTheme);
  const authTokenFromStorage =
    localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');
  const promptTemplateToCopy =
    chain_prompt && steps?.length > 0
      ? steps.map(sp => sp.prompt_template).join('\n')
      : prompt_template;

  useEffect(() => {
    if (isLoading || !data?.chain_prompt) return;

    setSelectedChainPromptStep(steps?.[0]?.step_number);
  }, [isLoading, steps, chain_prompt]);

  const onUsePrompt = async () => {
    if (!promptTemplateToCopy) return;
    let formattedText = promptTemplateToCopy;

    inputs?.forEach(inp => {
      const inputName = inp.variable_name;

      if (formattedText.includes(`[${inputName}]`)) {
        const pattern = new RegExp(`\\[${inputName}\\]`, 'g');

        formattedText = formattedText?.replace(pattern, `#${inputName}`);
      }
    });

    try {
      const body = { prompt_extension_pk: Number(customPromptId) };

      await clickAmountUpdate(body).unwrap();
      await lookupsAmountUpdate(body).unwrap();
      await navigator.clipboard.writeText(formattedText);
      handleSuccess('Successfully copied to clipboard');
    } catch (error) {
      handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
      console.error('Use prompt failed', error);
    }
  };

  return (
    <div className={styles.sharePrompt}>
      <div className={styles.sharePromptHeader}>
        <Link
          to={authTokenFromStorage ? `${PAGES_ENUM.HOME}?tab=customPrompts` : PAGES_ENUM.SIGN_IN}
        >
          <MainLogo />
        </Link>
        <Button
          onClick={alchemyButton.onClick}
          disabled={isLoading}
          className={cn(
            {
              [styles.sharePromptPrimaryBtnGreenPreview]: isLoading && theme === ThemeOptions.Green,
            },
            {
              [styles.sharePromptPrimaryBtnPurplePreview]:
                isLoading && theme === ThemeOptions.Purple,
            }
          )}
        >
          {isLoading ? (
            <Skeleton className={styles.headerButtonLoading} />
          ) : (
            alchemyButton.buttonText
          )}
        </Button>
      </div>
      {isLoading ? (
        <SharePromptContentLoading />
      ) : (
        <>
          <div className={styles.sharePromptContent}>
            <div className={styles.sharePromptDateAndCategoryInfo}>
              <div className={styles.sharePromptDateInfo}>{`Published ${formattedDate}`}</div>
              <div className={styles.sharePromptCategories}>
                {categories?.map(category => (
                  <Badge
                    key={category?.id}
                    icon={category?.icon ?? undefined}
                    title={category?.parent?.name ? category.parent.name : category?.name}
                    color={category?.color ?? '#0BA5EC'}
                    background={category?.background_color ?? 'rgba(11, 165, 236, 0.16)'}
                    subcategory={(category?.parent && category?.name) ?? undefined}
                  />
                ))}
              </div>
            </div>
            <h1 className={styles.sharePromptTitle}>{name}</h1>
            <div className={styles.sharePromptUserAvatarAndInfo}>
              <Avatar url={avatar} userName={username} size="small" />
              <div className={styles.sharePromptUserInfo}>
                <div className={styles.sharePromptUserName}>
                  {first_name ? first_name : username ? username : ''}
                </div>
              </div>
            </div>
            <p className={styles.sharePromptDescription}>{description}</p>
            {chain_prompt && steps?.length > 0 && (
              <div className={styles.sharePromptChainList}>
                {steps.map((step, index) => (
                  <button
                    key={`${step.step_number} ${step.prompt_template?.slice(0, 2) || index}`}
                    className={cn(styles.sharePromptChainBtn, {
                      [styles.selectedPrompt]: selectedChainPromptStep === step.step_number,
                    })}
                    onClick={() => setSelectedChainPromptStep(step.step_number)}
                  >
                    <ChainIcon />
                    {`Prompt ${index + 1}`}
                  </button>
                ))}
              </div>
            )}
            <div className={styles.sharePromptMainContent}>
              <div className={styles.sharePromptContentWrapper}>
                <PromptContentField
                  promptContent={
                    chain_prompt
                      ? (chainSelectedPromptMarkdownTemplate ?? '')
                      : (prompt_markdown_template ?? '')
                  }
                  key={selectedChainPromptStep}
                />
              </div>
            </div>
            <div className={styles.sharePromptStatisticsAndButtonsContainer}>
              <div className={styles.sharePromptStatisticsInfo}>
                {(formattedUsageNumber || formattedLookupsNumber) && (
                  <>
                    {formattedLookupsNumber && (
                      <div className={styles.sharePromptViewsInfo}>
                        <div className={styles.sharePromptViewsNumber}>
                          {formattedLookupsNumber}
                        </div>
                        <div className={styles.sharePromptViewsText}>Total Views</div>
                      </div>
                    )}
                    {formattedUsageNumber && (
                      <div className={styles.sharePromptUsageInfo}>
                        <div className={styles.sharePromptUsageNumber}>{formattedUsageNumber}</div>
                        <div className={styles.sharePromptUsageText}>Total Usage</div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.sharePromptButtonsContainer}>
                <Button isSecondary onClick={onUsePrompt}>
                  Use Prompt
                </Button>
                <Button onClick={promptManageButton.onClick}>
                  {promptManageButton.plusIcon && <PlusIcon />}
                  {promptManageButton.copyIcon && <ShareIcon color="var(--app-text-on-accent)" />}
                  {promptManageButton.buttonText}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SharePromptContentLoading = () => {
  return (
    <div className={cn(styles.sharePromptContent, styles.sharePromptContentLoading)}>
      <div className={styles.sharePromptDateAndCategoryInfo}>
        <div className={styles.sharePromptDateInfo}>
          <Skeleton />
        </div>
        <div className={styles.sharePromptCategories}>
          <Skeleton />
        </div>
      </div>
      <h1 className={styles.sharePromptTitle}>
        <Skeleton className={styles.titleFirstLine} />
        <Skeleton className={styles.titleSecondLine} />
      </h1>
      <div className={styles.sharePromptUserAvatarAndInfo}>
        <Skeleton className={styles.avatarLoading} />
        <div className={styles.sharePromptUserInfo}>
          <div className={styles.sharePromptUserName}>
            <Skeleton />
          </div>
        </div>
      </div>
      <p className={styles.sharePromptDescription}>
        <Skeleton className={styles.firstLine} />
        <Skeleton className={styles.firstLine} />
        <Skeleton className={styles.secondLine} />
      </p>
      <div className={styles.sharePromptMainContent}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
      <div className={styles.sharePromptStatisticsAndButtonsContainer}>
        <div className={styles.sharePromptStatisticsInfo}>
          <>
            <div className={styles.sharePromptViewsInfo}>
              <div className={styles.sharePromptViewsNumber}>
                <Skeleton />
              </div>
              <div className={styles.sharePromptViewsText}>
                <Skeleton />
              </div>
            </div>

            <div className={styles.sharePromptUsageInfo}>
              <div className={styles.sharePromptUsageNumber}>
                <Skeleton />
              </div>
              <div className={styles.sharePromptUsageText}>
                <Skeleton />
              </div>
            </div>
          </>
        </div>
        <div className={styles.sharePromptButtonsContainer}>
          <Button isSecondary>
            <Skeleton />
          </Button>
          <Button>
            <PlusIcon />
            <Skeleton />
          </Button>
        </div>
      </div>
    </div>
  );
};
