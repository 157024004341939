import { FC, ReactNode } from 'react';

import * as styles from './ModalLayout.module.scss';

type Props = {
  title: string;
  children: ReactNode;
};

export const ModalLayout: FC<Props> = ({ title, children }) => {
  return (
    <div className={styles.modalLayout}>
      <h1 className={styles.modalLayoutTitle}>{title}</h1>
      {children}
    </div>
  );
};
