import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import {
  ExtensionEvents,
  useExtensionEventListener
} from '../../../external/extension/service/events';
import { ExtensionEventType } from '../../../external/extension/service/events/types';
import { UpgradeConfirmationModal } from '../../../external/extension/ui/components/upgrade/confirmation';
import { UpgradePricesModal } from '../../../external/extension/ui/components/upgrade/prices';
import { Sidebar } from '../../Sidebar';
import { MaxWidthContainer } from '../MaxWidthContainer';
import * as styles from './DashboardLayout.module.scss';
import { useExtensionServiceContext } from '../../../external/extension/service/context';
import { useAsyncProcessManagerContext } from '../../../external/extension/tools/async/context';

export const DashboardLayout = () => {
  const [isShowUpgradePopup, setIsShowUpgradePopup] = useState(false);
  const [isShowPricePopup, setIsShowPricePopup] = useState(false);

  useExtensionEventListener(ExtensionEventType.ShowUpgradeWindow, () => {
    setIsShowUpgradePopup(true);
  });

  useExtensionEventListener(ExtensionEventType.ShowUpgradePriceWindow, () => {
    setIsShowPricePopup(true);
  });

  const asyncProcessManager = useAsyncProcessManagerContext();
  const extensionService = useExtensionServiceContext();
  const isAuthorized = extensionService.useExtensionAuthorization();

  const [snippetsRefreshTrigger, setSnippetsRefreshTrigger] =
    useState<boolean>(false);

  useExtensionEventListener(
    ExtensionEventType.RefreshGlobalSnippets,
    () => {
      setSnippetsRefreshTrigger((prev) => !prev);
    },
    []
  );

  useEffect(() => {
    if (!isAuthorized) return;

    asyncProcessManager?.doProcess({
      name: 'Get snippets',
      action: async () => {
        const res = await extensionService.getSnippets();
        if (res) {
          ExtensionEvents.dispatch(ExtensionEventType.SetGlobalSnippets, res);
        }
      }
    });
  }, [extensionService, isAuthorized, snippetsRefreshTrigger]);

  return (
    <>
      <MaxWidthContainer>
        <div
          className={classNames(
            styles.dashboardLayoutContent,
            'dashboard-layout'
          )}
        >
          <Sidebar />
          <div
            className={classNames(
              styles.dashboardLayoutMainContent,
              'dashboard-layout-main'
            )}
          >
            <Outlet />
          </div>
        </div>
      </MaxWidthContainer>
      <UpgradePricesModal
        visible={isShowPricePopup}
        onClose={() => {
          setIsShowPricePopup(false);
        }}
      />

      <UpgradeConfirmationModal
        visible={isShowUpgradePopup}
        onUpgrade={() => {
          setIsShowPricePopup(true);
        }}
        onClose={() => setIsShowUpgradePopup(false)}
      />
    </>
  );
};
