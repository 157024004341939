import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import { IAuthResponseWithStatus } from '../../models/IAuth';
import { useGoogleRegisterMutation } from '../../services/auth';
import { errorMessages } from '../../services/errors';
import { PAGES_ENUM } from '../../utils/constants';
import { handleError } from '../../utils/notifications';

export const useGoogleSignUp = () => {
  const [googleReg] = useGoogleRegisterMutation();
  const navigate = useNavigate();
  const inviteRefCodeFromLocalStorage = localStorage.getItem('invite_ref_code');

  const googleRegistration = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const data = (await googleReg({
          ...codeResponse,
          code: inviteRefCodeFromLocalStorage
            ? inviteRefCodeFromLocalStorage
            : ''
        }).unwrap()) as IAuthResponseWithStatus;

        if (data?.status >= 200 && data?.status < 400) {
          const userData = data.user as any;
          const token = data.auth_token;

          if (userData && token) {
            localStorage.setItem('is_google_auth', 'true');
            localStorage.setItem('auth_token', token);
            localStorage.setItem('ref_code', userData.referral_code);
            localStorage.setItem(
              'show_download_extension',
              String(userData.show_again)
            );
            localStorage.setItem(
              'onboarding_passed',
              String(userData.onboarding_passed)
            );
            navigate(PAGES_ENUM.ONBOARDING_FIRST_STEP);
          }
        }
      } catch (error: any) {
        if (error?.data?.status === 'GOOGLE_ACCOUNT_ALREADY_REGISTERED') {
          handleError(errorMessages.GOOGLE_ACCOUNT_ALREADY_REGISTERED);
        } else {
          handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
          console.error('Registration Failed', error);
        }
      }
    },
    onError: () => {
      console.error('Registration Failed');
    }
  });

  return { googleRegistration };
};
