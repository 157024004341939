import classNames from 'classnames';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { LogoIcon } from '../../../assets/svg/LogoIcon';
import { RocketIcon } from '../../../assets/svg/RocketIcon';
import AlchemyLogo from '../../../external/extension/ui/design/assets/svg/AlchemyLogo';
import BlobSidebarChatsIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarChatsIcon';
import BlobSidebarClipsIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarClipsIcon';
import BlobSidebarKnowledgeIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarKnowledgeIcon';
import BlobSidebarNotesIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarNotesIcon';
import BlobSidebarPromptLibraryIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarPromptLibraryIcon';
import BlobSidebarSnippetsIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarSnippetsIcon';
import { FillerIcon } from '../../../external/extension/ui/design/assets/svg/icons/FillerIcon';
import SidebarToggleIcon from '../../../external/extension/ui/design/assets/svg/icons/SidebarToggleIcon';
import { PAGES_ENUM } from '../../../utils/constants';
import { ChatsList } from './ChatsList/ChatsList';
import { NavList } from './NavList';
import * as styles from './SidebarNavigation.module.scss';

type Props = {
  isSidebarCollapsed: boolean;
  isLoading: boolean;
  isSubscribtionFetching: boolean;
  isUpgradeNeeded: boolean;
  onCollapseButtonClicked: () => void;
};

export const SidebarNavigation: FC<Props> = ({
  isSidebarCollapsed,
  isLoading,
  isUpgradeNeeded,
  isSubscribtionFetching,
  onCollapseButtonClicked,
}) => {
  const navigate = useNavigate();

  const mainNav = [
    {
      title: 'Chats',
      icon: <BlobSidebarChatsIcon />,
      link: '/chat',
      hasNewButton: true,
      onClickOnNewButton: () => {
        navigate('/chat');
      },
    },
    {
      title: 'Prompts',
      icon: <BlobSidebarPromptLibraryIcon />,
      link: PAGES_ENUM.PROMPTS,
      subItems: [
        {
          icon: <FillerIcon />,
          title: 'Alchemy',
          link: PAGES_ENUM.PROMPTS + '?tab=all',
        },
        {
          icon: <FillerIcon />,
          title: 'Favorite',
          link: PAGES_ENUM.PROMPTS + '?tab=favorite',
        },
        {
          icon: <FillerIcon />,
          title: 'Custom',
          link: PAGES_ENUM.PROMPTS + '?tab=custom',
        },
      ],
    },
    {
      title: 'Knowledge',
      icon: <BlobSidebarKnowledgeIcon />,
      link: PAGES_ENUM.KNOWLEDGE,
    },
    {
      title: 'Notes',
      icon: <BlobSidebarNotesIcon />,
      link: PAGES_ENUM.ALL_CONTENT,
    },
    {
      title: 'Snippets',
      icon: <BlobSidebarSnippetsIcon />,
      link: PAGES_ENUM.SNIPPETS,
    },
    {
      title: 'Swipes',
      icon: <BlobSidebarClipsIcon />,
      link: PAGES_ENUM.CLIPBOARD,
    },
  ];

  return (
    <div className={styles.nav}>
      <div className={styles.navContent}>
        <div
          className={classNames(styles.sidebarLogoContainer, {
            [styles.sidebarLogoContainerCollapsed]: isSidebarCollapsed,
          })}
        >
          <Link to="/">{!isSidebarCollapsed ? <AlchemyLogo /> : <LogoIcon />}</Link>

          <button onClick={() => onCollapseButtonClicked()}>
            <SidebarToggleIcon />
          </button>
        </div>
        <NavList isSidebarCollapsed={isSidebarCollapsed} items={mainNav} isLoading={isLoading} />

        <ChatsList visible={!isSidebarCollapsed} />
        {isUpgradeNeeded && !isSubscribtionFetching && (
          <div className={styles.navCommunityAndUpgrade}>
            <Link
              className={styles.navUpgradeBtn}
              to={`${PAGES_ENUM.SETTINGS}?tab=billing-and-plans`}
            >
              <RocketIcon />
              <span>{!isSidebarCollapsed && ' Upgrade to Pro'}</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
