/* eslint-disable react/jsx-key */
import { Node } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { Highlight, themes } from 'prism-react-renderer';

const CodeBlockComponent = ({ node }: any) => {
  const code = node.textContent || '';
  const language = node.attrs.language || 'plaintext'; // Используем язык из атрибутов или javascript по умолчанию

  return (
    <NodeViewWrapper as='div' className='code-block'>
      <Highlight {...{ code, language, theme: themes.vsDark }}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.map((line: any, i: any) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token: any, key: any) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </NodeViewWrapper>
  );
};

const CodeBlockPrism = Node.create({
  name: 'codeBlock',
  group: 'block',
  content: 'text*',
  code: true,
  marks: '',
  defining: true,

  addAttributes() {
    return {
      language: {
        default: 'javascript' // Язык по умолчанию - JavaScript
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'pre'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'pre',
      HTMLAttributes,
      ['code', { class: `language-${HTMLAttributes.language}` }, 0]
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CodeBlockComponent); // Используем React-компонент
  }
});

export default CodeBlockPrism;
