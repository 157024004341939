import cn from 'classnames';
import { FC } from 'react';

import { Button } from '../../../../components/Button';
import * as styles from './StepControls.module.scss';

type Props = {
  currentStep: number;
  onSkip: () => void;
  onNext: () => void;
};

const STEPS = [
  {
    id: 1,
  },
  {
    id: 2,
  },
];

export const StepControls: FC<Props> = ({ currentStep, onSkip, onNext }) => {
  return (
    <div className={styles.stepControls}>
      <div className={styles.stepsStatus}>
        <div className={styles.stepsDisplay}>
          {STEPS.map(step => (
            <div
              key={step.id}
              className={cn(styles.step, {
                [styles.stepActive]: currentStep === step.id,
              })}
            ></div>
          ))}
        </div>
      </div>
      <div className={styles.stepBtnsWrapper}>
        <Button className={styles.stepSkipBtn} isSecondary onClick={onSkip}>
          Skip
        </Button>
        <Button className={styles.stepNextBtn} onClick={onNext}>
          Next
        </Button>
      </div>
    </div>
  );
};
