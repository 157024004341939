import { FC } from 'react';

import { AccountFormInput } from '../AccountFormInput';
import * as styles from './PairedInputs.module.scss';

interface IInput {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  autoComplete?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
}

type Props = {
  firstInput: IInput;
  secondInput: IInput;
  title: string;
};

export const PairedInputs: FC<Props> = ({ firstInput, secondInput, title }) => {
  const {
    label,
    name,
    type,
    placeholder,
    autoComplete,
    isVisible = true,
    isDisabled = false,
  } = firstInput;
  const {
    label: secondInputLabel,
    name: secondInputName,
    type: secondInputType,
    placeholder: secondInputPlaceholder,
    isVisible: secondInputIsVisible = true,
  } = secondInput;

  return (
    <div className={styles.accountFormPairedInputsAndTitle}>
      <h2 className={styles.accountFormPairedInputsTitle}>{title}</h2>
      <div className={styles.accountFormPairedInputsContainer}>
        {isVisible && (
          <AccountFormInput
            label={label}
            name={name}
            type={type}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={isDisabled}
          />
        )}
        {secondInputIsVisible && (
          <AccountFormInput
            label={secondInputLabel}
            name={secondInputName}
            type={secondInputType}
            placeholder={secondInputPlaceholder}
            autoComplete={autoComplete}
          />
        )}
      </div>
    </div>
  );
};
