import { throttle } from 'throttle-debounce';
import { ExtensionEvents } from '../external/extension/service/events';
import { ExtensionEventType } from '../external/extension/service/events/types';
import { ISnippet } from '../external/extension/service/interfaces';
import { KeyboardTools } from '../external/extension/tools/keyboard';
import { Timer } from '../external/extension/tools/events';

if (!document.querySelector('alchemy-app')) {
  let snippets: ISnippet[] = [];
  let typedCode: string | null = null;
  let cleaningTimeout: NodeJS.Timeout | null = null;
  let replaceTimeout: NodeJS.Timeout | null = null;
  let busy = false;

  ExtensionEvents.on(ExtensionEventType.SetGlobalSnippets, (e) => {
    snippets = e.data;
  });

  const clearTimeouts = () => {
    if (cleaningTimeout) clearTimeout(cleaningTimeout);
    if (replaceTimeout) clearTimeout(replaceTimeout);

    cleaningTimeout = null;
    replaceTimeout = null;
  };
  const clean = () => {
    clearTimeouts();
    typedCode = null;
  };

  const snippetsKeyboardCatchHandler = (e: KeyboardEvent) => {
    if (busy) return;

    // Function to reset typedCode and clear existing timeout
    const cleaningTimeoutCallback = () => {
      if (busy) return;

      clean();
    };

    const replaceTimeoutCallback = () => {
      if (typedCode && e.target) {
        snippets.forEach(async (snippet) => {
          if (snippet.code === typedCode?.slice(1)) {
            busy = true;
            await KeyboardTools.simulateBackspace(
              e.target as any,
              typedCode.length
            );
            await KeyboardTools.simulateTyping(e.target as any, snippet.text);
            await Timer.sleep(0.5);
            busy = false;

            clean();
          }
        });
      }
    };

    // Handle non-Backspace keys
    if (e.key !== 'Backspace') {
      // Check for '#' or timeout condition with alphanumeric and symbol validation
      if (
        (e.key === '#' || cleaningTimeout) &&
        /[a-zA-Z0-9-_#]/.test(e.key) &&
        e.key.length === 1
      ) {
        // Update the typedCode with the key pressed or reset if '#' is pressed alone
        typedCode =
          e.key === '#' && !typedCode ? '#' : (typedCode ?? '') + e.key;

        clearTimeouts();

        replaceTimeout = setTimeout(replaceTimeoutCallback, 300);
        cleaningTimeout = setTimeout(cleaningTimeoutCallback, 5000);
      }

      // Reset on Enter, Space, or Tab key
      if (
        ['Enter', ' ', 'Tab'].includes(e.key) ||
        e.altKey ||
        e.metaKey ||
        e.ctrlKey
      ) {
        clean();
      }
    } else {
      // Handle Backspace by removing the last character or setting to null if empty
      typedCode = typedCode ? typedCode.slice(0, -1) : null;
    }
  };

  if (window.location.hostname === 'docs.google.com') {
    const callback = () => {
      const googleDocsIframe = document.querySelector(
        'iframe.docs-texteventtarget-iframe'
      );

      if (googleDocsIframe instanceof HTMLIFrameElement) {
        googleDocsIframe.contentDocument?.addEventListener(
          'keydown',
          snippetsKeyboardCatchHandler
        );

        observer.disconnect();
      }
    };

    const observer = new MutationObserver(callback);

    observer.observe(document, {
      childList: true,
      subtree: true
    });
  } else {
    const fastHandleInput = (event: any) => {
      const element = event.target;

      if (element.tagName === 'BLOB-APP') return;
      const isTextInput =
        element.tagName === 'INPUT' && element.type === 'text';
      const isTextArea = element.tagName === 'TEXTAREA';
      const isContentEditable = element.isContentEditable;

      if (
        (isTextInput &&
          element.type !== 'password' &&
          element.type !== 'email') ||
        isTextArea ||
        isContentEditable
      ) {
        let text: any, cursorPosition: any;

        const selection = window.getSelection();
        if (isContentEditable) {
          if (selection && selection?.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            text = element.textContent;
            cursorPosition = range.startOffset;
          } else {
            return; // Нет активного выделения, выход
          }
        } else {
          text = element.value;
          cursorPosition = element.selectionStart;
        }

        snippets.forEach((snippet) => {
          const regex = new RegExp(`#${snippet.code}$`);
          const match = text.substring(0, cursorPosition).match(regex);
          if (match) {
            const newTextBeforeCursor =
              text.substring(0, cursorPosition - (snippet.code.length + 1)) +
              snippet.text;
            const newText =
              newTextBeforeCursor + text.substring(cursorPosition);

            if (isContentEditable) {
              element.textContent = newText;
              const newRange = document.createRange();
              newRange.setStart(element.firstChild, newTextBeforeCursor.length);
              newRange.collapse(true);
              selection?.removeAllRanges();
              selection?.addRange(newRange);
            } else {
              element.selectionStart = element.selectionEnd =
                newTextBeforeCursor.length;

              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                element instanceof HTMLInputElement
                  ? HTMLInputElement.prototype
                  : HTMLTextAreaElement.prototype,
                'value'
              )?.set;
              nativeInputValueSetter?.call(element, newText);

              const inputEvent = new Event('input', { bubbles: true });
              element.dispatchEvent(inputEvent);
            }
          }
        });
      }
    };

    const handleInput = throttle(1000, fastHandleInput, {
      noLeading: false,
      noTrailing: false
    });

    document.addEventListener('input', handleInput);
    document.addEventListener('change', handleInput);

    document.body?.addEventListener('input', fastHandleInput);
  }
}
