/* eslint-disable react-hooks/rules-of-hooks */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { AlchemyEncryptor } from '../../tools/crypto';
import { AIService } from './ai';
import {
  DEF_CATEGORY_ENUM,
  IAddToCommand,
  IAlchemyProfile,
  IAlchemySession,
  IAuthResult,
  IChatFolder,
  IChatFolderConversation,
  ICheckResetPasswordCode,
  IClickPrompt,
  ICommand,
  ICreateDocumentRequest,
  ICreatePremiumSubscriptionRequest,
  IDocument,
  IDocumentBase,
  IDocumentFolder,
  IDocumentFolderBase,
  IDocumentsAndFolders,
  ILoginRequest,
  IMoveDocumentRequest,
  IParamsGetDocumentList,
  IPrompt,
  IPromptCreation,
  IPromptListParams,
  IPromptStep,
  IReplaceExistingDocument,
  IReportingError,
  IResetPassword,
  ISendResetPasswordCode,
  ISetFavoriteRequest,
  ITextareaCommand
} from './interfaces';
import { IUploadItem, UploadManager } from './uploads';
import { ExtensionEvents } from '../events';
import { ExtensionEventType } from '../events/types';

export abstract class BaseExtensionService {
  protected static host: string =
    process.env.NODE_ENV !== 'production'
      ? 'stage.alchemy-app.com'
      : 'web.alchemy-app.com';

  protected static baseURL = `https://${this.host}/api`;

  protected disableRequests = false;

  protected axiosInstance: AxiosInstance = axios.create({
    baseURL: BaseExtensionService.baseURL,
    withCredentials: true
  });

  protected session: IAlchemySession | null = null;
  protected profile: IAlchemyProfile | null = null;

  public ai: AIService | null = null;
  public encryptor: AlchemyEncryptor = new AlchemyEncryptor(
    'QXJlIHlvdSBoYXBweT8gQmUgaG9uZXN0LiBJIGtub3cgdGhpcyB3b3JsZCBjYW4gaHVydC4uLiBJIGtub3cgaXQgbXlzZWxmLCBidXQgeW91IGNhbiBnZXQgdGhyb3VnaCBpdC4gSnVzdCBkbyBnb29kIHRoaW5ncy4gRm9yIGV4YW1wbGUsIGZlZWQgYSBzdHJheSBjYXQuIEkga25vdyB5b3UgYXJlIGEgZ29vZCBwZXJzb24uJw=='
  );

  public getHost(): string {
    return (this.constructor as any).baseURL;
  }

  abstract getSessionFromStorage(): Promise<IAlchemySession | null>;
  abstract getProfileFromStorage(): Promise<IAlchemyProfile | null>;
  abstract onSessionExpired(): void;
  abstract writeSessionToStorage(
    session: IAlchemySession | null
  ): Promise<void>;

  abstract writeProfileToStorage(
    profile: IAlchemyProfile | null
  ): Promise<void>;

  abstract clearStorageData(): Promise<void>;

  constructor(initialSession: IAlchemySession, disableRequests?: boolean) {
    this.disableRequests = disableRequests ?? false;

    if (!disableRequests) this.setup(initialSession);
  }

  static async getSessionFromGetParameters(): Promise<IAlchemySession | null> {
    const tokenFromGet = this.getTokenFromGetParameters();

    if (tokenFromGet) {
      const tier = await this.getSubscriptionTierForToken(tokenFromGet);

      return {
        token: tokenFromGet,
        tier: tier
      };
    } else {
      return null;
    }
  }

  static async getSessionFromGlobalExtensionStorage(): Promise<IAlchemySession | null> {
    return null;
  }

  async setup(initialSession: IAlchemySession): Promise<void> {
    this.session = initialSession;

    this.initializeAxios();

    if (!initialSession.token) {
      return;
    }

    this.profile = await this.getProfileFromStorage();

    if (this.session) {
      this.initializeAxios();

      this.session.tier = await this.getSubscriptionTier();

      this.initializeAxios();

      if (!this.profile || this.profile?.id === -1) {
        this.profile = await this.getProfileFromBackend();
      }
    }

    await this.writeSessionToStorage(this.session);
    await this.writeProfileToStorage(this.profile);

    this.initializeAxios();

    if (this.isAuthorized() && this.profile) {
      this.ai = new AIService(this.profile.id, this.axiosInstance);
    }

    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent('alchemyDashboardSessionRefresh', {
          detail: this.session
        })
      );
    }, 50);
  }

  static async reportError(error: IReportingError): Promise<any> {
    if (process.env.NODE_ENV === 'development') {
      return null;
    }

    const response = await axios.post(
      this.baseURL + '/user/create-error/',
      error
    );

    return response.data;
  }

  static getTokenFromGetParameters(): string | null {
    return null;
  }

  static clearTokenFromtabURL(): void {
    setTimeout(() => {
      window.history.pushState('', '', '/');
    }, 1000);
  }

  protected getAxiosInstance(customSession?: IAlchemySession): AxiosInstance {
    const session = customSession ?? this.session;

    const headers = {
      Authorization: session?.token ? `token ${session.token}` : 'none',
      'Content-Type': 'application/json'
    };

    const axiosInstance = axios.create({
      baseURL: (this.constructor as any).baseURL,
      headers,
      withCredentials: true
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          !this.disableRequests
        ) {
          this.session = null;
          try {
            document.dispatchEvent(new CustomEvent('alchemySessionSaved'));
          } catch (e) {
            console.error(e);
          }

          this.onSessionExpired();
        }

        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

  protected initializeAxios(): void {
    this.axiosInstance = this.getAxiosInstance();
  }

  protected getAxios(): AxiosInstance {
    return this.axiosInstance;
  }

  async getProfileFromBackend(): Promise<IAlchemyProfile | null> {
    const response = await this.axiosInstance.get('/user/profile/');

    return (response?.data as IAlchemyProfile) ?? null;
  }

  static async getSubscriptionTierForToken(
    token: string
  ): Promise<number | null> {
    const headers = {
      Authorization: `token ${token}`,
      'Content-Type': 'application/json'
    };

    const axiosInstance = axios.create({
      baseURL: 'https://web.alchemy-app.com/api',
      headers,
      withCredentials: true
    });

    const response = await axiosInstance.get('/user/subscription-level/');

    return response.data?.subscription_level ?? null;
  }

  async getSubscriptionTier(): Promise<number | null> {
    if (
      !this.axiosInstance.defaults.headers['Authorization'] ||
      this.axiosInstance.defaults.headers['Authorization'] === 'none'
    )
      return null;

    const response: AxiosResponse = await this.axiosInstance.get(
      `/user/subscription-level/`
    );
    const tier = response.data?.subscription_level ?? null;
    const trial_activated = response.data?.trial_activated ?? null;

    if (this.session) {
      this.session.tier = tier;
      this.session.trial_activated = trial_activated;
      await this.writeSessionToStorage(this.session);
    }

    return tier;
  }

  hasToken(): boolean {
    return !!this.session?.token;
  }

  public static async informServiceWorkerAboutSessionChange(
    session: IAlchemySession | null
  ) {
    // pass
  }

  async loginByExternalToken(token: string): Promise<void> {
    this.session = {
      token: token,
      tier: null
    };

    this.initializeAxios();

    await this.writeSessionToStorage(this.session);

    await this.setup(this.session);
  }

  async login(body: ILoginRequest): Promise<IAuthResult | null> {
    const response = await this.axiosInstance.post('/user/login/', body, {
      headers: {
        Authorization: null
      }
    });
    const data = (response?.data as IAuthResult) ?? null;

    if (data?.auth_token) {
      this.session = {
        token: data.auth_token,
        tier: data.user.subscription_tier,
        trial_activated: data.user.trial_activated
      };

      await this.writeSessionToStorage(this.session);

      this.axiosInstance.defaults.headers['Authorization'] =
        'token ' + this.session?.token;
    }

    const profile = await this.axiosInstance.get('/user/profile/');

    if (profile.data.id) {
      this.profile = profile.data;
      await this.writeProfileToStorage(profile.data);
    }

    if (this.isAuthorized() && this.profile) {
      this.ai = new AIService(this.profile.id, this.axiosInstance);
    }

    this.initializeAxios();
    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent('alchemyDashboardSessionRefresh', {
          detail: this.session
        })
      );
    }, 50);

    await BaseExtensionService.informServiceWorkerAboutSessionChange(
      this.session
    );

    return data;
  }

  async partialUpdateCurrentUser(property: string, value: any): Promise<void> {
    await this.axiosInstance.post(
      '/user/partial-update/',
      {
        [property]: value
      },
      {
        headers: {
          Authorization: null
        }
      }
    );
  }

  async passOnboarding(): Promise<void> {
    await this.partialUpdateCurrentUser('onboarding_passed', true);
  }

  async register(body: ILoginRequest): Promise<IAuthResult | null> {
    const response = await this.axiosInstance.post('/user/register/', body, {
      headers: {
        Authorization: null
      }
    });

    await this.passOnboarding();

    const data = (response?.data as IAuthResult) ?? null;

    if (data?.auth_token) {
      this.session = {
        token: data.auth_token,
        tier: data.user.subscription_tier,
        trial_activated: data.user.trial_activated
      };

      await this.writeSessionToStorage(this.session);

      this.axiosInstance.defaults.headers['Authorization'] =
        'token ' + this.session?.token;
    }

    const profile = await this.axiosInstance.get('/user/profile/');

    if (profile.data.id) {
      this.profile = profile.data;
      await this.writeProfileToStorage(profile.data);
    }

    if (this.isAuthorized() && this.profile) {
      this.ai = new AIService(this.profile.id, this.axiosInstance);
    }

    this.initializeAxios();
    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent('alchemyDashboardSessionRefresh', {
          detail: this.session
        })
      );
    }, 50);

    await BaseExtensionService.informServiceWorkerAboutSessionChange(
      this.session
    );

    return data;
  }

  async sendResetPasswordCode(body: ISendResetPasswordCode): Promise<any> {
    const response = await this.axiosInstance.post(
      '/user/reset-code-send/',
      body
    );

    return response.data;
  }

  async checkResetPasswordCode(body: ICheckResetPasswordCode): Promise<any> {
    const response = await this.axiosInstance.post(
      '/user/reset-code-check/',
      body
    );

    return response.data;
  }

  async resetPassword(body: IResetPassword): Promise<any> {
    const response = await this.axiosInstance.post(
      '/user/reset-password/',
      body
    );

    return response.data;
  }

  async logout(): Promise<any> {
    try {
      if (this.session?.token) await this.axiosInstance.get(`/user/logout/`);

      this.session = null;
      await this.writeSessionToStorage(this.session);
      this.profile = null;
      await this.writeProfileToStorage(this.profile);
      await this.clearStorageData();

      document.dispatchEvent(
        new CustomEvent('alchemyDashboardSessionRefresh', {
          detail: this.session
        })
      );
      await BaseExtensionService.informServiceWorkerAboutSessionChange(
        this.session
      );

      return null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  getSession() {
    return this.session;
  }

  getProfile() {
    return this.profile;
  }

  async getCategories(): Promise<any> {
    if (
      !this.axiosInstance.defaults.headers['Authorization'] ||
      this.axiosInstance.defaults.headers['Authorization'] === 'none'
    )
      return [];

    try {
      const response = await this.axiosInstance.get('/shop/get-categories/');

      return response.data;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getGoogleURL(newAccount: boolean): Promise<string | null> {
    try {
      const response = await this.axiosInstance.get(
        '/user/google-creds-setup/',
        {
          headers: {
            Authorization: null
          },
          params: {
            login_url: !newAccount ? 1 : undefined,
            is_sidebar: true
          }
        }
      );

      return response.data?.url ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async createPremiumSubscription(
    body: ICreatePremiumSubscriptionRequest
  ): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        `/user/create-premium-subscription/`,
        body
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getPromptList({ params }: IPromptListParams): Promise<any> {
    if (
      !this.axiosInstance.defaults.headers['Authorization'] ||
      this.axiosInstance.defaults.headers['Authorization'] === 'none'
    )
      return {
        results: []
      };

    const requestParams = { ...params };

    try {
      const isCustomCategory =
        requestParams.categories === DEF_CATEGORY_ENUM.CUSTOM;
      const isFavoriteCategory =
        requestParams.categories === DEF_CATEGORY_ENUM.FAVORITE;

      if (isFavoriteCategory) {
        requestParams.favorite = true;
        delete requestParams.categories;
      }

      if (isCustomCategory) {
        delete requestParams.categories;
        delete requestParams.favorite;
      }

      if (!isCustomCategory && !isFavoriteCategory)
        delete requestParams.favorite;

      const url = isCustomCategory
        ? 'shop/get-custom-user-prompts/'
        : '/shop/search/';

      const response = await this.axiosInstance.get(url, {
        params: requestParams
      });

      return response.data;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getSuggestedPrompts({ params }: IPromptListParams): Promise<any> {
    if (
      !this.axiosInstance.defaults.headers['Authorization'] ||
      this.axiosInstance.defaults.headers['Authorization'] === 'none'
    )
      return {
        results: []
      };

    const requestParams = { ...params };

    try {
      const url = 'shop/main-page-prompts/';

      const response = await this.axiosInstance.get(url, {
        params: requestParams
      });

      return response.data;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async addCustomCommand(body: IAddToCommand): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        `/shop/add-custom-command/`,
        body
      );

      return response;
    } catch (err) {
      const e = err as any;

      if (e.response.status === 403)
        toast.error(
          'You have reached the limit of marking prompts as commands'
        );
    }
  }

  async setFavorite(body: ISetFavoriteRequest): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        '/chat/prompt-favourite/',
        body
      );

      return response;
    } catch (err) {
      const e = err as any;

      if (e.response.status === 403)
        toast.error(
          'You have reached the limit of marking prompts as favorites'
        );
    }
  }

  async getCommands(): Promise<any> {
    if (!this.axiosInstance.defaults.headers['Authorization'])
      this.initializeAxios();

    try {
      const response = await this.axiosInstance.get('/shop/get-commands/');

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getCommandsList(): Promise<ICommand[] | null> {
    if (!this.axiosInstance.defaults.headers['Authorization'])
      this.initializeAxios();

    try {
      const response = await this.axiosInstance.get('/shop/get-commands/');

      return response?.data?.results ?? [];
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async createCustomUserPrompt(
    prompt: IPromptCreation
  ): Promise<IPrompt | undefined> {
    try {
      const promptToSend = { ...prompt };

      if (promptToSend.user === -1) {
        promptToSend.user = this.getProfile()?.id ?? null;
      }

      const response = await this.axiosInstance.post(
        '/shop/create-custom-user-prompt/',
        prompt
      );

      return response.data;
    } catch (err) {
      throw new Error('Failed to create custom prompt');
    }
  }

  async createPromptStep(steps: IPromptStep[]): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        '/shop/create-prompt-step/',
        steps
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async createInputField(inputs: IPrompt['inputs']) {
    const body = inputs.map((input) => ({
      placeholder: input.placeholder,
      user_prompt: input._prompt,
      variable_name: input.variable_name
    }));

    try {
      const response = await this.axiosInstance.post(
        '/shop/create-input-field/',
        body
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getQuickPrompts(): Promise<any> {
    if (
      !this.axiosInstance.defaults.headers['Authorization'] ||
      this.axiosInstance.defaults.headers['Authorization'] === 'none'
    )
      return null;

    try {
      const response = await this.axiosInstance.get('/shop/get-quick-prompts/');

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getQuickPromptsList(): Promise<ICommand[] | null> {
    if (
      !this.axiosInstance.defaults.headers['Authorization'] ||
      this.axiosInstance.defaults.headers['Authorization'] === 'none'
    )
      return null;

    try {
      const response = await this.axiosInstance.get('/shop/get-quick-prompts/');

      return response?.data?.results ?? [];
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  static fillPromptTemplate(
    prompt: IPrompt,
    inputValues: { [key: string]: string },
    stepIndex?: number
  ): string {
    let text =
      prompt.chain_prompt && prompt.steps.length > 0
        ? prompt.steps?.[stepIndex ?? 0].prompt_template
        : prompt.prompt_template;

    for (const input of prompt.inputs) {
      const variableName = input.variable_name;
      const value = inputValues[variableName];
      const regex = new RegExp(`\\[${variableName}\\]`, 'g');

      text = text.replace(regex, value);
    }

    return text;
  }

  static generatePromptAlias(
    prompt: IPrompt,
    inputValues: { [key: string]: string }
  ): string {
    const textParts: string[] = [];

    prompt.inputs.forEach((input) => {
      textParts.push(
        input.variable_name + ' = ' + inputValues[input.variable_name]
      );
    });

    return textParts.join('\n');
  }

  async clickSendPrompt(body: IClickPrompt): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        `/shop/prompt-extension-click-amount-update/`,
        body
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async clickCustomSendPrompt(body: IClickPrompt): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        `/shop/custom-prompt-click-amount-update/`,
        body
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async createDocument(body: ICreateDocumentRequest): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        '/shop/create-document/',
        body
      );

      return response;
    } catch (error) {
      const err = error as any; // fixes problems with err type

      toast.error(
        err.response.data.detail ??
          'You have reached the limit of document creation'
      );
      console.error('🛑 Error detected', err);
    }
  }

  async replaceExistingDocument({
    id,
    body
  }: IReplaceExistingDocument): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        `/shop/replace-existing-document/${id}`,
        body
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getRecentDocuments(
    params: IParamsGetDocumentList
  ): Promise<IDocument[] | undefined> {
    try {
      const response = await this.axiosInstance.get(
        '/shop/get-recent-documents/',
        {
          params
        }
      );

      return response.data;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getDocuments(): Promise<IDocument[] | undefined> {
    try {
      const response = await this.axiosInstance.get(
        '/shop/get-documents-categories/'
      );

      return response.data.documents;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getDocumentsAndFolders(
    categoryId?: number
  ): Promise<IDocumentsAndFolders | undefined> {
    try {
      const response = await this.axiosInstance.get(
        '/shop/get-documents-categories/',
        {
          params: {
            category_id: categoryId
          }
        }
      );

      return response.data;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getDocument(id: number): Promise<string | undefined> {
    try {
      const response = await this.axiosInstance.get(
        `/shop/get-document-content/${id}`
      );

      return response.data;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async updateDocument(id: number, body: IDocumentBase): Promise<any> {
    try {
      await this.axiosInstance.put(`/shop/update-document/${id}`, body);
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async moveDocument(id: number, body: IMoveDocumentRequest): Promise<any> {
    try {
      await this.axiosInstance.put(`/shop/move-document/${id}`, body);
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async createDocumentCategory(
    body: IDocumentFolderBase
  ): Promise<IDocumentFolder | null> {
    try {
      const response = await this.axiosInstance.post(
        `/shop/create-document-category/`,
        body
      );

      return (response.data as IDocumentFolder) ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async updateDocumentCategoryTitle(id: number, title: string): Promise<any> {
    try {
      await this.axiosInstance.post(`/shop/category-title-update/${id}`, {
        name: title
      });
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async deleteDocumentCategory(id: number): Promise<any> {
    try {
      await this.axiosInstance.post(`/shop/remove-document-category/${id}`);
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getFolders(gptUserId: string | null): Promise<IChatFolder[] | null> {
    if (gptUserId) {
      try {
        const response = await this.axiosInstance.get(
          `/shop/get-gpt-folders/${gptUserId && '?gpt_user_id=' + gptUserId}`
        );

        return (response.data as IChatFolder[]) ?? null;
      } catch (err) {
        console.error('🛑 Error detected', err);
      }
    }

    return [];
  }

  async deleteFolder(folderId: number, gptUserId: string): Promise<any> {
    try {
      if (!gptUserId) throw new Error('user id is not provided');
      const response = await this.axiosInstance.delete(
        `/shop/destroy-gpt-folder/${folderId}/${gptUserId}`
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async createFolder(folder: IChatFolder): Promise<any> {
    try {
      if (!folder.gpt_user_id) throw new Error('no user id provided');
      const response = await this.axiosInstance.post(
        '/shop/create-gpt-folder/',
        {
          color: folder.color,
          gpt_user_id: folder.gpt_user_id,
          archived: folder.archived,
          name: folder.name
        }
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async addConversationToFolder(
    id: string,
    conversationTitle: string,
    folder: IChatFolder
  ): Promise<any> {
    try {
      const response = await this.axiosInstance.post('/shop/move-gpt-chat/', {
        gpt_id: id,
        folder: folder.id,
        title: !conversationTitle ? 'New chat' : conversationTitle,
        gpt_user_id: folder.gpt_user_id
      });

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async removeConversationFromFolder(
    id: string,
    folder: IChatFolder
  ): Promise<any> {
    try {
      const response = await this.axiosInstance.post('/shop/move-gpt-chat/', {
        gpt_id: id,
        gpt_user_id: folder.gpt_user_id
      });

      return response;
    } catch (err) {
      document.dispatchEvent(new CustomEvent('alchemyRefreshChatFolders'));
      console.error('🛑 Error detected', err);
    }
  }

  async updateFolder(folder: IChatFolder): Promise<any> {
    try {
      const response = await this.axiosInstance.put(
        `/shop/partial-update-gpt-folder/${folder.id}`,
        {
          ...folder
        }
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async updateChat(
    chat: IChatFolderConversation & { gpt_user_id?: string }
  ): Promise<any> {
    try {
      const response = await this.axiosInstance.put(
        `/shop/partial-update-gpt-chat/${chat.alchemyId}`,
        {
          ...chat
        }
      );

      return response;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return chat;
  }

  static urlIsSharedPrompt(url: string) {
    const pattern =
      /^https:\/\/dashboard\.alchemy-app\.com\/prompt\/shared\/\d+$/;

    return pattern.test(url);
  }

  static getSharedPromptIdFromUrl(url: string) {
    const pattern =
      /^https:\/\/dashboard\.alchemy-app\.com\/prompt\/shared\/(\d+)$/;
    const match = url.match(pattern);

    return match ? match[1] : null;
  }

  static getPromptFromCommand(
    command: ITextareaCommand,
    allowConversionFromInputFields?: boolean
  ): IPrompt | null {
    return (
      command.related_prompt ??
      (command.input_fields &&
      command.input_fields.length > 0 &&
      allowConversionFromInputFields
        ? {
            id: -1,
            inputs: command.input_fields,
            steps: [],
            categories: [],
            is_favourite: false,
            like_amount: 0,
            is_liked: false,
            creator: {
              pk: -1,
              avatar: null,
              username: 'N/A',
              first_name: 'N/A',
              last_name: 'N/A'
            },

            is_in_command: true,
            name: command.command,
            description: 'Command with fields',
            preview_description: null,
            created_at: new Date().toISOString(),
            amount_of_lookups: -1,
            prompt_template: command.prompt,
            click_amount: -1,
            plugins: [],

            currentChainStep: 0
          }
        : null)
    );
  }

  static getPromptFromModernCommand(
    command: ICommand,
    allowConversionFromInputFields?: boolean
  ): IPrompt | null {
    return (
      command.related_prompt ??
      (command.input_fields &&
      command.input_fields.length > 0 &&
      allowConversionFromInputFields
        ? {
            id: -1,
            inputs: command.input_fields,
            steps: [],
            categories: [],
            is_favourite: false,
            like_amount: 0,
            is_liked: false,
            creator: {
              pk: -1,
              avatar: null,
              username: 'N/A',
              first_name: 'N/A',
              last_name: 'N/A'
            },

            is_in_command: true,
            name: command.command,
            description: 'Command with fields',
            preview_description: null,
            created_at: new Date().toISOString(),
            amount_of_lookups: -1,
            prompt_template: command.prompt,
            click_amount: -1,
            plugins: [],

            currentChainStep: 0
          }
        : null)
    );
  }

  navigateToDashboard(): void {
    const host = this.getHost();
    const session = this.getSession();

    if (session) {
      const newWindow = window.open(
        `${host}/login-to-dashboard?token=${session.token}`,
        '_blank'
      );

      if (newWindow) {
        newWindow.focus();
      } else {
        window.location.href = `${host}/login-to-dashboard?token=${session.token}`;
      }
    } else if (!session) {
      const newWindow = window.open(`${host}/sign-in`, '_blank');

      if (newWindow) {
        newWindow.focus();
      } else {
        window.location.href = `${host}/sign-in`;
      }
    }
  }

  static async navigationToDashboardSettingsNeeded(): Promise<boolean> {
    return false;
  }

  static currentUrlMatchAlchemyHost(): boolean {
    return window.location.hostname.includes('alchemy-app.com');
  }

  static userIsInSecureDashboardZone(): boolean {
    return (
      this.currentUrlMatchAlchemyHost() &&
      !window.location.pathname.includes('sign-in') &&
      !window.location.pathname.includes('sign-up') &&
      !window.location.pathname.includes('forgot-password')
    );
  }

  static async navigateUserToSettingsInDashboard(): Promise<void> {
    // pass
  }

  static async navigateUserToSettingsInDashboardAfterRedirect(): Promise<void> {
    // pass
  }

  isAuthorized(): boolean {
    return !!this.session?.token;
  }

  useExtensionAuthorization() {
    const [isAuthorized, setIsAuthorized] = useState<boolean>(
      this.isAuthorized()
    );

    const refreshAuthorization = (e?: any) => {
      setIsAuthorized(this.isAuthorized() || !!e?.detail?.token);
    };

    useEffect(() => {
      refreshAuthorization();

      document.addEventListener(
        'alchemyDashboardSessionRefresh',
        refreshAuthorization
      );

      return () => {
        document.removeEventListener(
          'alchemyDashboardSessionRefresh',
          refreshAuthorization
        );
      };
    }, []);

    return isAuthorized;
  }

  useExtensionUser() {
    const [session, setSession] = useState<IAlchemySession | null>(
      this.session
    );

    const [profile, setProfile] = useState<IAlchemyProfile | null>(
      this.profile
    );

    const refreshData = (e?: any) => {
      setSession(e?.detail ?? this.session);
      setProfile(this.profile);
    };

    useEffect(() => {
      refreshData();

      document.addEventListener('alchemyDashboardSessionRefresh', refreshData);

      return () => {
        document.removeEventListener(
          'alchemyDashboardSessionRefresh',
          refreshData
        );
      };
    }, []);

    return {
      session,
      profile
    };
  }

  useUploads(
    config: {
      maxFiles: number;
      concurrentUploads: number;
    } = {
      maxFiles: 10,
      concurrentUploads: 3
    }
  ) {
    const [items, setItems] = useState<IUploadItem[]>([]);
    const user = this.useExtensionUser();

    const [uploadManager] = useState(() => {
      const axiosInstance = this.getAxiosInstance(user?.session ?? undefined);

      return new UploadManager(
        axiosInstance,
        config.maxFiles,
        config.concurrentUploads
      );
    });

    useEffect(() => {
      uploadManager.onChange((newItems) => setItems(newItems));
    }, [uploadManager]);

    const upload = async (file: File) => {
      try {
        await uploadManager.upload(file, this.axiosInstance);
      } catch (e: any) {
        toast.error(e.message);
        throw e;
      }
    };

    const removeFile = (id: string) => {
      uploadManager.removeFile(id);
    };

    const clearAll = () => {
      uploadManager.clearAll();
    };

    return { items, upload, removeFile, clearAll };
  }
}
