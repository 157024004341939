// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyContentPage-module__myContentAddDocumentBtn___RoqXz{display:flex;justify-content:center;align-items:center;width:40px;height:40px;border-radius:8px;border:1px solid var(--app-dashboard-grid-item-border);background:var(--app-dashboard-grid-item);flex-shrink:0;transition:all .3s ease}.MyContentPage-module__myContentAddDocumentBtn___RoqXz svg{width:20px;height:20px}.MyContentPage-module__myContentAddDocumentBtn___RoqXz svg path{stroke:var(--titanium-400);transition:all .3s ease}.MyContentPage-module__myContentAddDocumentBtn___RoqXz:hover{background:var(--app-dashboard-grid-item-hover)}.MyContentPage-module__myContentAddDocumentBtn___RoqXz:hover svg path{stroke:var(--base-white)}.MyContentPage-module__alert___vTo6T{margin:auto}`, "",{"version":3,"sources":["webpack://./src/pages/MyContentPage/MyContentPage.module.scss"],"names":[],"mappings":"AAAA,uDACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,sDAAA,CACA,yCAAA,CACA,aAAA,CACA,uBAAA,CAEA,2DACE,UAAA,CACA,WAAA,CAEA,gEACE,0BAAA,CACA,uBAAA,CAIJ,6DACE,+CAAA,CAGE,sEACE,wBAAA,CAMR,qCACE,WAAA","sourceRoot":""}]);
// Exports
export var myContentAddDocumentBtn = `MyContentPage-module__myContentAddDocumentBtn___RoqXz`;
export var alert = `MyContentPage-module__alert___vTo6T`;
export default ___CSS_LOADER_EXPORT___;
