// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToolbarButton-module__bookmarkToolbarButton___B7RH8{display:flex;justify-content:center;align-items:center;width:28px;height:28px;border-radius:4px}.ToolbarButton-module__bookmarkToolbarButton___B7RH8 svg{width:20px;height:20px}.ToolbarButton-module__bookmarkToolbarButton___B7RH8 svg path{stroke:var(--color-titanium-050)}.ToolbarButton-module__bookmarkToolbarButton___B7RH8:hover{background:var(--arsenic-800)}.ToolbarButton-module__is_active___SwqRP{background:var(--arsenic-800)}.ToolbarButton-module__disabled___MiJB_{background:rgba(0,0,0,0)}.ToolbarButton-module__disabled___MiJB_ svg path{stroke:var(--arsenic-400)}.ToolbarButton-module__disabled___MiJB_:hover{cursor:default;background:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/pages/MyContentPage/components/ToolbarButton/ToolbarButton.module.scss"],"names":[],"mappings":"AAAA,qDACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CAEA,yDACE,UAAA,CACA,WAAA,CAEA,8DACE,gCAAA,CAIJ,2DACE,6BAAA,CAIJ,yCACE,6BAAA,CAGF,wCACE,wBAAA,CAGE,iDACE,yBAAA,CAIJ,8CACE,cAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
export var bookmarkToolbarButton = `ToolbarButton-module__bookmarkToolbarButton___B7RH8`;
export var is_active = `ToolbarButton-module__is_active___SwqRP`;
export var disabled = `ToolbarButton-module__disabled___MiJB_`;
export default ___CSS_LOADER_EXPORT___;
