import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const UnderlineIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M3.95801 16.042H16.0413"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5413 3.95801V9.16634C13.5413 11.1223 11.9557 12.708 9.99967 12.708C8.04367 12.708 6.45801 11.1223 6.45801 9.16634V3.95801"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
