import { Timer } from '../events';

export class KeyboardTools {
  public static async simulateTyping(element: HTMLElement, text: string) {
    // Create a DataTransfer object to store the text to paste
    const dataTransfer = new DataTransfer();

    dataTransfer.setData('text/plain', text);

    // Create the ClipboardEvent with the dataTransfer object
    const pasteEvent = new ClipboardEvent('paste', {
      clipboardData: dataTransfer
    });

    // Dispatch the event on the target element
    element.dispatchEvent(pasteEvent);
  }

  public static async simulateBackspace(
    element: HTMLElement,
    count = 1
  ) {
    const delay = 1; // milliseconds between key presses

    for (let i = 0; i < count; i++) {
      setTimeout(() => {
        // Create and dispatch the keydown event
        const keydownEvent = new KeyboardEvent('keydown', {
          bubbles: true,
          cancelable: true,
          code: 'Backspace',
          key: 'Backspace',
          keyCode: 8,
          which: 8
        });

        element.dispatchEvent(keydownEvent);

        // Optionally create and dispatch keypress event (not always necessary)
        const keypressEvent = new KeyboardEvent('keypress', {
          bubbles: true,
          cancelable: true,
          code: 'Backspace',
          key: 'Backspace',
          keyCode: 8,
          which: 8
        });

        element.dispatchEvent(keypressEvent);

        // Create and dispatch the keyup event
        const keyupEvent = new KeyboardEvent('keyup', {
          bubbles: true,
          cancelable: true,
          code: 'Backspace',
          key: 'Backspace',
          keyCode: 8,
          which: 8
        });

        element.dispatchEvent(keyupEvent);
      }, delay * i);
    }

    await Timer.sleep((delay * count) / 1000);
  }
}
