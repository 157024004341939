// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardLayout-module__dashboardLayoutContent___KdU4K{display:flex}.DashboardLayout-module__dashboardLayoutMainContent___MVf_i{flex-grow:1;padding:16px;padding-left:256px;min-height:100vh}.DashboardLayout-module__dashboardLayoutMainContentExtended___FM_7s{padding-left:88px}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/DashboardLayout/DashboardLayout.module.scss"],"names":[],"mappings":"AAAA,wDACE,YAAA,CAGF,4DACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CAGF,oEACE,iBAAA","sourceRoot":""}]);
// Exports
export var dashboardLayoutContent = `DashboardLayout-module__dashboardLayoutContent___KdU4K`;
export var dashboardLayoutMainContent = `DashboardLayout-module__dashboardLayoutMainContent___MVf_i`;
export var dashboardLayoutMainContentExtended = `DashboardLayout-module__dashboardLayoutMainContentExtended___FM_7s`;
export default ___CSS_LOADER_EXPORT___;
