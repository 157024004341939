import { FC, ReactNode } from 'react';

import { DocumentContentSkeleton } from '../../../pages/MyContentPage/DocumentContent/DocumentContentSkeleton';
import { DocumentCardSkeleton } from '../../DocumentCard/DocumentCardSkeleton';
import * as styles from './MyContentLayout.module.scss';

interface IProps {
  leftContent: ReactNode;
  rightContent: ReactNode;
  isLoading: boolean;
}

export const MyContentLayout: FC<IProps> = ({ leftContent, rightContent, isLoading }) => {
  return (
    <div className={styles.layout}>
      {isLoading ? (
        <>
          <div className={styles.layoutLeftContent}>
            <DocumentCardSkeleton />
          </div>
          <div className={styles.layoutRightContent}>
            <DocumentContentSkeleton />
          </div>
        </>
      ) : (
        <>
          <div className={styles.layoutLeftContent}>{leftContent}</div>
          <div className={styles.layoutRightContent}>{rightContent}</div>
        </>
      )}
    </div>
  );
};
