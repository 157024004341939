/* eslint-disable @typescript-eslint/no-unused-vars */
import './ChatsList.external.scss';

import detectUrlChange from 'detect-url-change';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { IConversation } from '../../../../external/extension/service/base/ai/interfaces';
import { IChatFolder } from '../../../../external/extension/service/base/interfaces';
import { useExtensionServiceContext } from '../../../../external/extension/service/context';
import {
  ExtensionEvents,
  useExtensionEventListener
} from '../../../../external/extension/service/events';
import { ExtensionEventType } from '../../../../external/extension/service/events/types';
import { useAsyncProcessManagerContext } from '../../../../external/extension/tools/async/context';
import { useDebounce } from '../../../../external/extension/tools/events';
import ArrowDownIcon from '../../../../external/extension/ui/design/assets/svg/icons/ArrowDownIcon';
import { confirm } from '../../../../external/extension/ui/design/components/modal/confirmation';
import { prompt } from '../../../../external/extension/ui/design/components/modal/prompt';
import { SmoothVisibility } from '../../../../external/extension/ui/design/components/smoothVisibility';
import { BackendHistoryStorageProvider } from '../../../../external/extension/ui/screens/history/providers/backend';
import { Chat } from './components/Chat';
import { FolderCreateModal } from './components/external/modal/folders/create';
import { MoveToFolderModal } from './components/external/modal/folders/move';
import { Folder } from './components/Folder';

export interface IChatsList {
  visible: boolean;
}

export function ChatsList(props: IChatsList) {
  const [isChatsExpanded, setIsChatsExpanded] = useState<boolean>(true);
  const [isFoldersExpanded, setIsFoldersExpanded] = useState<boolean>(true);

  const [modalItem, setModalItem] = useState<IConversation | null>(null);
  const [modalItemInFolder, setIsModalItemInFolder] = useState<boolean>(false);
  const [isMoveToFolderModalVisible, setIsMoveToFolderModalVisible] =
    useState<boolean>(false);
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] =
    useState<boolean>(false);

  const [chats, setChats] = useState<IConversation[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

  const extensionService = useExtensionServiceContext();
  const asyncProcessManager = useAsyncProcessManagerContext();

  const user = extensionService.useExtensionUser();
  const isAuthorized = extensionService.useExtensionAuthorization();

  const history = useMemo(() => {
    return new BackendHistoryStorageProvider(extensionService);
  }, [extensionService, isAuthorized]);

  const [currentURL, setCurrentURL] = useState<string>(window.location.href);

  const activeId = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const encryptedId = searchParams.get('id');

    if (encryptedId) {
      return extensionService.encryptor.decrypt(encryptedId);
    } else {
      return null;
    }
  }, [currentURL]);

  const [folders, setFolders] = useState<IChatFolder[]>([]);

  const [foldersRefreshTrigger, setFoldersRefreshTrigger] =
    useState<boolean>(false);

  const notFolderedChats = useMemo(
    () =>
      chats.filter(
        (c) =>
          !folders.some((f) =>
            f.chats?.some(
              (cc) => extensionService.encryptor.encrypt(c.id) === cc.gpt_id
            )
          )
      ),
    [chats, folders]
  );

  const refresh = () => setRefreshTrigger((prev) => !prev);

  useEffect(() => {
    const updateURL = () => setCurrentURL(window.location.href);

    detectUrlChange.on('change', updateURL);

    return () => {
      detectUrlChange.off('change', updateURL);
    };
  }, []);

  useEffect(() => {
    if (!user.profile) return;

    asyncProcessManager?.doProcess({
      name: 'Fetch chats',
      action: async () => {
        const result = await history.getConversationsList();

        if (result) {
          setChats(result);
        }
      }
    });
  }, [user?.profile?.id, refreshTrigger]);

  useExtensionEventListener(ExtensionEventType.RefreshConversations, () =>
    refresh()
  );

  function fetchFolders() {
    asyncProcessManager?.doProcess({
      name: 'Fetch folders',
      onError: (e) => {
        console.log(e);
      },
      action: async () => {
        const gptUserId = extensionService.encryptor.encrypt(
          user.profile?.id ?? ''
        );

        if (gptUserId) {
          const response = await extensionService.getFolders(gptUserId);

          setFolders(response ?? []);
        } else {
          setFoldersRefreshTrigger((prev) => !prev);
        }
      }
    });
  }

  async function handleFolderCreation(folder: IChatFolder) {
    await asyncProcessManager?.doProcess({
      name: 'Create folder',
      action: async () => {
        await extensionService.createFolder(folder);
      }
    });

    setFoldersRefreshTrigger((prev) => !prev);

    setIsMoveToFolderModalVisible(true);
  }

  async function handleAddConversationToFolder(
    folder: IChatFolder | null,
    conversation?: IConversation
  ) {
    if (!modalItem && !conversation) {
      toast.error('Modal conversation not found');

      return;
    }

    const chat = conversation ?? modalItem;

    await asyncProcessManager?.doProcess({
      name: 'Add / remove conversation to folder',
      action: async () => {
        if (!user.profile?.id) return;

        const userId = extensionService.encryptor.encrypt(user.profile?.id);
        const conversationId = extensionService.encryptor.encrypt(
          chat?.id ?? ''
        );

        if (folder) {
          await extensionService.addConversationToFolder(
            conversationId,
            chat?.name ?? '',
            {
              ...folder,
              gpt_user_id: userId
            }
          );
        } else {
          await extensionService.removeConversationFromFolder(conversationId, {
            gpt_user_id: userId
          } as any);
        }
      }
    });

    setFoldersRefreshTrigger((prev) => !prev);
  }

  async function handleFolderChange(color: string, name: string, id: number) {
    await asyncProcessManager?.doProcess({
      name: 'Update folder',
      action: async () => {
        if (!user.profile?.id) return;

        const userId = extensionService.encryptor.encrypt(user.profile?.id);

        await extensionService.updateFolder({
          name: name,
          archived: false,
          color: color,
          gpt_user_id: userId,
          id
        });
      }
    });

    setFoldersRefreshTrigger((prev) => !prev);
  }

  async function handleFolderDeletion(id: number | undefined) {
    if (!id) return;

    confirm({
      text: 'Are you sure you want delete this folder?',
      theme: 'blob',
      primary: true,
      danger: true,
      handleConfirm: async () => {
        await asyncProcessManager?.doProcess({
          name: 'Delete folder',
          action: async () => {
            if (!user.profile?.id) return;

            const userId = extensionService.encryptor.encrypt(user.profile?.id);

            await extensionService.deleteFolder(id, userId);
          }
        });

        setFoldersRefreshTrigger((prev) => !prev);
      }
    });
  }

  useDebounce(() => {
    if (!isMoveToFolderModalVisible && !modalItem) {
      setIsModalItemInFolder(false);
    }
  }, [isMoveToFolderModalVisible, modalItem]);

  useDebounce(
    () => {
      if (!user.profile?.id) return;

      fetchFolders();
    },
    [
      extensionService,
      extensionService,
      user.profile?.id,
      foldersRefreshTrigger
    ],
    500
  );

  return (
    <>
      {props.visible && (
        <>
          <div className='folders-and-chats'>
            <SmoothVisibility
              visible={folders.length > 0}
              className='sidebar-folders-list'
            >
              {chats.length > 0 && (
                <div
                  onClick={() => setIsFoldersExpanded((prev) => !prev)}
                  className={'heading' + (isFoldersExpanded ? ' expanded' : '')}
                >
                  <span>Folders</span>
                  <ArrowDownIcon />
                </div>
              )}
              <SmoothVisibility visible={isFoldersExpanded} className='folders'>
                {folders.map((folder, index) => {
                  const folderChats = (folder.chats ?? [])
                    ?.map((cc) =>
                      chats?.find(
                        (c) =>
                          extensionService.encryptor.encrypt(c?.id) ===
                          cc.gpt_id
                      )
                    )
                    ?.filter((f) => !!f);

                  return (
                    <Folder
                      key={index}
                      name={folder.name}
                      archived={false}
                      created_at={folder.created_at}
                      updated_at={folder.updated_at}
                      color={folder.color}
                      chats={folder.chats}
                      mustBeOpenByDefault={folderChats?.some(
                        (f) => f.id === +(activeId ?? '')
                      )}
                      onDropConversation={(item) => {
                        handleAddConversationToFolder(folder, {
                          id: +item.id,
                          name: item.title
                        } as any);
                      }}
                      chatProps={{
                        activeId: activeId ? +activeId : undefined,
                        onAddToFolder: (chat) => {
                          setIsModalItemInFolder(true);
                          setModalItem(chat);
                          setIsMoveToFolderModalVisible(true);
                        },
                        onRename: (chat) => {
                          prompt({
                            title: 'Chat Edit',
                            text: 'Please enter a new name for this chat',
                            primary: true,
                            theme: 'blob',
                            inputPlaceholder: 'New name',
                            defaultValue: chat.name,
                            handleConfirm: async (newTitle) => {
                              await extensionService.changeConversationTitle(
                                chat.id,
                                newTitle
                              );

                              ExtensionEvents.dispatch(
                                ExtensionEventType.RefreshConversations
                              );
                            }
                          });
                        },
                        onDelete: (chat) => {
                          confirm({
                            title: 'Confirm Deletion',
                            theme: 'blob',
                            primary: true,
                            text: 'Are you sure you want to delete this item? This action cannot be undone.',
                            handleConfirm: async () => {
                              await extensionService.removeSidebarConversation(
                                chat.id
                              );

                              ExtensionEvents.dispatch(
                                ExtensionEventType.RefreshConversations
                              );
                            }
                          });
                        }
                      }}
                      folderChats={folderChats}
                      onFolderDelete={() => handleFolderDeletion(folder.id)}
                      onFolderChange={(color, name) => {
                        if (!folder?.id) return;

                        handleFolderChange(color, name, folder.id);
                      }}
                    />
                  );
                })}
              </SmoothVisibility>
            </SmoothVisibility>
            <SmoothVisibility
              visible={notFolderedChats.length > 0}
              className='sidebar-chats-list'
            >
              {notFolderedChats.length > 0 && (
                <div
                  onClick={() => setIsChatsExpanded((prev) => !prev)}
                  className={'heading' + (isChatsExpanded ? ' expanded' : '')}
                >
                  <span>Chats</span>
                  <ArrowDownIcon />
                </div>
              )}
              <SmoothVisibility visible={isChatsExpanded} className='chats'>
                {notFolderedChats.map((chat, index) => (
                  <Chat
                    onAddToFolder={() => {
                      setModalItem(chat);
                      setIsMoveToFolderModalVisible(true);
                    }}
                    onRename={() => {
                      prompt({
                        title: 'Chat Edit',
                        text: 'Please enter a new name for this chat',
                        primary: true,
                        theme: 'blob',
                        inputPlaceholder: 'New name',
                        defaultValue: chat.name,
                        handleConfirm: async (newTitle) => {
                          await extensionService.changeConversationTitle(
                            chat.id,
                            newTitle
                          );

                          ExtensionEvents.dispatch(
                            ExtensionEventType.RefreshConversations
                          );
                        }
                      });
                    }}
                    onDelete={() => {
                      confirm({
                        title: 'Confirm Deletion',
                        theme: 'blob',
                        primary: true,
                        text: 'Are you sure you want to delete this item? This action cannot be undone.',
                        handleConfirm: async () => {
                          await extensionService.removeSidebarConversation(
                            chat.id
                          );

                          ExtensionEvents.dispatch(
                            ExtensionEventType.RefreshConversations
                          );
                        }
                      });
                    }}
                    key={index}
                    {...{ chat, isActive: chat.id.toString() === activeId }}
                  />
                ))}
              </SmoothVisibility>
            </SmoothVisibility>
          </div>
        </>
      )}
      <MoveToFolderModal
        isVisible={isMoveToFolderModalVisible}
        modalItemInFolder={modalItemInFolder}
        onClose={() => {
          setIsMoveToFolderModalVisible(false);
          setModalItem(null);
        }}
        folders={folders}
        onFolderCreate={() => {
          setIsMoveToFolderModalVisible(false);
          setIsCreateFolderModalVisible(true);
        }}
        onAddConversationToFolder={(folder) => {
          setIsMoveToFolderModalVisible(false);
          handleAddConversationToFolder(folder);
        }}
      />
      <FolderCreateModal
        visible={isCreateFolderModalVisible}
        onClose={() => {
          setIsCreateFolderModalVisible(false);
        }}
        onCreate={handleFolderCreation}
      />
    </>
  );
}
