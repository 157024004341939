// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeviceRecommendation-module__deviceRecommendation___bITN8{background-repeat:no-repeat;background-size:cover;padding:24px 32px}@media only screen and (min-width: 1201px){.DeviceRecommendation-module__deviceRecommendation___bITN8{display:none}}.DeviceRecommendation-module__deviceRecommendationContent___nGGc6{display:flex;flex-direction:column;text-align:left}.DeviceRecommendation-module__logo___KozN4{width:90px;height:20px;margin-right:auto;margin-bottom:40px}@media only screen and (min-width: 768px){.DeviceRecommendation-module__logo___KozN4{width:126px;height:28px}}.DeviceRecommendation-module__tabletText___MP4Pz,.DeviceRecommendation-module__mobileText___CIdeN{display:none;font-size:24px;font-weight:500;line-height:125%;letter-spacing:-0.48px;color:#f5f5f5;letter-spacing:-0.48px;margin-bottom:8px}.DeviceRecommendation-module__deviceRecommendationText___TDVDG{font-size:14px;font-weight:400;line-height:140%;color:#cfcfcf}@media(max-width: 767px){.DeviceRecommendation-module__mobileText___CIdeN{display:block}}@media(min-width: 768px)and (max-width: 1200px){.DeviceRecommendation-module__tabletText___MP4Pz{display:block}}`, "",{"version":3,"sources":["webpack://./src/components/DeviceRecommendation/DeviceRecommendation.module.scss","webpack://./src/assets/styles/mixins/_medias.scss"],"names":[],"mappings":"AAEA,2DACE,2BAAA,CACA,qBAAA,CACA,iBAAA,CCsCA,2CDzCF,2DAMI,YAAA,CAAA,CAIJ,kEACE,YAAA,CACA,qBAAA,CACA,eAAA,CAGF,2CACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CCTA,0CDKF,2CAOI,WAAA,CACA,WAAA,CAAA,CAIJ,kGAEE,YAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,sBAAA,CACA,aAAA,CACA,sBAAA,CACA,iBAAA,CAGF,+DACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,aAAA,CAGF,yBACE,iDACE,aAAA,CAAA,CAIJ,gDACE,iDACE,aAAA,CAAA","sourceRoot":""}]);
// Exports
export var deviceRecommendation = `DeviceRecommendation-module__deviceRecommendation___bITN8`;
export var deviceRecommendationContent = `DeviceRecommendation-module__deviceRecommendationContent___nGGc6`;
export var logo = `DeviceRecommendation-module__logo___KozN4`;
export var tabletText = `DeviceRecommendation-module__tabletText___MP4Pz`;
export var mobileText = `DeviceRecommendation-module__mobileText___CIdeN`;
export var deviceRecommendationText = `DeviceRecommendation-module__deviceRecommendationText___TDVDG`;
export default ___CSS_LOADER_EXPORT___;
