import { Navigate, Outlet } from 'react-router-dom';

import { PAGES_ENUM } from '../../utils/constants';

export const OnboardingRedirect = () => {
  const isOnboardingPassed =
    JSON.parse(localStorage.getItem('onboarding_passed') ?? 'false') ||
    JSON.parse(sessionStorage.getItem('onboarding_passed') ?? 'false');

  if (isOnboardingPassed) {
    return <Navigate to={PAGES_ENUM.HOME} />;
  }

  return <Outlet />;
};
