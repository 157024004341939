import { Form, Formik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';

import { Button } from '../../../../components/Button';
import { ModalLayout } from '../../../../components/Modal/ModalLayout';
import { errorMessages } from '../../../../services/errors';
import { useSendDestroyLinkMutation } from '../../../../services/settings';
import { handleError } from '../../../../utils/notifications';
import { AccountFormInput } from '../AccountForm/AccountFormInput';
import * as styles from './DeleteAccountModal.module.scss';

type Props = {
  onClose: () => void;
};

export const DeleteAccountModal: FC<Props> = ({ onClose }) => {
  const [sendDestroyLink, { isSuccess, isLoading }] = useSendDestroyLinkMutation();

  const handleDeleteBtnClick = async (values: { email: string }) => {
    try {
      await sendDestroyLink({ email: values.email }).unwrap();
    } catch (error) {
      handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
    }
  };

  return (
    <ModalLayout title="Delete Account">
      <div className={styles.deleteAccountModalContent}>
        <>
          {isSuccess ? (
            <>
              <p className={styles.deleteAccountModalDescription}>
                We have sent a link to your email to confirm the deletion. Please check your email
                to complete this process.
              </p>
              <Button onClick={onClose} type="button" className={styles.deleteAccountConfirmBtn}>
                Ok
              </Button>
            </>
          ) : (
            <>
              <p className={styles.deleteAccountModalDescription}>
                Are you sure? Your account will be closed immediately and you will no longer have
                access.
              </p>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={Yup.object({
                  email: Yup.string().email('Invalid email address').required('Enter your email'),
                })}
                onSubmit={handleDeleteBtnClick}
              >
                <Form className={styles.deleteAccountModalForm}>
                  <AccountFormInput
                    label="E-mail adress"
                    name="email"
                    type="email"
                    placeholder="Email address (for confirmation)"
                    autoComplete="off"
                  />
                  <div className={styles.deleteAccounModalBtnsContainer}>
                    <Button onClick={onClose} isSecondary type="button">
                      Cancel
                    </Button>
                    <Button disabled={isLoading} isRed>
                      Delete account
                    </Button>
                  </div>
                </Form>
              </Formik>
            </>
          )}
        </>
      </div>
    </ModalLayout>
  );
};
