export enum PAGES_ENUM {
  HOME = '/',
  TERMS_OF_SERVICE = '/terms-of-service',
  SETTINGS = '/settings',
  NOT_FOUND_404 = '*',
  SIGN_UP = '/sign-up',
  SIGN_IN = '/sign-in',
  SHARE_CUSTOM_PROMPT = '/prompt/share',
  SHARE_CUSTOM_PROMPT_PARAMETRIZED = '/prompt/share/:id',
  SHARED_CUSTOM_PROMPT = '/prompt/shared',
  SHARED_CUSTOM_PROMPT_PARAMETRIZED = '/prompt/shared/:id',
  TRASH = '/trash',
  STRIPE_PAY_PAGE = '/stripe-pay-page',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  CODE_CONFIRMATION = '/code-confirmation',
  ALL_CONTENT = '/notes',
  ONBOARDING_FIRST_STEP = '/onboarding/first-step',
  ONBOARDING_SECOND_STEP = '/onboarding/second-step',
  ONBOARDING_THIRD_STEP = '/onboarding/third-step',
  ONBOARDING_PAYMENT = '/onboarding/payment',
  ONBOARDING_WAITLIST = '/onboarding/waitlist',
  DESTROY_ACCOUNT = '/destroy-account/',
  SNIPPETS = '/snippets',
  CHAT = '/chat',
  CHAT_HISTORY = '/chat/history',
  CLIPBOARD = '/clips',
  PROMPTS = '/prompts',
  SYNTH = '/synthesize',
  AI_SEARCH = '/search',
  KNOWLEDGE = '/knowledge',
  DEMO = '/demo/:slug'
}

export enum ERROR_STATUS_ENUM {
  NOT_AUTHORIZED = 401,
  SUBSCRIPTION = 402,
  NOT_FOUND = 404,
}
