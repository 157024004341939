/* eslint-disable @typescript-eslint/no-unused-vars */
import '../index.scss';
import '../prose.scss';

import { createRef, Dispatch, SetStateAction, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import { IPrompt } from '../../../../interfaces';
import { AlchemyModel, IMessage } from '../../../../service/base/ai/interfaces';
import { PromptDetailsModal } from '../../../components/auth/modal/prompt';
import { SmoothVisibility } from '../../../design/components/smoothVisibility';
import { Dialog } from '../components/dialog';
import { PromptTextarea } from '../components/textarea';
import { ExtensionService } from '../../../../service';

export interface IAnonymousChatScreen {
  onCancel: () => void;
  onSend: (
    text: string,
    alias: string,
    inputs: { [key: string]: string }
  ) => void;

  modalPrompt: IPrompt | null;
  isPromptModalVisible: boolean;
  isLimitReached: boolean;
  renderingMessage: IMessage | null;
  isGenerating: boolean;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
}

export function AnonymousChatScreen({
  onCancel,
  onSend,
  modalPrompt,
  isPromptModalVisible,
  renderingMessage,
  isGenerating,
  isLimitReached
}: IAnonymousChatScreen) {
  const dialogWrapperRef = createRef<HTMLDivElement>();

  const isPromptSending = isGenerating;

  const allMessages = useMemo(
    () => [
      ...(renderingMessage
        ? [{ ...renderingMessage, _generating: isGenerating }]
        : [])
    ],
    [renderingMessage]
  );

  const textareaWrapperRef = createRef<HTMLDivElement>();

  return (
    <>
      <SmoothVisibility className='smooth-section' visible={true}>
        <div
          ref={dialogWrapperRef}
          className={
            `dialog-wrapper` + (isLimitReached ? ' limit-reached' : '')
          }
        >
          <Dialog
            noBranding={true}
            noEdit={true}
            noSnippets={true}
            hideButtons={true}
            limitReached={isLimitReached}
            model={AlchemyModel.Claude35Sonnet}
            messages={allMessages as any}
            onEditMessage={() =>
              toast.error('Only available for authorized users')
            }
            isPromptSending={isPromptSending}
            onSubmitSuggestion={() => toast.error('Not available')}
            onRegenerateMessage={() =>
              toast.error('Only available for authorized users')
            }
            onMoveToChat={() => {
              toast.error('Not available');
            }}
            synthModeActive={false}
            onSaveMessage={() =>
              toast.error('Only available for authorized users')
            }
          />
        </div>
        <SmoothVisibility visible={true} className={'bottom-bar-wrapper'}>
          <div
            style={
              {
                '--app-panel-offset': `0px`,
                opacity: isLimitReached ? 0.5 : 1,
                transition: 'all 0.3s ease',
                pointerEvents: 'none',
                cursor: 'not-allowed'
              } as any
            }
            className='bottom-bar'
          >
            <PromptTextarea
              containerRef={textareaWrapperRef}
              onPaste={() => toast.error('Only available for authorized users')}
              conversationModel={AlchemyModel.Claude35Sonnet}
              isPromptSending={isPromptSending}
              onSend={() => {
                return;
              }}
              prompt={''}
              setConversationModel={() => toast.error('Not available')}
              setPrompt={() => {}}
              modelDropdownVerticalPosition='top'
              hideModels={true}
              disableCommands={true}
              maxRows={7}
            />
          </div>
        </SmoothVisibility>
      </SmoothVisibility>
      <PromptDetailsModal
        visible={isPromptModalVisible}
        prompt={modalPrompt}
        onClose={(success) => {
          if (!success) onCancel();
        }}
        onPromptUpdate={() => {
          toast.error('Only available for authorized users');
        }}
        onEdit={() => {
          toast.error('Only available for authorized users');
        }}
        onClickSendPrompt={(inputs) => {
          if (!modalPrompt) return;

          const promptText = ExtensionService.fillPromptTemplate(
            modalPrompt,
            inputs
          );
          const promptAlias = ExtensionService.generatePromptAlias(
            modalPrompt,
            inputs
          );

          onSend(promptText, promptAlias, inputs);
        }}
        isCustomCategory={false}
      />
    </>
  );
}
