import { FC, ReactNode } from 'react';

import { MaxWidthContainer } from '../../../../components/Layouts/MaxWidthContainer';
import { StepControls } from '../StepControls';
import * as styles from './OnboardingStepLayout.module.scss';

type Props = {
  children: ReactNode;
  onSkip: () => void;
  onNext: () => void;
  title: string;
  description: string;
  currentStep: number;
  headerClassName?: string;
};

export const OnboardingStepLayout: FC<Props> = ({
  children,
  onSkip,
  onNext,
  title,
  description,
  currentStep,
  headerClassName,
}) => {
  return (
    <div className={styles.onboardingStep}>
      <MaxWidthContainer className={styles.onboardingStepWrapper}>
        <div className={styles.onboardingStepContent}>
          <main className={styles.onboardingStepMainContent}>
            <div className={styles.onboardingStepTitleAndDescription}>
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
            {children}
          </main>
          <StepControls currentStep={currentStep} onSkip={onSkip} onNext={onNext} />
        </div>
      </MaxWidthContainer>
    </div>
  );
};
