import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { handleError } from '../../../../../../utils/notifications';
import * as styles from './HashtagList.module.scss';

interface IItem {
  id: number;
  variable_name: string;
}

interface HashtagListProps {
  items: IItem[];
  command: (args: { id: string }) => void;
  query: string;
}

interface HashtagListRef {
  onKeyDown: ({ event }: { event: React.KeyboardEvent }) => boolean;
}

export const HashtagList = forwardRef<HashtagListRef, HashtagListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const selectItem = (index: number, isChosenByClick: boolean) => {
    const item = props.items[index];

    if (item?.variable_name?.length > 99) {
      handleError('The length of the hashtag should not exceed 100 characters');

      return;
    }

    if ((item && item.variable_name === props.query) || (item && isChosenByClick)) {
      props.command({ id: item.variable_name });
    } else if (props.query) {
      props.command({ id: props.query });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex, false);
  };

  useEffect(() => {
    setSelectedIndex(0);
  }, [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        upHandler();

        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();

        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();

        return true;
      }

      return false;
    },
  }));

  return (
    <div className={styles.hashtags}>
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={styles.hashtag}
            key={index}
            onClick={() => selectItem(index, true)}
            type="button"
          >
            {item.variable_name}
          </button>
        ))
      ) : (
        <div className={styles.hashtag}>No options</div>
      )}
    </div>
  );
});

HashtagList.displayName = 'HashtagList';
