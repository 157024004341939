import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const OrderedListIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M3.95801 5.20801L5.20801 3.95801V8.54134M5.20801 8.54134H3.95801M5.20801 8.54134H6.04134"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0413 11.875H9.7132C9.10425 11.875 8.76485 11.1966 9.08783 10.7145C9.12762 10.6551 9.18394 10.6093 9.24346 10.5697L10.7433 9.57163C10.8029 9.53202 10.859 9.48602 10.8996 9.4272C11.2708 8.88986 10.8924 8.125 10.2102 8.125H8.95801"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.958 12.292H15.1633C16.192 12.292 16.3746 13.865 15.4248 14.13C15.3787 14.1429 15.3305 14.148 15.2828 14.1497L14.7913 14.167L15.2828 14.1843C15.3305 14.186 15.3787 14.1911 15.4248 14.204C16.3746 14.469 16.192 16.042 15.1633 16.042H13.958"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
