import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { BooksIcon } from '../../../../assets/svg/BooksIcon';
import { CustomPromptsIcon } from '../../../../assets/svg/CustomPromptsIcon';
import { FilePlusIcon } from '../../../../assets/svg/FilePlusIcon';
import { StarIcon } from '../../../../assets/svg/StarIcon';
import { useStripeRedirectToStripePage } from '../../../../hooks/useStripeRedirectToStripePage';
import { SUBSCRIPTION_TIER, SUBSCRIPTION_TYPE } from '../../../../models/ISubscription';
import { PAGES_ENUM } from '../../../../utils/constants';
import { Button } from '../../../Button';
import * as styles from './ProSubscribtionModal.module.scss';
import { SubscribtionType } from './SubscribtionType';

const SUBSCRIBTION_TYPES = [
  {
    id: 1,
    title: 'Annually',
    price: 'US $10.99',
    type: SUBSCRIPTION_TYPE.YEAR,
  },
  {
    id: 2,
    title: 'Monthly',
    price: 'US $12.99',
    type: SUBSCRIPTION_TYPE.MONTH,
  },
];

interface IProps {
  onClose: () => void;
}

export const ProSubscribtionModal: FC<IProps> = ({ onClose }) => {
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(SUBSCRIPTION_TYPE.YEAR);

  const { onStripeRedirectToStripePage, isLoading } = useStripeRedirectToStripePage();

  const handleSubscribtion = () => {
    onStripeRedirectToStripePage({ tier: SUBSCRIPTION_TIER.ONE, type: selectedSubscriptionType });
  };

  return (
    <div className={styles.proModal}>
      <div className={styles.proModalTitleAndDescription}>
        <h1>Alchemy Pro</h1>
        <p className={styles.proModalDescribtion}>
          Upgrade to Alchemy Pro for unlimted access to prompt library, custom prompts, and easy
          content management.
        </p>
      </div>
      <div className={styles.proModalBenefits}>
        <div className={styles.proModalBenefit}>
          <BooksIcon />
          Access full prompt library
        </div>
        <div className={styles.proModalBenefit}>
          <CustomPromptsIcon />
          Unlimited Custom Prompts
        </div>
        <div className={styles.proModalBenefit}>
          <StarIcon />
          Unlimited Favorite Prompts
        </div>
        <div className={styles.proModalBenefit}>
          <FilePlusIcon />
          Unlimited Documents
        </div>
      </div>
      <div className={styles.proModalSubscribtionTypes}>
        {SUBSCRIBTION_TYPES.map(item => {
          const { id, title, price, type } = item;

          return (
            <SubscribtionType
              key={id}
              title={title}
              price={price}
              type={type}
              isSelected={selectedSubscriptionType === type}
              onSelectType={() => setSelectedSubscriptionType(type)}
            />
          );
        })}
      </div>
      <Button className={styles.subscribtionBtn} onClick={handleSubscribtion} disabled={isLoading}>
        Start 7-day free trial
      </Button>
      <div className={styles.subscribtionAgreementText}>
        By continuing, you acknowledge that you have read and understood, and agree to Alchemy’s{' '}
        {
          <Link to={PAGES_ENUM.TERMS_OF_SERVICE} onClick={onClose}>
            Terms of Service
          </Link>
        }{' '}
        and{' '}
        {
          <a
            href="https://alchemy-app.notion.site/Privacy-Policy-ef58f155eb6b469eb144fa4907cce6aa"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        }{' '}
      </div>
    </div>
  );
};
