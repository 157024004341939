import './KnowledgePage.external.scss';

import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { MainContentLayout } from '../../components/Layouts/MainContentLayout';
import { useExtensionServiceContext } from '../../external/extension/service/context';
import {
  IKnowledge,
  IKnowledgeBase,
  IKnowledgePageContent
} from '../../external/extension/service/interfaces';
import { useAsyncProcessManagerContext } from '../../external/extension/tools/async/context';
import { LoadingOverlay } from '../../external/extension/ui/design/components/loading';
import { confirm } from '../../external/extension/ui/design/components/modal/confirmation';
import { SmoothVisibility } from '../../external/extension/ui/design/components/smoothVisibility';
import { KnowledgeItem } from './components/KnowledgeItem';
import { KnowledgeModal } from './modal/KnowledgeModal';

export function KnowledgePage() {
  const asyncProcessManager = useAsyncProcessManagerContext();
  const extensionService = useExtensionServiceContext();

  const user = extensionService.useExtensionUser();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
  const [content, setContent] = useState<IKnowledgePageContent | null>(null);

  const [knowledgeModalItem, setKnowledgeModalItem] =
    useState<IKnowledge | null>(null);
  const [knowledgeModalVisible, setKnowledgeModalVisible] =
    useState<boolean>(false);
  const [showAllKnowledge, setShowAllKnowledge] = useState<boolean>(false);
  const [showAllSourceMaterials, setShowAllSourceMaterials] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredKnowledgeItems = content?.knowledge
    ?.filter(
      (item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.url?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.text?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    ?.sort((a, b) => {
      const aTitleMatch = a.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const bTitleMatch = b.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      if (aTitleMatch && !bTitleMatch) return -1;
      if (!aTitleMatch && bTitleMatch) return 1;

      const aUrlMatch = a.url
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const bUrlMatch = b.url
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());

      if (aUrlMatch && !bTitleMatch) return -1;
      if (!aUrlMatch && bUrlMatch) return 1;

      const aTextMatch = a.text
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const bTextMatch = b.text
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());

      return aTextMatch && !bTextMatch ? -1 : 1;
    });

  const filteredSourceMaterialItems = content?.source
    ?.filter(
      (item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.url?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.text?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    ?.sort((a, b) => {
      const aTitleMatch = a.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const bTitleMatch = b.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      if (aTitleMatch && !bTitleMatch) return -1;
      if (!aTitleMatch && bTitleMatch) return 1;

      const aUrlMatch = a.url
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const bUrlMatch = b.url
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());

      if (aUrlMatch && !bTitleMatch) return -1;
      if (!aUrlMatch && bUrlMatch) return 1;

      const aTextMatch = a.text
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const bTextMatch = b.text
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());

      return aTextMatch && !bTextMatch ? -1 : 1;
    });

  const visibleKnowledgeItems = showAllKnowledge
    ? filteredKnowledgeItems
    : filteredKnowledgeItems?.slice(0, 4);

  const visibleSourceMaterialItems = showAllSourceMaterials
    ? filteredSourceMaterialItems
    : filteredSourceMaterialItems?.slice(0, 4);

  const knowledgeItemsExists =
    content &&
    !!content.knowledge &&
    content.knowledge.length > 0 &&
    (filteredKnowledgeItems?.length ?? 0) > 0;
  const sourceMaterialItemsExists =
    content &&
    !!content.source &&
    content.source.length > 0 &&
    (filteredSourceMaterialItems?.length ?? 0) > 0;

  const handleShowMoreKnowledgeItemsClick = () => {
    setShowAllKnowledge(!showAllKnowledge);
  };

  const handleShowMoreSourceMaterialItemsClick = () => {
    setShowAllSourceMaterials(!showAllSourceMaterials);
  };

  const refresh = () => {
    setRefreshTrigger((prev) => !prev);
  };

  const handleKnowledgeItemEdit = (knowledge: any) => {
    setKnowledgeModalVisible(true);
    setKnowledgeModalItem(knowledge);
  };

  const handleKnowledgeItemCreate = () => {
    setKnowledgeModalItem(null);
    setKnowledgeModalVisible(true);
  };

  const handleKnowledgeModalCancel = () => {
    setKnowledgeModalVisible(false);

    setTimeout(() => {
      setKnowledgeModalItem(null);
    }, 500);
  };
  const handleKnowledgeModalSubmission = async (
    item: IKnowledgeBase,
    isSource: boolean
  ) => {
    if (knowledgeModalItem) {
      await asyncProcessManager?.doProcess({
        name: 'Update knowledge',
        action: async () => {
          await extensionService.updateKnowledge(
            knowledgeModalItem.id,
            isSource ? { ...item, category: null } : { ...item, url: null }
          );
        }
      });
    } else {
      await asyncProcessManager?.doProcess({
        name: 'Create knowledge',
        action: async () => {
          await extensionService.createKnowledge(item);
        }
      });
    }

    setKnowledgeModalVisible(false);

    refresh();

    setTimeout(() => {
      setKnowledgeModalItem(null);
    }, 500);
  };

  useEffect(() => {
    if (!user.profile) return;

    asyncProcessManager?.doProcess({
      name: 'Fetch knowledge page content',
      onError: () => {
        setIsLoading(false);

        return undefined;
      },
      action: async () => {
        setIsLoading(true);

        const result = await extensionService.getKnowledgePageContent();

        setContent(result);
        setIsLoading(false);
      }
    });
  }, [user.profile?.id, refreshTrigger]);

  const handleCopy = (item: IKnowledge) => {
    navigator.clipboard.writeText(item.text).then(() => {
      toast.success('Copied to clipboard');
    });
  };

  const handleDelete = async (item: IKnowledge) => {
    confirm({
      text: 'Are you sure you want delete this item?',
      theme: 'blob',
      confirmButtonText: 'Delete',
      danger: true,
      primary: true,
      handleConfirm: async () => {
        await asyncProcessManager?.doProcess({
          name: 'Delete knowledge',
          action: async () => {
            await extensionService.deleteKnowledge(item.id);
          }
        });

        refresh();
      }
    });
  };

  return (
    <>
      <MainContentLayout
        title='Knowledge'
        searchBarName='knowledge'
        searchBarPlaceholder='Search a knowledge'
        searchValue={searchQuery}
        onInstantChange={(q) => setSearchQuery(q)}
        isSearchDisabled={false}
        actionButton={{
          children: (
            <>
              <span>+</span>
              <span>Create new</span>
            </>
          ),
          onClick: () => handleKnowledgeItemCreate()
        }}
        className='knowledge-page-wrapper'
      >
        <SmoothVisibility visible={!isLoading} className='knowledge-page'>
          <div className='knowledge-section'>
            <div className='section-header'>
              <h3>Knowledge</h3>
            </div>
            {knowledgeItemsExists ? (
              <div className='items'>
                {visibleKnowledgeItems?.map((item, index) => (
                  <KnowledgeItem
                    onEdit={handleKnowledgeItemEdit}
                    onCopy={handleCopy}
                    onDelete={handleDelete}
                    isSource={false}
                    item={item}
                    key={index}
                    searchQuery={searchQuery}
                  />
                ))}
                {(filteredKnowledgeItems?.length ?? 0) > 4 &&
                  !showAllKnowledge && (
                    <div
                      className='show-more'
                      onClick={handleShowMoreKnowledgeItemsClick}
                    >
                      <span className='count'>
                        {(filteredKnowledgeItems?.length ?? 0) - 4}
                      </span>
                      <span className='label'>more</span>
                    </div>
                  )}
              </div>
            ) : (
              <div className='empty-list'>
                {searchQuery.length > 0
                  ? 'No knowledge items found for your request'
                  : 'Add your first knowledge'}
              </div>
            )}
          </div>
          <div className='knowledge-section'>
            <div className='section-header'>
              <h3>Source Material</h3>
            </div>
            {sourceMaterialItemsExists ? (
              <div className='items'>
                {visibleSourceMaterialItems?.map((item, index) => (
                  <KnowledgeItem
                    onEdit={handleKnowledgeItemEdit}
                    onCopy={handleCopy}
                    onDelete={handleDelete}
                    isSource={true}
                    item={item}
                    key={index}
                    searchQuery={searchQuery}
                  />
                ))}
                {(filteredSourceMaterialItems?.length ?? 0) > 4 &&
                  !showAllSourceMaterials && (
                    <div
                      className='show-more'
                      onClick={handleShowMoreSourceMaterialItemsClick}
                    >
                      <span className='count'>
                        {(filteredSourceMaterialItems?.length ?? 0) - 4}
                      </span>
                      <span className='label'>more</span>
                    </div>
                  )}
              </div>
            ) : (
              <div className='empty-list'>
                {' '}
                {searchQuery.length > 0
                  ? 'No source materials found for your request'
                  : 'Add your first source material'}
              </div>
            )}
          </div>
        </SmoothVisibility>
        <LoadingOverlay active={isLoading} />
      </MainContentLayout>
      <KnowledgeModal
        visible={knowledgeModalVisible}
        item={knowledgeModalItem}
        onCancel={handleKnowledgeModalCancel}
        onSubmit={handleKnowledgeModalSubmission}
      />
    </>
  );
}
