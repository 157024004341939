import cn from 'classnames';
import { FieldAttributes, useField } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

import * as styles from './SnippetContentTextArea.module.scss';

export const SnippetContentTextArea: React.FC<FieldAttributes<any>> = ({
  label,
  className,
  maxLength,
  isError,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isShowError = meta.touched && meta.error;

  return (
    <label className={styles.snippetTextAreaLabel}>
      {label}
      <TextareaAutosize
        className={cn(styles.snippetTextArea, className, {
          [styles.snippetTextAreaError]: isShowError,
        })}
        minRows={9}
        maxRows={12}
        maxLength={maxLength}
        {...field}
        {...props}
      />
      {isShowError ? <div className={styles.snippetError}>{meta.error}</div> : null}
    </label>
  );
};
