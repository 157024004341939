import React from 'react';

import { useDeleteDocument } from '../../../../pages/MyContentPage/useDeleteDocument';
import { useDeleteDocumentFromTrash } from '../../../../pages/Trash/useDeleteDocumentFromTrash';
import Emitter, { EMITTER_EVENTS } from '../../../../utils/eventEmitter';
import { Button } from '../../../Button';
import { ModalLayout } from '../../ModalLayout';
import * as styles from './DeleteDocumentModal.module.scss';

interface IProps {
  onClose: () => void;
  id: number;
  isTrash: boolean;
  emmitType: EMITTER_EVENTS.MODAL_DELETE_DOCUMENT;
}

export const DeleteDocumentModal: React.FC<IProps> = ({ id, onClose, isTrash, emmitType }) => {
  const { handleDeleteDocument } = useDeleteDocument();
  const { handleDeleteDocumentFromTrash } = useDeleteDocumentFromTrash();

  const deleteDocument = () => {
    if (isTrash) {
      handleDeleteDocumentFromTrash(id);
    } else {
      handleDeleteDocument(id);
    }
    Emitter.emit(emmitType, {});
    onClose();
  };

  return (
    <ModalLayout title="You are about to delete this saved response">
      <div className={styles.deleteSavedResponseModalContent}>
        <p className={styles.deleteSavedResponseModalDescription}>
          All talents will be kept but this job will be permanently removed. Are you sure?
        </p>
        <div className={styles.deleteSavedResponseModalBtnsContainer}>
          <Button onClick={onClose} isSecondary>
            Cancel
          </Button>
          <Button onClick={deleteDocument} isRed>
            Delete
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
