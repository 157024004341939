import './Auth.external.scss';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MaxWidthContainer } from '../../components/Layouts/MaxWidthContainer';
import { useExtensionServiceContext } from '../../external/extension/service/context';
import { useAsyncProcessManagerContext } from '../../external/extension/tools/async/context';
import {
  AuthBlock,
  AuthSubBlock
} from '../../external/extension/ui/components/auth';
import { PAGES_ENUM } from '../../utils/constants';
import { Header } from '../Onboarding/components/Header';
import * as styles from './Auth.module.scss';
import { useGoogleSignIn } from './useGoogleSignIn';
import { useGoogleSignUp } from './useGoogleSignUp';
import { useSignIn } from './useSignIn';
import { useSignUp } from './useSignUp';

export interface IAuth {
  defaultBlock: AuthSubBlock;
}

export const Auth = (props: IAuth) => {
  const navigate = useNavigate();
  const { handleSignIn } = useSignIn();
  const { handleSignUp } = useSignUp();
  const { googleLogin } = useGoogleSignIn();
  const { googleRegistration } = useGoogleSignUp();

  const asyncProcessManager = useAsyncProcessManagerContext();
  const extensionService = useExtensionServiceContext();

  useEffect(() => {
    const authTokenFromStorage =
      localStorage.getItem('auth_token') ||
      sessionStorage.getItem('auth_token');
    const isInWaitinglist = localStorage.getItem('in_waiting_list') === 'true';

    if (authTokenFromStorage && !isInWaitinglist) {
      navigate(PAGES_ENUM.HOME);
    }
  }, []);

  return (
    <div className={styles.authPage}>
      <MaxWidthContainer className={styles.authPageWrapper}>
        <div className={styles.authPageContent}>
          <Header />
          <main className={styles.authPageMainContentWrapper}>
            <AuthBlock
              defaultBlock={props.defaultBlock ?? AuthSubBlock.Login}
              isDashboard={true}
              onGoogle={function (newAccount: boolean) {
                if (newAccount) {
                  googleRegistration();
                } else {
                  googleLogin();
                }
              }}
              onChangeBlock={(block) => {
                if (block === AuthSubBlock.Login) {
                  navigate(PAGES_ENUM.SIGN_IN);
                } else if (block === AuthSubBlock.SignUp) {
                  navigate(PAGES_ENUM.SIGN_UP);
                } else if (block === AuthSubBlock.ResetPassword) {
                  navigate(PAGES_ENUM.RESET_PASSWORD);
                } else {
                  navigate(PAGES_ENUM.SIGN_IN);
                }
              }}
              onLogin={async (data: any) => {
                handleSignIn(data);
              }}
              onSignUp={async (data: any) => {
                handleSignUp(data);
              }}
              sendResetPasswordCode={async (
                data: any,
                formRef,
                _formState,
                setErrors,
                setActiveStep,
                setIsLocalLoading
              ) => {
                const form = formRef.current;

                await asyncProcessManager?.doProcess({
                  name: 'Reset password: send confirmation code to email',
                  onError: () => {
                    setIsLocalLoading(false);
                  },
                  action: async () => {
                    setIsLocalLoading(true);

                    try {
                      await extensionService.sendResetPasswordCode(data);

                      setActiveStep('code');
                      setIsLocalLoading(false);
                    } catch (error) {
                      setIsLocalLoading(false);

                      setErrors({
                        email: 'Invalid email'
                      });

                      const emailInput = form?.elements.namedItem(
                        'email'
                      ) as HTMLInputElement;

                      emailInput.setCustomValidity('Invalid email');
                      setIsLocalLoading(false);
                    }
                  }
                });
              }}
              checkResetPasswordCode={async (
                data: any,
                formRef,
                _formState,
                setErrors,
                setActiveStep,
                setIsLocalLoading
              ) => {
                const form = formRef.current;

                await asyncProcessManager?.doProcess({
                  name: 'Reset password: check confirmation code',
                  onError: () => {
                    setIsLocalLoading(false);
                  },
                  action: async () => {
                    setIsLocalLoading(true);

                    try {
                      await extensionService.checkResetPasswordCode(data);

                      setActiveStep('password');
                      setIsLocalLoading(false);
                    } catch (error) {
                      setIsLocalLoading(false);

                      setErrors({
                        code: 'Invalid code'
                      });

                      const codeInput = form?.elements.namedItem(
                        'code'
                      ) as HTMLInputElement;

                      codeInput.setCustomValidity('Invalid code');
                    }
                  }
                });
              }}
              resetPassword={async (
                data: any,
                formRef,
                _formState,
                setErrors,
                setActiveStep,
                setIsLocalLoading
              ) => {
                const form = formRef.current;

                await asyncProcessManager?.doProcess({
                  name: 'Reset password: send confirmation code to email',
                  onError: () => {
                    setIsLocalLoading(false);
                  },
                  action: async () => {
                    setIsLocalLoading(true);

                    try {
                      await extensionService.resetPassword(data);

                      setActiveStep('success');
                      setIsLocalLoading(false);
                    } catch (error) {
                      setIsLocalLoading(false);

                      setErrors({
                        password: 'Invalid password',
                        passwordConfirmation: 'Invalid password'
                      });

                      const passwordInput = form?.elements.namedItem(
                        'password'
                      ) as HTMLInputElement;

                      const passwordConfirmation = form?.elements.namedItem(
                        'confirmation'
                      ) as HTMLInputElement;

                      passwordInput.setCustomValidity('Invalid password');
                      passwordConfirmation.setCustomValidity(
                        'Invalid password'
                      );
                    }
                  }
                });
              }}
            />
          </main>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
