/* eslint-disable no-case-declarations */
import './index.scss';

import {
  ClipboardEvent,
  Fragment,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { IPrompt } from '../../../../../interfaces';
import { BaseExtensionService } from '../../../../../service/base';
import { AlchemyModel } from '../../../../../service/base/ai/interfaces';
import { ICommand } from '../../../../../service/base/interfaces';
import { IUploadItem, UploadStatus } from '../../../../../service/base/uploads';
import {
  ExtensionEvents,
  useExtensionEventListener
} from '../../../../../service/events';
import { ExtensionEventType } from '../../../../../service/events/types';
import { FileTools } from '../../../../../tools/file';
import { GraphicTools } from '../../../../../tools/graphics';
import { StringTools } from '../../../../../tools/string';
import ArrowDownIcon from '../../../../design/assets/svg/icons/ArrowDownIcon';
import ArrowRightAltIcon from '../../../../design/assets/svg/icons/ArrowRightAltIcon';
import AttachmentFileIcon from '../../../../design/assets/svg/icons/AttachmentFileIcon';
import { CloseIcon } from '../../../../design/assets/svg/icons/CloseIcon';
import DocumentIcon from '../../../../design/assets/svg/icons/DocumentIcon';
import SendIcon from '../../../../design/assets/svg/icons/SendIcon';
import StopIcon from '../../../../design/assets/svg/icons/StopIcon';
import { Dropdown } from '../../../../design/components/dropdown';
import { LoadingSpinner } from '../../../../design/components/loading';
import { CircleLoader } from '../../../../design/components/loading/progress/circle';
import { SmoothVisibility } from '../../../../design/components/smoothVisibility';
import { VoiceInput } from '../../../../design/components/voice';
import { CommandCenter } from './commands';

interface IPromptTextarea {
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  conversationModel: AlchemyModel;
  setConversationModel: (model: AlchemyModel) => void;
  setSidebarModel?: (model: AlchemyModel) => void;
  isPromptSending: boolean;
  placeholder?: string;
  onSend: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    customPromptText?: string,
    customPromptAlias?: string
  ) => void;
  onPaste?: (e: ClipboardEvent<HTMLElement>) => void;
  maxRows?: number;
  minRows?: number;
  disableImageGenerativeModels?: boolean;
  onModalPromptSended?: () => void;
  containerRef?: RefObject<HTMLDivElement>;
  modelDropdownVerticalPosition?: 'top' | 'bottom';
  modelDropdownHorizontalPosition?: 'left' | 'center' | 'right';
  isInToolbar?: boolean;
  hideModels?: boolean;
  customButtons?: ReactNode | ReactNode[];
  onShowCommands?: () => void;
  onHideCommands?: () => void;
  onSendCommandPrompt?: (prompt: IPrompt) => void;
  attachmentsSection?: {
    items: IUploadItem[];
    upload: (file: File) => Promise<void>;
    removeFile: (id: string) => void;
    clearAll: () => void;
  };
}

export enum CommandsMode {
  Default,
  QuickPrompts
}

export const PromptTextarea = (props: IPromptTextarea) => {
  const [isCommandCenterVisible, setIsCommandCenterVisible] =
    useState<boolean>(false);

  const [isListening, setIsListening] = useState<boolean>(false);

  useExtensionEventListener(ExtensionEventType.ShowQuickPrompts, () => {
    setIsCommandCenterVisible(true);
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    switch (event.key) {
      case 'Escape':
        setIsCommandCenterVisible(false);
        break;

      case 'Backspace':
        if (props.prompt.length === 0 || props.prompt === '/') {
          setIsCommandCenterVisible(false);
        }
        break;

      case '/':
        if (props.prompt.length === 0 || props.prompt === '/') {
          event.preventDefault();

          setIsCommandCenterVisible((prev) => !prev);
        }
        break;

      case 'Enter':
        if (isCommandCenterVisible) {
          event.preventDefault();

          return;
        }
        if (!event.shiftKey) {
          event.preventDefault();
          ExtensionEvents.dispatch(ExtensionEventType.RefreshVoiceInputText);
          setTimeout(() => {
            props.onSend();
          }, 100);
        }
        break;

      default:
        break;
    }
  };

  const onClickCommand = useCallback(
    (command: ICommand) => {
      const relatedPrompt: IPrompt | null =
        BaseExtensionService.getPromptFromModernCommand(command, true);

      if (relatedPrompt) {
        props.onSendCommandPrompt?.(relatedPrompt);

        return;
      }

      props.setPrompt(command.prompt);
      setIsCommandCenterVisible(false);

      props.onSend(undefined, command.prompt, command.prompt);
    },
    [props]
  );

  const models = Object.values(AlchemyModel).filter((model) =>
    props.disableImageGenerativeModels
      ? ![AlchemyModel.DALLE3, AlchemyModel.ChatPDF].includes(model)
      : true
  );
  const sendBtnRef = useRef<HTMLButtonElement>(null);

  const showCommands = isCommandCenterVisible && !props.isPromptSending;

  useEffect(() => {
    if (showCommands) {
      props.onShowCommands?.();
    } else {
      props.onHideCommands?.();
    }
  }, [showCommands]);

  const handleFileUploadButtonClick = async () => {
    const file = await FileTools.uploadFile([
      'png',
      'jpeg',
      'jpg',
      'gif',
      'pdf'
    ]);

    if (file) props.attachmentsSection?.upload(file);
  };

  const attachments = props.attachmentsSection?.items ?? [];

  function handleSuggestionSend(suggestion: string) {
    props.onSend(undefined, suggestion);
  }

  return (
    <div
      ref={props.containerRef}
      className={
        'textarea-wrapper' +
        (showCommands && props.isInToolbar ? ' commands-visible' : '')
      }
    >
      <CommandCenter
        inputValue={props.prompt}
        onCommandClicked={onClickCommand}
        visible={isCommandCenterVisible}
      />
      <ReactTextareaAutosize
        onPaste={props.onPaste}
        placeholder={
          isCommandCenterVisible
            ? 'Type to search for a prompt or press ESC to hide the commands.'
            : (props.placeholder ?? 'Make your magic...')
        }
        value={props.prompt}
        onChange={(e) => props.setPrompt(e.target.value)}
        maxRows={props.maxRows ?? 5}
        minRows={props.minRows ?? 2}
        onKeyDown={handleKeyDown}
        style={{ transition: 'all 0.3s ease' }}
      />
      <div className='textarea-buttons-wrapper'>
        <SmoothVisibility
          className='attachments-section'
          visible={!!props.attachmentsSection}
        >
          {(props.attachmentsSection?.items.length ?? 0) > 0 ? (
            <div className='attachments'>
              {props.attachmentsSection?.items.map(
                (attachment: IUploadItem, index: number) => {
                  const uploadStatus = (
                    <div
                      onClick={async () => {
                        if (
                          attachment.status !== UploadStatus.Uploading &&
                          attachment.mime.startsWith('image')
                        )
                          await GraphicTools.viewImage(attachment.preview);
                      }}
                      className={`attachment-status ${attachment.status}`}
                    >
                      {attachment.status === 'uploading' ? (
                        attachment.uploadPercent === 100 ? (
                          <LoadingSpinner width={20} color='white' />
                        ) : (
                          <CircleLoader
                            progress={attachment.uploadPercent ?? 0}
                          />
                        )
                      ) : null}
                      {attachment.error && <span>Error</span>}
                    </div>
                  );

                  return (
                    <Fragment key={index}>
                      <div
                        className={
                          'attachment-item ' +
                          (attachment.mime.startsWith('image')
                            ? 'image-item'
                            : 'file-item')
                        }
                      >
                        {attachment.mime.startsWith('image') ? (
                          <div
                            className='attachment-image'
                            style={{
                              backgroundImage: `url(${attachment.preview})`
                            }}
                          />
                        ) : (
                          <div className='attachment-file'>
                            <div className='attachment-icon'>
                              <DocumentIcon />
                              {uploadStatus}
                            </div>

                            <div className='attachment-meta'>
                              <span className='title'>
                                {attachment.filename}
                              </span>
                              <span className='filetype'>
                                {
                                  StringTools.getFileTypeInfo(
                                    attachment.filename
                                  )?.title
                                }
                              </span>
                            </div>
                          </div>
                        )}

                        {attachment.mime.startsWith('image') && uploadStatus}

                        <div className='image-item-btn-wrapper'>
                          <button
                            className='attachment-delete'
                            onClick={() =>
                              props.attachmentsSection?.removeFile(
                                attachment.id
                              )
                            }
                          >
                            <CloseIcon color='white' />
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  );
                }
              )}
            </div>
          ) : (
            <button
              className='upload-button'
              onClick={handleFileUploadButtonClick}
            >
              <AttachmentFileIcon />
            </button>
          )}
        </SmoothVisibility>

        <Dropdown
          hidden={props.hideModels}
          verticalPosition={props.modelDropdownVerticalPosition}
          horizontalPosition={props.modelDropdownHorizontalPosition}
          className='alchemy-model-dropdown'
          items={models
            .filter((m) => m !== AlchemyModel.Claude3Sonnet)
            .map((model) => {
              const modelId = model;

              return {
                isLink: true,
                linkExternal: true,
                linkTo: '',
                kind: 'item',
                id: modelId,
                text: modelId,
                onLinkClick: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.setConversationModel(model);
                  props.setSidebarModel?.(model);
                },
                active: model === props.conversationModel
              };
            })}
        >
          <span>{props.conversationModel}</span>
          <ArrowDownIcon color='var(--app-text)' />
        </Dropdown>

        <div className='right-side'>
          {props.customButtons}
          <VoiceInput
            prompt={props.prompt}
            setPrompt={props.setPrompt}
            onListeningStopped={() => {
              setTimeout(() => {
                sendBtnRef.current?.focus();
              }, 100);
            }}
            onChange={(isListening) => {
              setIsListening(isListening);
            }}
          />
          <button
            ref={sendBtnRef}
            onClick={(e) => {
              if (isCommandCenterVisible) {
                e.preventDefault();

                return;
              }

              props.onSend(e);
            }}
            className={`${props.prompt && !isListening && !isCommandCenterVisible ? 'active' : ''}`}
            tabIndex={-1}
          >
            {props.isPromptSending ? <StopIcon /> : <SendIcon />}
          </button>
        </div>
      </div>
      <SmoothVisibility
        visible={attachments.length > 0}
        className='textarea-attachment-suggestions-wrapper'
      >
        <>
          {attachments[0]?.mime.startsWith('image') ? (
            <>
              <button
                onClick={() => handleSuggestionSend('Describe the image')}
                className='suggestion'
              >
                <span>Describe the image</span>
                <ArrowRightAltIcon />
              </button>
              <button
                onClick={() => handleSuggestionSend('Extract text from image')}
                className='suggestion'
              >
                <span>Extract text</span>
                <ArrowRightAltIcon />
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => handleSuggestionSend('Describe file')}
                className='suggestion'
              >
                <span>Describe file</span>
                <ArrowRightAltIcon />
              </button>
              <button
                onClick={() => handleSuggestionSend('Extract text')}
                className='suggestion'
              >
                <span>Extract text</span>
                <ArrowRightAltIcon />
              </button>
            </>
          )}
        </>
      </SmoothVisibility>
    </div>
  );
};
