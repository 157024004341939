import { useLogOut } from '../../../../hooks/useLogOut';
import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { getIsUserAuthorizedWithGoogle } from '../../../../services/storageService';
import { Button } from '../../../Button';
import { ModalLayout } from '../../ModalLayout';
import { deleteModal } from '../../ModalSlice';
import * as styles from './LogoutModal.module.scss';

export const LogoutModal = () => {
  const dispatch = useTypedDispatch();
  const isGoogleAuthenticate = getIsUserAuthorizedWithGoogle();

  const handleModalClose = () => {
    dispatch(deleteModal());
  };

  const { onLogout, onGoogleLogout } = useLogOut();

  return (
    <ModalLayout title="Log out">
      <div className={styles.logoutModalContent}>Are you sure you want to sign out?</div>
      <div className={styles.logoutModalBtnsContainer}>
        <Button className={styles.cancelBtn} onClick={handleModalClose} isSecondary>
          Cancel
        </Button>
        <Button
          className={styles.logOutBtn}
          onClick={() => {
            if (isGoogleAuthenticate) {
              onGoogleLogout();
            } else {
              onLogout();
            }
            handleModalClose();
          }}
        >
          Yes, sign out
        </Button>
      </div>
    </ModalLayout>
  );
};
