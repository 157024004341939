// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown-module__option___sb6Ps{width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.Dropdown-module__singleValue___ApTiA{width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.Dropdown-module__singleValuePreview___ogcH7{margin-top:-3px}.Dropdown-module__singleValuePreview___ogcH7 span{width:118px;height:20px}.Dropdown-module__iconPreview___b6zj8{margin-top:-3px}.Dropdown-module__iconPreview___b6zj8 span{width:20px;height:20px;border-radius:4px}.Dropdown-module__optionIcon___kPh8U{width:20px;height:20px;object-fit:contain}`, "",{"version":3,"sources":["webpack://./src/components/Dropdown/Dropdown.module.scss"],"names":[],"mappings":"AAAA,iCACE,UAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CAGF,sCACE,UAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CAGF,6CACE,eAAA,CACA,kDACE,WAAA,CACA,WAAA,CAIJ,sCACE,eAAA,CACA,2CACE,UAAA,CACA,WAAA,CACA,iBAAA,CAIJ,qCACE,UAAA,CACA,WAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var option = `Dropdown-module__option___sb6Ps`;
export var singleValue = `Dropdown-module__singleValue___ApTiA`;
export var singleValuePreview = `Dropdown-module__singleValuePreview___ogcH7`;
export var iconPreview = `Dropdown-module__iconPreview___b6zj8`;
export var optionIcon = `Dropdown-module__optionIcon___kPh8U`;
export default ___CSS_LOADER_EXPORT___;
