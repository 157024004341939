import cn from 'classnames';
import { FC, ReactElement, SVGProps } from 'react';

import * as styles from './ToolbarButton.module.scss';

type Props = {
  icon: ReactElement<FC<SVGProps<SVGSVGElement>>>;
  onClick: () => void;
  disabled?: boolean;
  isActive: boolean;
};

export const ToolbarButton: FC<Props> = ({ icon, onClick, disabled, isActive }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cn(
        styles.bookmarkToolbarButton,
        'documents-toolbar-button',
        { [styles.is_active]: isActive },
        { 'button-active': isActive },
        { [styles.disabled]: disabled }
      )}
    >
      {icon}
    </button>
  );
};
