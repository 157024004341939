import './index.scss';

import { Button } from '../../../design/components/button';
import { Modal } from '../../../design/components/modal';

export interface IUpgradeConfirmationModal {
  visible: boolean;
  onUpgrade: () => void;
  onClose: () => void;
}
export function UpgradeConfirmationModal(props: IUpgradeConfirmationModal) {
  const onClose = () => {
    props.onClose();
  };

  const onUpgrade = () => {
    props.onUpgrade();
    props.onClose();
  };

  return (
    <Modal
      title="Upgrade to become a true synthesizer"
      visible={props.visible}
      onClose={onClose}
      hasCloseButton={true}
      className="upgrade-confirmation-modal"
    >
      <p className="upgrade-text">
        Get an early bird discount of 40% and start gaining your edge to create content.
      </p>

      <div className="footer-buttons">
        <Button onClick={onClose} active={false} text="Dismiss" />
        <Button onClick={onUpgrade} active={true} text="Upgrade" />
      </div>
    </Modal>
  );
}
