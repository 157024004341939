import { useNavigate, useParams } from 'react-router-dom';

import { useGetShareCustomPromptQuery } from '../../../services/promptLibrary';
import { PAGES_ENUM } from '../../../utils/constants';
import { handleSuccess } from '../../../utils/notifications';
import { ShareCustomPrompt } from '../ShareCustomPrompt';

export const SharePromptPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isFetching } = useGetShareCustomPromptQuery(id ?? '');

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(
      window.location.origin + `${PAGES_ENUM.SHARED_CUSTOM_PROMPT}/${id}`
    );
    handleSuccess('Successfully copied to clipboard');
  };

  return (
    <>
      {data && (
        <ShareCustomPrompt
          data={data}
          alchemyButton={{
            buttonText: 'Open Alchemy',
            onClick: () => navigate(`${PAGES_ENUM.HOME}?tab=customPrompts`)
          }}
          promptManageButton={{
            copyIcon: true,
            buttonText: 'Copy Link',
            onClick: () => onCopyLink()
          }}
          customPromptId={id ?? ''}
          isLoading={isFetching}
        />
      )}
    </>
  );
};
