// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsPage-module__settings___qHbKh{display:flex;flex-direction:column;margin-top:16px;flex-grow:1}.SettingsPage-module__settingsTabsContainer___iJPd8{display:flex;align-items:center;flex-wrap:wrap;gap:8px}`, "",{"version":3,"sources":["webpack://./src/pages/SettingsPage/SettingsPage.module.scss"],"names":[],"mappings":"AAAA,uCACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,WAAA,CAGF,oDACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,OAAA","sourceRoot":""}]);
// Exports
export var settings = `SettingsPage-module__settings___qHbKh`;
export var settingsTabsContainer = `SettingsPage-module__settingsTabsContainer___iJPd8`;
export default ___CSS_LOADER_EXPORT___;
