import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const BoldFontIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M5.625 3.95801H10.4167C12.1426 3.95801 13.5417 5.35712 13.5417 7.08301C13.5417 8.8089 12.1426 10.208 10.4167 10.208H5.625V3.95801Z"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 10.208H11.4583C13.0692 10.208 14.375 11.5138 14.375 13.1247C14.375 14.7355 13.0692 16.0413 11.4583 16.0413H5.625V10.208Z"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
