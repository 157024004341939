import cn from 'classnames';
import { FC } from 'react';

import * as styles from './Tag.module.scss';

type Props = {
  title: string;
  onClick: () => void;
  isActive: boolean;
  isLoading: boolean;
  isDisabled: boolean;
};

export const Tag: FC<Props> = ({ title, onClick, isActive = false, isLoading, isDisabled }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        styles.tag,
        { [styles.activeTag]: isActive },
        { [styles.tagPreview]: isLoading },
        { [styles.disabled]: isLoading || isDisabled }
      )}
      disabled={isLoading || isDisabled}
    >
      {title}
    </button>
  );
};
