import React, { FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { ICustomInput } from '../../../../../models/IPromptLibrary';
import { replaceUnderscores } from '../../../../../utils/editor';
import * as styles from './HashtagTextAreaField.module.scss';

type Props = {
  input: ICustomInput;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>, variableName: string) => void;
  disabled: boolean;
  value: string;
};

export const HashtagTextAreaField: FC<Props> = React.memo(
  ({ input, onChange, value, ...props }) => {
    const { variable_name, placeholder } = input || {};
    const variableNameWithoutUnderscore = replaceUnderscores(variable_name);

    return (
      <div className={styles.textareaWrapper}>
        <div className={styles.textAreaLabel}>{`#${variableNameWithoutUnderscore}`}</div>
        <TextareaAutosize
          className={styles.textarea}
          maxRows={4}
          placeholder="Write something about this input tag"
          value={value || value === '' ? value : placeholder ? placeholder : ''}
          onChange={e => onChange(e, variable_name)}
          {...props}
        />
      </div>
    );
  }
);

HashtagTextAreaField.displayName = 'HashtagTextAreaField';
