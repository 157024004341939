import './index.scss';

import { useState } from 'react';

import { useExtensionServiceContext } from '../../../../service/context';
import CheckboxIcon from '../../../design/assets/svg/icons/CheckboxIcon';
import { Button } from '../../../design/components/button';
import { Modal } from '../../../design/components/modal';

export interface IUpgradePricesModal {
  visible: boolean;
  onClose: () => void;
}

export enum SubscriptionOption {
  Annualy = '2',
  Monthly = '1',
}

const pricesMap = {
  [SubscriptionOption.Monthly]: '49.99',
  [SubscriptionOption.Annualy]: '29.99',
};

export function UpgradePricesModal(props: IUpgradePricesModal) {
  const [selectedOption, setSelectedOption] = useState<SubscriptionOption>(
    SubscriptionOption.Annualy
  );

  const extensionService = useExtensionServiceContext();

  const onClose = () => {
    props.onClose();
  };

  const onUpgrade = () => {
    const token = extensionService.getSession()?.token;

    onClose();

    if (token) {
      const newWindow = window.open(
        // `http://localhost:3000/extension-pay-page?tier=${plan.tier}&type=${currTimeType.type}&token=${token}`,
        `${extensionService.getHost()}/extension-pay-page?tier=${2}&type=${selectedOption}&token=${token}`,
        '_blank'
      );

      if (newWindow) {
        newWindow.focus();
      } else {
        // Если окно не было открыто (возможно, из-за блокировщика всплывающих окон),
        // вы можете предоставить пользователю ссылку, которую они могут щелкнуть.
        window.location.href = `${extensionService.getHost()}/extension-pay-page?tier=${2}&type=${selectedOption}&token=${token}`;
      }
    }

    props.onClose();
  };

  const user = extensionService.useExtensionUser();

  return (
    <Modal
      title="Upgrade to Premium"
      visible={props.visible}
      onClose={onClose}
      hasCloseButton={true}
      className="upgrade-prices-modal"
    >
      <div className="sides">
        <div className="side benefits">
          <p className="benefits-title">What you get on Alchemy Premium</p>

          <ul>
            <li>Unlimited Chat Tokens</li>
            <li>Unlimited Snippets</li>
            <li>Webpage Summarization</li>
            <li>Youtube Summarization</li>
            <li>Image Generation</li>
            <li>AI Search</li>
            <li>Image OCR</li>
            <li>Chat with PDF</li>
            <li>Capture Screenshots</li>
            <li>Full Prompt Library</li>
            <li>Unlimited Custom Prompts</li>
            <li>Unlimited Favorite Prompts</li>
            <li>Unlimited Documents</li>
            <li>Quick Prompts</li>
            <li>Speech to Text</li>
          </ul>
        </div>
        <div className="side subscription-option-selection">
          <div className="subscription-options">
            <div
              onClick={() => setSelectedOption(SubscriptionOption.Annualy)}
              className="subscription-option"
            >
              <div className="option-main">
                <div className="option-header">
                  <span className="option-type">Annually</span>
                  <span className="option-discount">15% OFF</span>
                </div>
                <div className="option-price-wrapper">
                  <span className="option-price">US ${pricesMap[SubscriptionOption.Annualy]}</span>
                  <span className="option-period">per month</span>
                </div>
                <div className="option-cancelation-info">cancel anytime</div>
              </div>
              <CheckboxIcon checked={selectedOption === SubscriptionOption.Annualy} />
            </div>
            <div
              onClick={() => setSelectedOption(SubscriptionOption.Monthly)}
              className="subscription-option"
            >
              <div className="option-main">
                <div className="option-header">
                  <span className="option-type">Monthly</span>
                </div>
                <div className="option-price-wrapper">
                  <span className="option-price">US ${pricesMap[SubscriptionOption.Monthly]}</span>
                  <span className="option-period">per month</span>
                </div>
                <div className="option-cancelation-info">cancel anytime</div>
              </div>
              <CheckboxIcon checked={selectedOption === SubscriptionOption.Monthly} />
            </div>
          </div>

          <Button
            onClick={onUpgrade}
            active={true}
            text={user.session?.trial_activated ? 'Subscribe' : 'Start 7-day free trial'}
          />
        </div>
      </div>
    </Modal>
  );
}
