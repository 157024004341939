export default function BlobSidebarKnowledgeIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0417 4.79159C16.0417 4.33135 15.6686 3.95825 15.2083 3.95825H11.6667C10.7462 3.95825 10 4.70444 10 5.62492V16.0416L10.6903 15.3513C11.3155 14.7261 12.1633 14.3749 13.0474 14.3749H15.2083C15.6686 14.3749 16.0417 14.0018 16.0417 13.5416V4.79159Z"
        stroke="#F5F5F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.95834 4.79159C3.95834 4.33135 4.33144 3.95825 4.79168 3.95825H8.33334C9.25384 3.95825 10 4.70444 10 5.62492V16.0416L9.30968 15.3513C8.68451 14.7261 7.83669 14.3749 6.95264 14.3749H4.79168C4.33144 14.3749 3.95834 14.0018 3.95834 13.5416V4.79159Z"
        stroke="#F5F5F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
