import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '..';

interface GlobalState {
  loading: boolean;
  collapsedSidebar: boolean;
}

const initialState: GlobalState = {
  loading: false,
  collapsedSidebar: false
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    // This will be used in the future for the collapsed sidebar UI
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.collapsedSidebar = action.payload;
    }
  }
});

export const { setLoading, setSidebarCollapsed } = globalSlice.actions;

export const selectLoading = (state: RootState) => state.global.loading;
export const selectCollapsedSidebar = (state: RootState) =>
  state.global.collapsedSidebar;

export default globalSlice;
