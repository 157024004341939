import { createApi } from '@reduxjs/toolkit/query/react';

import * as I from '../models/IMyContent';
import { baseQuery } from '../utils/baseQuery';
import { ExtensionEvents } from '../external/extension/service/events';
import { ExtensionEventType } from '../external/extension/service/events/types';

export const myContentApi = createApi({
  reducerPath: 'myContent',
  baseQuery,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  tagTypes: ['Documents', 'TrashDocuments'],
  endpoints: (builder) => ({
    getDocuments: builder.query<I.ICategoriesAndDocuments, void>({
      query: () => ({ url: `/shop/get-documents-categories/` }),
      providesTags: () => ['Documents'],
      keepUnusedDataFor: 120
    }),

    createDocument: builder.mutation<
      I.ICreateDocumentResponse,
      I.ICreateDocumentRequest
    >({
      query: (body) => ({
        url: `/shop/create-document/`,
        method: 'POST',
        body
      }),
      invalidatesTags: () => ['Documents']
    }),

    renameDocument: builder.mutation<I.IDocument, I.IRenameDocument>({
      query: ({ id, body }) => ({
        url: `/shop/document-title-update/${id}`,
        body,
        method: 'POST'
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(
          myContentApi.util.updateQueryData(
            'getDocuments',
            undefined,
            (draft) => {
              const document = draft?.documents?.find((doc) => doc.id === id);

              if (document) {
                Object.assign(document, patch.body);
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(myContentApi.util.invalidateTags(['Documents']));
        }
      }
    }),

    updateDocument: builder.mutation<I.IDocument, I.IUpdateDocument>({
      query: ({ id, body }) => ({
        url: `/shop/update-document/${id}`,
        body,
        method: 'PUT'
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(
          myContentApi.util.updateQueryData(
            'getDocuments',
            undefined,
            (draft) => {
              const document = draft?.documents?.find((doc) => doc.id === id);

              if (document) {
                Object.assign(document, patch.body);
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(myContentApi.util.invalidateTags(['Documents']));
        }
      }
    }),

    deleteDocument: builder.mutation<unknown, number>({
      query: (id) => ({ url: `/shop/remove-document/${id}`, method: 'PUT' }),
      invalidatesTags: () => ['Documents', 'TrashDocuments']
    }),

    findByCategoriesAndDocuments: builder.query<
      I.ICategoriesAndDocuments,
      object
    >({
      query: (params) => ({ url: `/shop/find-doc-category/`, params })
    }),

    // TRASH_START

    getTrashDocuments: builder.query<I.ICategoriesAndDocuments, void>({
      query: () => ({
        url: `/shop/get-deleted-documents-categories/`
      }),
      providesTags: () => ['TrashDocuments'],
      keepUnusedDataFor: 120
    }),

    deleteTrashDocument: builder.mutation<unknown, number>({
      query: (id) => ({
        url: `/shop/remove-cart-document/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => ['TrashDocuments']
    })
  })
});

ExtensionEvents.on(ExtensionEventType.ReloadDocuments, () => {
  myContentApi.util.invalidateTags(['Documents', 'TrashDocuments']);
});

export const {
  useGetDocumentsQuery,
  useGetTrashDocumentsQuery,
  useDeleteTrashDocumentMutation,
  useRenameDocumentMutation,
  useDeleteDocumentMutation,
  useFindByCategoriesAndDocumentsQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation
} = myContentApi;
