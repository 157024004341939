export interface ISettingsIcon {
  color?: string;
}
export default function SettingsIcon(props: ISettingsIcon) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 21 21'
      fill='none'
      style={{ fill: 'transparent' }}
    >
      <path
        d='M5.579 12.8846L6.49634 13.0283C7.17351 13.1343 7.65712 13.7545 7.60725 14.4533L7.53966 15.4004C7.51998 15.6763 7.66696 15.936 7.9104 16.0562L8.58889 16.3905C8.83233 16.5106 9.12302 16.4656 9.32184 16.2784L10.0036 15.634C10.5062 15.1588 11.2818 15.1588 11.7851 15.634L12.4669 16.2784C12.6657 16.4663 12.9557 16.5106 13.1998 16.3905L13.8796 16.0555C14.1224 15.936 14.2688 15.6769 14.2491 15.4017L14.1815 14.4533C14.1316 13.7545 14.6152 13.1343 15.2924 13.0283L16.2097 12.8846C16.4768 12.843 16.692 12.6383 16.7524 12.3684L16.9197 11.618C16.9801 11.3482 16.8731 11.0682 16.65 10.9125L15.8843 10.3762C15.3193 9.98018 15.1467 9.20691 15.4879 8.59945L15.9505 7.77651C16.0851 7.53688 16.0627 7.23684 15.8941 7.0207L15.4249 6.41861C15.2563 6.20247 14.9755 6.11319 14.7169 6.19441L13.8298 6.4723C13.1743 6.6777 12.4754 6.33336 12.2235 5.68159L11.8836 4.80026C11.7838 4.54251 11.5404 4.37335 11.2694 4.37403L10.5174 4.37604C10.2464 4.37671 10.0036 4.5472 9.90518 4.80563L9.57381 5.67689C9.32446 6.33269 8.62235 6.67972 7.96486 6.47298L7.04097 6.183C6.78178 6.10111 6.49962 6.19106 6.33098 6.40854L5.8651 7.01131C5.69646 7.22946 5.67612 7.53017 5.81326 7.7698L6.28636 8.59475C6.63479 9.20289 6.46419 9.98353 5.89594 10.3816L5.13936 10.9119C4.91626 11.0682 4.80931 11.3482 4.86968 11.6173L5.037 12.3678C5.09671 12.6383 5.31194 12.843 5.579 12.8846Z'
        stroke={props.color ?? 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.2204 9.09013C12.9526 9.82227 12.9526 11.0098 12.2204 11.7419C11.4883 12.4741 10.3008 12.4741 9.56864 11.7419C8.83649 11.0098 8.83649 9.82227 9.56864 9.09013C10.3008 8.35798 11.4883 8.35798 12.2204 9.09013Z'
        stroke={props.color ?? 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
