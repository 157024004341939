// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyContentLayout-module__layout___FMzGf{display:flex;gap:100px;flex-grow:1}.MyContentLayout-module__layoutRightContent___qvvE3,.MyContentLayout-module__layoutLeftContent___f7t0E{display:flex;flex-direction:column;max-height:calc(100vh - 93px);gap:4px;overflow-y:auto;flex-grow:1;width:100%}.MyContentLayout-module__layoutLeftContent___f7t0E{padding:12px 12px 0 0;max-width:280px;flex-shrink:0;border-right:1px solid var(--effects-selected-fill)}.MyContentLayout-module__layoutRightContent___qvvE3{position:relative;max-width:700px;padding:12px 24px 0 24px;overflow:hidden;margin:0 auto;gap:12px}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/MyContentLayout/MyContentLayout.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,SAAA,CACA,WAAA,CAGF,uGAEE,YAAA,CACA,qBAAA,CAEA,6BAAA,CACA,OAAA,CACA,eAAA,CACA,WAAA,CACA,UAAA,CAGF,mDACE,qBAAA,CACA,eAAA,CACA,aAAA,CACA,mDAAA,CAGF,oDACE,iBAAA,CACA,eAAA,CACA,wBAAA,CACA,eAAA,CACA,aAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
export var layout = `MyContentLayout-module__layout___FMzGf`;
export var layoutRightContent = `MyContentLayout-module__layoutRightContent___qvvE3`;
export var layoutLeftContent = `MyContentLayout-module__layoutLeftContent___f7t0E`;
export default ___CSS_LOADER_EXPORT___;
