import { PlusIcon } from '../../../../assets/svg/PlusIcon';
import * as styles from './AddNewPromptTemplate.module.scss';

type Props = {
  onClick: () => void;
};

export const AddNewPromptTemplate: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.promptTemplate}>
      <div className={styles.promptTemplateContent}>
        <button className={styles.promtTemplateAddBtn} onClick={onClick}>
          <PlusIcon />
        </button>
        <h2 className={styles.promtTemplateTitle}>Add a new custom prompt</h2>
        <p className={styles.promtTemplateDescription}>Create your own unique prompt here</p>
      </div>
    </div>
  );
};
