import moment from 'moment';
import Highlighter from 'react-highlight-words';

import { IKnowledge } from '../../../external/extension/service/interfaces';
import { StringTools } from '../../../external/extension/tools/string';
import CopyIconChat from '../../../external/extension/ui/design/assets/svg/icons/CopyIconChat';
import DeleteIcon from '../../../external/extension/ui/design/assets/svg/icons/DeleteIcon';
import EditIcon from '../../../external/extension/ui/design/assets/svg/icons/EditIcon';
import { Avatar } from '../../../external/extension/ui/design/components/avatar';

export interface KnowledgeItemProps {
  item: IKnowledge;
  isSource: boolean;
  searchQuery: string;
  onEdit: (item: IKnowledge) => void;
  onCopy: (item: IKnowledge) => void;
  onDelete: (item: IKnowledge) => void;
}

export function KnowledgeItem(props: KnowledgeItemProps) {
  return (
    <div onClick={() => props.onEdit(props.item)} className="knowledge-item">
      <div className="left-side">
        {!props.isSource ? (
          <div className="emoji">{props.item?.obj_category?.emoji ?? '🛠'}</div>
        ) : (
          <Avatar
            size="nano"
            url={
              props.item?.url
                ? 'http://www.google.com/s2/favicons?domain=' +
                  encodeURI(props.item?.url) +
                  '&sz=128'
                : undefined
            }
            userName="🔗"
          />
        )}
        <div className="name">
          <Highlighter
            highlightClassName="alchemy-highlight"
            searchWords={[props.searchQuery ?? '']}
            autoEscape={true}
            textToHighlight={
              props.item?.url && StringTools.isValidURL(props.item.url)
                ? new URL(StringTools.ensureHttps(props.item.url)).hostname
                    .split('.')
                    .slice(0, -1)
                    .join('')
                : props.item?.title ??
                  (props.item?.obj_category?.title
                    ? props.item.obj_category?.title.toLowerCase().replaceAll(' ', '')
                    : 'unknown')
            }
          />
        </div>
        {props.item?.url ? (
          <a
            target="_blank"
            onClick={e => {
              e.stopPropagation();
            }}
            href={StringTools.ensureHttps(props.item.url)}
            rel="noreferrer"
          >
            {props.searchQuery.length > 0 ? (
              <Highlighter
                highlightClassName="alchemy-highlight"
                searchWords={[props.searchQuery ?? '']}
                autoEscape={true}
                textToHighlight={StringTools.ensureHttps(props.item.url)}
              />
            ) : (
              <>{StringTools.ensureHttps(props.item.url)}</>
            )}
          </a>
        ) : (
          <div className="description">
            <Highlighter
              highlightClassName="alchemy-highlight"
              searchWords={[props.searchQuery ?? '']}
              autoEscape={true}
              textToHighlight={StringTools.truncate(props.item.text, 80)}
            />
          </div>
        )}
      </div>
      <div className="right-side">
        <div className="date">{moment(props.item.created_at).format('MMM Do YYYY')}</div>
        <div className="quick-actions">
          <button
            onClick={e => {
              e.stopPropagation();

              props.onCopy(props.item);
            }}
          >
            <CopyIconChat copied={false} />
          </button>
          <button
            onClick={e => {
              e.stopPropagation();

              props.onEdit(props.item);
            }}
          >
            <EditIcon color="currentColor" />
          </button>
          <button
            onClick={e => {
              e.stopPropagation();

              props.onDelete(props.item);
            }}
          >
            <DeleteIcon color="currentColor" />
          </button>
        </div>
      </div>
    </div>
  );
}
