import cn from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CheckboxIcon from '../../../external/extension/ui/design/assets/svg/icons/CheckboxIcon';
import { errorMessages } from '../../../services/errors';
import { useSetSettingsMutation } from '../../../services/settings';
import { PAGES_ENUM } from '../../../utils/constants';
import { handleError } from '../../../utils/notifications';
import { OnboardingStepLayout } from '../components/OnboardingStepLayout';
import * as styles from './OnboardingSecondStep.module.scss';

const GPT_EXPERIENCE_LEVELS = [
  {
    id: 1,
    title: 'Beginner',
  },
  {
    id: 2,
    title: 'Intermediate',
  },
  {
    id: 3,
    title: 'Expert',
  },
];

export const OnboardingSecondStep = () => {
  const navigate = useNavigate();
  const [selectedLevel, setSelectedLevel] = useState('BEGINNER');
  const [setSettings] = useSetSettingsMutation();

  const handleLevelSelection = (level: string) => {
    setSelectedLevel(level);
  };

  const handleSkip = () => {
    navigate(PAGES_ENUM.ONBOARDING_PAYMENT);
  };

  const handleNext = async () => {
    try {
      await setSettings({ experience_level: selectedLevel }).unwrap();
      navigate(PAGES_ENUM.ONBOARDING_PAYMENT);
    } catch (error) {
      handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
      console.error('User Update Failed', error);
    }
  };

  return (
    <OnboardingStepLayout
      title="What’s your experience level with ChatGPT?"
      description="We want to make sure you make the most out of Alchemy."
      onSkip={handleSkip}
      onNext={handleNext}
      currentStep={2}
    >
      <div className={styles.onboardingSecondStepGPTExperience}>
        {GPT_EXPERIENCE_LEVELS.map(level => (
          <button
            className={cn(styles.onboardingSecondStepLevelBtn, {
              [styles.onboardingSecondStepLevelBtnSelected]:
                selectedLevel === level.title.toUpperCase(),
            })}
            key={level.id}
            onClick={() => handleLevelSelection(level.title.toUpperCase())}
          >
            <CheckboxIcon radius={6} checked={selectedLevel === level.title.toUpperCase()} />
            {level.title}
          </button>
        ))}
      </div>
    </OnboardingStepLayout>
  );
};
