/* eslint-disable react/no-unescaped-entities */
import './index.scss';

import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import ReactTextareaAutosize from 'react-textarea-autosize';
import gfm from 'remark-gfm';

import { IPrompt } from '../../../../../interfaces';
import { BaseExtensionService } from '../../../../../service/base';
import { useExtensionServiceContext } from '../../../../../service/context';
import { ExtensionEvents } from '../../../../../service/events';
import { ExtensionEventType } from '../../../../../service/events/types';
import {
  IKnowledge,
  IKnowledgePageContent
} from '../../../../../service/interfaces';
import { useAsyncProcessManagerContext } from '../../../../../tools/async/context';
import { StringTools } from '../../../../../tools/string';
import BlobSidebarKnowledgeIcon from '../../../../design/assets/svg/icons/BlobSidebarKnowledgeIcon';
import { HelpIcon } from '../../../../design/assets/svg/icons/HelpIcon';
import LinkIcon from '../../../../design/assets/svg/icons/LinkIcon';
import PlusIcon from '../../../../design/assets/svg/icons/PlusIcon';
import { SlashIcon } from '../../../../design/assets/svg/icons/SlashIcon';
import { StarIcon } from '../../../../design/assets/svg/icons/StarIcon';
import { Avatar } from '../../../../design/components/avatar';
import { Button } from '../../../../design/components/button';
import { Dropdown } from '../../../../design/components/dropdown';
import { Modal } from '../../../../design/components/modal';
import { SmoothVisibility } from '../../../../design/components/smoothVisibility';
import { Tooltip } from '../../../../design/components/tooltip';
import { VoiceInput } from '../../../../design/components/voice';
import { PromptDetailsModalHotkeys } from './hotkeys';

export const PromptDetailsModal: React.FC<{
  onClose: (success: boolean) => void;
  onEdit?: (prompt: IPrompt) => void;
  onPromptUpdate: (
    newState: IPrompt,
    update: 'all' | 'favorite' | 'command'
  ) => void;
  onClickSendPrompt: (
    inputs: { [key: string]: string },
    addedKnowledge: IKnowledge[]
  ) => void;
  isCustomCategory: boolean;
  visible?: boolean;
  prompt?: IPrompt | null;
}> = ({
  onClose,
  isCustomCategory,
  onClickSendPrompt,
  onPromptUpdate,
  visible,
  prompt
}: any) => {
  const extensionService = useExtensionServiceContext();
  const asyncProcessManager = useAsyncProcessManagerContext();

  const user = extensionService.useExtensionUser();

  const [isFavorite, setIsFavorite] = useState<boolean>(
    prompt?.is_favourite ?? false
  );
  const [isAddCommand, setIsAddCommand] = useState<boolean>(
    prompt?.is_in_command ?? false
  );

  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const [addedKnowledge, setAddedKnowledge] = useState<IKnowledge[]>([]);
  const [knowledgeData, setKnowledgeData] =
    useState<IKnowledgePageContent | null>(null);

  const replaceBracketsWithValues = (input: string): string => {
    return input.replace(/\[(.*?)\]/g, (match, p1) => {
      return inputValues[p1] || match;
    });
  };

  const onCopy = async () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);

    const formattedText = replaceBracketsWithValues(
      prompt?.prompt_template
    ).trim();

    try {
      await window.navigator.clipboard.writeText(formattedText);
    } catch (e) {
      copy(formattedText, {
        message:
          'iOS blocked automatic text copying, so it needed to be copied manually.'
      });
    }
    toast.success('Successfully copied to clipboard');
  };

  useEffect(() => {
    let timeout: any = null;

    if (!visible) {
      timeout = setTimeout(() => {
        setInputValues({});
      }, 500);
    }
    setIsFavorite(prompt?.is_favourite ?? false);
    setIsAddCommand(prompt?.is_in_command ?? false);

    setFocusedInputId(0);

    return () => {
      !!timeout && clearTimeout(timeout);
    };
  }, [visible]);

  useEffect(() => {
    setIsFavorite(prompt?.is_favourite ?? false);
    setIsAddCommand(prompt?.is_in_command ?? false);
  }, [prompt]);

  const onFavorite = () => {
    if (!prompt) return;

    setIsFavorite(!isFavorite);
    onPromptUpdate(
      {
        ...prompt,
        is_favourite: !isFavorite
      },
      'favorite'
    );
  };

  const onAddToCommand = () => {
    if (!prompt) return;

    setIsAddCommand(!isAddCommand);
    onPromptUpdate(
      {
        ...prompt,
        is_in_command: !isAddCommand
      },
      'command'
    );
  };

  const formRef = useRef<HTMLFormElement | null>(null);
  const [errorInputs, setErrorInputs] = useState<string[]>([]);
  const [focusedInputId, setFocusedInputId] = useState<number>(0);

  const [isSendButtonDisabled, setIsSendButtonDisabled] =
    useState<boolean>(false);

  const [isKnowledgeLoading, setIsKnowledgeLoading] = useState<boolean>(false);

  const onSend = () => {
    try {
      setIsSendButtonDisabled(true);
      if (!formRef.current) return;
      formRef.current.classList.add('submitted');
      const inputs =
        prompt?.inputs.map((input: any) => input.variable_name) ?? [];
      const errors = inputs.filter(
        (key: any) => !inputValues[key] || inputValues[key] === ''
      );

      setErrorInputs(errors);

      if (errors.length === 0) {
        const lightModeEnabled = false;

        if (!lightModeEnabled) {
          onClickSendPrompt(inputValues, addedKnowledge);
        } else {
          const filledTemplate = prompt
            ? BaseExtensionService.fillPromptTemplate(prompt, inputValues)
            : '';

          document.dispatchEvent(
            new CustomEvent('alchemy-lightmode-speechrecogn-text', {
              detail: filledTemplate
            })
          );
          setTimeout(() => {
            const button = [
              ...(
                document.querySelector('#prompt-textarea')?.parentElement
                  ?.parentElement as any
              ).querySelectorAll('button')
            ].pop() as HTMLButtonElement;

            if (button) button.click();
          }, 10);
        }

        ExtensionEvents.dispatch(ExtensionEventType.StopAllVoiceInputs);
        onClose(true);
      }
    } catch (err) {
      setIsSendButtonDisabled(false);
    }
  };

  const handleInputChange = (value: string, variableName: string) => {
    setInputValues({
      ...inputValues,
      [variableName]: value
    });
  };

  console.log(addedKnowledge);

  const addKnowledgeDropdownItems = useMemo(
    () => [
      ...(knowledgeData?.knowledge
        ? [{ kind: 'info' as any, text: 'Knowledge' }]
        : []),
      ...(knowledgeData?.knowledge?.map((item) => ({
        kind: 'item' as any,
        icon: item.obj_category?.emoji ?? '🛠',
        text:
          item.title ??
          (item.obj_category?.title
            ? item.obj_category?.title.toLowerCase().replaceAll(' ', '')
            : 'unknown'),
        active: addedKnowledge.some((it) => it.id === item.id),
        onClick: () => {
          setAddedKnowledge((prev) =>
            prev.some((it) => it.id === item.id)
              ? prev.filter((it) => it.id !== item.id)
              : [...prev, item]
          );
        }
      })) ?? []),
      ...(knowledgeData?.knowledge && knowledgeData?.source
        ? [{ kind: 'separator' as any }]
        : []),
      ...(knowledgeData?.source
        ? [{ kind: 'info' as any, text: 'Source Materials' }]
        : []),
      ...(knowledgeData?.source?.map((item) => ({
        kind: 'item' as any,
        icon: (
          <Avatar
            size='nano'
            url={
              item?.url
                ? 'http://www.google.com/s2/favicons?domain=' +
                  encodeURI(item?.url) +
                  '&sz=128'
                : undefined
            }
            userName='🔗'
          />
        ),
        text:
          item.title ??
          (item?.url && StringTools.isValidURL(item.url)
            ? new URL(StringTools.ensureHttps(item.url)).hostname
                .split('.')
                .slice(0, -1)
                .join('')
            : 'unknown'),
        active: addedKnowledge.some((it) => it.id === item.id),
        onClick: () => {
          setAddedKnowledge((prev) =>
            prev.some((it) => it.id === item.id)
              ? prev.filter((it) => it.id !== item.id)
              : [...prev, item]
          );
        }
      })) ?? [])
    ],
    [knowledgeData, addedKnowledge]
  );

  useEffect(() => {
    if (!user.profile) return;

    asyncProcessManager?.doProcess({
      name: 'Fetch knowledge page content',
      onError: () => {
        setIsKnowledgeLoading(false);

        return undefined;
      },
      action: async () => {
        setIsKnowledgeLoading(true);

        const result = await extensionService.getKnowledgePageContent();

        setKnowledgeData(result);
        setIsKnowledgeLoading(false);
      }
    });
  }, [user.profile, visible]);

  useEffect(() => {
    if (visible) setIsSendButtonDisabled(false);
    else setAddedKnowledge([]);
  }, [visible]);

  const isEmptyCategories = prompt?.categories.length === 0;

  return (
    <Modal
      className='prompt-details-modal'
      title={
        <div className='prompt-details-title-wrapper'>
          Prompt details
          <div className='prompt-details-icon-wrapper'>
            <Tooltip
              position='top'
              text={`For fields that don't apply, just type 'N/A'.`}
            >
              <HelpIcon />
            </Tooltip>
          </div>
        </div>
      }
      visible={visible}
      hasCloseButton={true}
      onClose={() => {
        ExtensionEvents.dispatch(ExtensionEventType.StopAllVoiceInputs);
        onClose(false);
      }}
    >
      <PromptDetailsModalHotkeys onSubmit={onSend} />
      <div className='prompt-details-modal-content'>
        <div className='content-left-part'>
          <div className='answer'>
            <div className='flex'>
              <ul className='selected'>
                <button
                  onClick={onFavorite}
                  className={cn('promptSaveBtn', {
                    promptSaveBtnActive: isFavorite
                  })}
                >
                  <StarIcon />
                </button>
                <button
                  className={cn('promptCommandBtn', {
                    promptCommandBtnActive: isAddCommand
                  })}
                  onClick={onAddToCommand}
                >
                  <SlashIcon />
                </button>
              </ul>
            </div>
            <div>
              <h3 className={isEmptyCategories ? 'shortTitle' : ''}>
                {prompt?.name}
                {prompt?.chain_prompt && <LinkIcon />}
              </h3>
              <ReactMarkdown
                remarkPlugins={[gfm]}
                className='answer-description'
              >
                {prompt?.description ?? ''}
              </ReactMarkdown>
            </div>
          </div>
        </div>
        <div className='content-right-part'>
          <form ref={formRef}>
            {prompt?.inputs.map((inp: any, index: any) => {
              return (
                <div className='input' key={inp.variable_name}>
                  <div className={`input-textarea-wrapper`}>
                    <label>{inp.variable_name} </label>
                    <TextArea
                      key={inp.variable_name}
                      inp={inp}
                      handleInputChange={handleInputChange}
                      inputValues={inputValues}
                      isLastInput={index === prompt.inputs.length - 1}
                      isFocused={focusedInputId === index}
                      setFocused={() => {
                        setFocusedInputId(index);
                      }}
                      onSend={onSend}
                      onFocusNext={() => {
                        if (index === prompt.inputs.length - 1) onSend();
                        if (index < prompt.inputs.length - 1)
                          setFocusedInputId((prev) => prev + 1);
                      }}
                    />
                  </div>
                  <div
                    className={`prompt_detail_modal_textarea_error_message ${
                      errorInputs.some((input) => input === inp.variable_name)
                        ? 'is-visible'
                        : ''
                    }`}
                  >
                    For fields that don't apply, just type 'N/A'.
                  </div>
                </div>
              );
            })}
          </form>
        </div>
      </div>
      <div className='prompt-action'>
        <SmoothVisibility
          visible={
            (knowledgeData?.knowledge?.length ?? 0) > 0 ||
            (knowledgeData?.source?.length ?? 0) > 0
          }
          className='left-side'
        >
          <Dropdown
            disabled={isKnowledgeLoading}
            items={addKnowledgeDropdownItems}
          >
            <Button size='mini' className='add-knowledge'>
              <>
                {addedKnowledge.length > 0 ? (
                  <>
                    <BlobSidebarKnowledgeIcon />
                    {StringTools.numWord(
                      addedKnowledge.length,
                      ['knowledge item', 'knowledge items', 'knowledge items'],
                      true
                    ) + ' added'}
                  </>
                ) : (
                  <>
                    <PlusIcon />
                    Add knowledge
                  </>
                )}
              </>
            </Button>
          </Dropdown>
        </SmoothVisibility>
        <Button
          disabled={isCopied}
          onClick={onCopy}
          size='mini'
          className='copy-prompt'
        >
          {!isCopied ? 'Copy prompt' : 'Prompt copied'}
        </Button>
        <Button
          size='mini'
          className='send-prompt'
          onClick={onSend}
          disabled={isSendButtonDisabled}
        >
          Send prompt
        </Button>
      </div>
    </Modal>
  );
};

interface Props {
  inp: any;
  inputValues: any;
  handleInputChange: (value: string, variableName: string) => void;
  isLastInput: boolean;
  isFocused: boolean;
  setFocused: () => void;
  onSend: () => void;
  tabIndex?: number;
  onFocusNext: () => void;
}

const TextArea: React.FC<Props> = (props: any) => {
  const ref2 = useRef<HTMLTextAreaElement>(null);
  const [prompt, setPrompt] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);

  useEffect(() => {
    props.handleInputChange(prompt, props.inp.variable_name);
  }, [prompt]);

  useEffect(() => {
    isRecording && ref2.current?.focus();
  }, [isRecording]);

  useEffect(() => {
    if (props.isFocused) ref2.current?.focus();
  }, [props.isFocused]);

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    props.handleInputChange(e.target.value, props.inp.variable_name);
  };

  return (
    <>
      <VoiceInput
        onChange={(isActive) => {
          setIsRecording(isActive);
        }}
        prompt={props.inputValues[props.inp.variable_name] || ''}
        setPrompt={setPrompt}
        tabIndex={-1}
      />
      <ReactTextareaAutosize
        placeholder={
          props.inp.placeholder.length > 0
            ? props.inp.placeholder
            : props.inp.variable_name
        }
        value={props.inputValues[props.inp.variable_name] || ''}
        onChange={handleTextAreaChange}
        ref={ref2}
        className={`prompt_detail_modal_textarea`}
        title=''
        required
        style={{ overflow: 'hidden' }}
        onKeyDown={(e) => {
          if (e.key === 'Escape')
            ExtensionEvents.dispatch(ExtensionEventType.StopAllVoiceInputs);
          if (e.key === 'Enter') {
            if (isMacOs ? e.metaKey : e.ctrlKey) {
              e.preventDefault();
              props.onSend();

              return;
            }
            e.preventDefault();
            props.onFocusNext();
          }
        }}
        onFocus={props.setFocused}
      />
    </>
  );
};

export default TextArea;
