import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import * as styles from './DocumentCard.module.scss';

export const DocumentCardSkeleton: FC = () => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map(item => (
        <div key={item} className={styles.skeletonWrapper}>
          <Skeleton className={styles.skeletonName} />
          <Skeleton className={styles.skeletonPreview} />
          <Skeleton className={styles.skeletonTime} />
        </div>
      ))}
    </div>
  );
};
