import { CSSProperties, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';

import { IConversation } from '../../../../../external/extension/service/base/ai/interfaces';
import {
  IChatFolder,
  IChatFolderConversation,
} from '../../../../../external/extension/service/base/interfaces';
import DeleteIcon from '../../../../../external/extension/ui/design/assets/svg/icons/DeleteIcon';
import { DropdownIcon } from '../../../../../external/extension/ui/design/assets/svg/icons/DropdownIcon';
import FolderIcon from '../../../../../external/extension/ui/design/assets/svg/icons/FolderIcon';
import { Dropdown } from '../../../../../external/extension/ui/design/components/dropdown';
import { SmoothVisibility } from '../../../../../external/extension/ui/design/components/smoothVisibility';
import { Chat } from './Chat';
import { FolderEditModal } from './external/modal/folders/edit';

export const folderColors = [
  'rgba(189, 189, 189, 1)',
  'rgba(162, 167, 255, 1)',
  'rgba(255, 188, 63, 1)',
  'rgba(59, 232, 255, 1)',
  'rgba(207, 107, 255, 1)',
  'rgba(253, 58, 116, 1)',
  'rgba(10, 255, 157, 1)',
];

export interface FolderProps {
  folderChats?: IConversation[];
  chatProps?: {
    onAddToFolder: (chat: IConversation) => void;
    onRename: (chat: IConversation) => void;
    onDelete: (chat: IConversation) => void;
    activeId?: number;
  };
  onFolderDelete?: () => void;
  onFolderChange?: (color: string, name: string) => void;
  onDropConversation?: (item: { id: string; title: string }) => void;
  onFolderRename?: (newName: string) => void;
  onRemoveConversationFromFolder?: (conversation: string) => void;
  onClick?: () => void;
  size?: 'full-size' | 'default';
  isOneOfFirstTwo?: boolean;
  hideDropdown?: boolean;
  mustBeOpenByDefault?: boolean;
  updateConversationTitle?: (newTitle: string, chat: IChatFolderConversation) => void;
}

export function Folder({ size = 'default', ...props }: IChatFolder & FolderProps) {
  const [expanded, setExpanded] = useState<boolean>(props.mustBeOpenByDefault ?? false);

  const [editFolderModalVisible, setEditFolderModalVisible] = useState<boolean>(false);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CONVERSATION_ITEM',
    drop: (item: any) => {
      props.onDropConversation?.(item); // Обработка перемещения элемента в папку
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (props.mustBeOpenByDefault) {
      setExpanded(true);
    }
  }, [props.mustBeOpenByDefault]);

  return (
    <>
      <div
        ref={drop}
        className={'folder ' + (expanded ? 'expanded ' : '') + size}
        style={{ '--folder-color': props.color } as CSSProperties}
      >
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (size === 'full-size') props.onClick?.();
            else setExpanded(prev => !prev);
          }}
          className="folder-head"
        >
          <FolderIcon />
          <div className="main-info">
            <p className="title">{props.name}</p>
            <p className="description">{isOver && 'Move here'}</p>
          </div>

          <SmoothVisibility visible={!props?.hideDropdown} className="action-buttons">
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Dropdown
                items={[
                  {
                    kind: 'item',
                    onClick: () => setEditFolderModalVisible(true),
                    text: 'Edit folder',
                    icon: <FolderIcon color="currentColor" />,
                  },
                  {
                    kind: 'item',
                    onClick: props.onFolderDelete,
                    text: 'Delete folder',
                    icon: <DeleteIcon color="currentColor" />,
                    danger: true,
                  },
                ]}
              >
                <DropdownIcon />
              </Dropdown>
            </div>
          </SmoothVisibility>
        </div>
        <SmoothVisibility
          visible={(props?.folderChats ?? []).length > 0 && expanded}
          className="folder-chats"
        >
          {props.folderChats?.map(chat => {
            return (
              <Chat
                onDelete={() => {
                  props.chatProps?.onDelete?.(chat);
                }}
                onRename={() => props.chatProps?.onRename(chat)}
                isActive={props.chatProps?.activeId === chat?.id}
                onAddToFolder={() => props.chatProps?.onAddToFolder(chat)}
                chat={chat}
                key={chat.id}
              />
            );
          })}
        </SmoothVisibility>
      </div>

      <FolderEditModal
        folder={props}
        visible={editFolderModalVisible}
        setVisible={setEditFolderModalVisible}
        onSave={(color, name) => props.onFolderChange?.(color, name)}
      />
    </>
  );
}
