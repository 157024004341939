export default function SidebarToggleIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3752 16.0417H5.62501C4.70454 16.0417 3.95834 15.2955 3.95834 14.375V5.62504C3.95834 4.70457 4.70453 3.95837 5.62501 3.95837H14.3752C15.2957 3.95837 16.0419 4.70457 16.0419 5.62504V14.375C16.0419 15.2955 15.2957 16.0417 14.3752 16.0417Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 13.5417L13.125 6.45837"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
