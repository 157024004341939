import { useNavigate } from 'react-router-dom';

import { IAuthLoginBody, IAuthResponseWithStatus } from '../../models/IAuth';
import { useLoginMutation } from '../../services/auth';
import { errorMessages } from '../../services/errors';
import { PAGES_ENUM } from '../../utils/constants';
import { handleError, handleSuccess } from '../../utils/notifications';

export const useSignIn = () => {
  const navigate = useNavigate();
  const [login] = useLoginMutation();

  const handleSignIn = async (values: IAuthLoginBody) => {
    const { remember, email, password } = values;
    const user = {
      email,
      password
    };

    try {
      const data = (await login(user).unwrap()) as IAuthResponseWithStatus;

      if (data?.status >= 200 && data?.status < 400) {
        const token = data?.auth_token;
        const userData = data?.user as any;

        if (token && userData) {
          if (remember) {
            localStorage.setItem('auth_token', token);
            localStorage.setItem('ref_code', userData.referral_code);
            localStorage.setItem(
              'onboarding_passed',
              String(userData.onboarding_passed)
            );
          } else {
            sessionStorage.setItem('auth_token', token);
            sessionStorage.setItem('ref_code', userData.referral_code);
            sessionStorage.setItem(
              'onboarding_passed',
              String(userData.onboarding_passed)
            );
          }
          localStorage.setItem(
            'show_download_extension',
            String(userData.show_again)
          );

          if (userData.onboarding_passed) {
            navigate(PAGES_ENUM.HOME, { replace: true });
            handleSuccess('Successfully logged in');
          } else if (!userData.onboarding_passed) {
            navigate(PAGES_ENUM.ONBOARDING_FIRST_STEP);
          }
        }
      }
    } catch (error: any) {
      if (error?.data?.error === 'Incorrect credentials') {
        handleError(errorMessages.INCORRECT_CREDENTIALS);
      } else {
        handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
        console.error('Login failed:', error);
      }
    }
  };

  return { handleSignIn };
};
