import './index.scss';

import React from 'react';

import { IChatFolder } from '../../../../../../../../../external/extension/service/base/interfaces';
import PlusIcon from '../../../../../../../../../external/extension/ui/design/assets/svg/icons/PlusIcon';
import { TrashIcon } from '../../../../../../../../../external/extension/ui/design/assets/svg/icons/TrashIcon';
import { Button } from '../../../../../../../../../external/extension/ui/design/components/button';
import { Modal } from '../../../../../../../../../external/extension/ui/design/components/modal';
import { Folder } from '../../../../Folder';

interface IMoveToFolderModal {
  isVisible: boolean;
  folders: IChatFolder[];
  modalItemInFolder: boolean;
  onClose?: () => void;
  onFolderCreate?: () => void;
  onFolderDelete?: (folder: IChatFolder, callback?: CallableFunction) => void;
  onFolderRename?: (folder: IChatFolder, newName: string) => void;
  onFolderChange?: (folder: IChatFolder, color: string, name: string) => void;
  onAddConversationToFolder?: (folder: IChatFolder | null) => void;
}
export const MoveToFolderModal: React.FC<IMoveToFolderModal> = (
  props: IMoveToFolderModal
) => {
  return (
    <Modal
      title='Move to folder'
      hasCloseButton={true}
      visible={props.isVisible}
      onClose={props.onClose}
      className='move-folder-modal'
      theme='blob'
    >
      <div className='content'>
        <div className='info'>
          <div className='title'>Current folders</div>
          <div className='description'>
            You can select one of your existing folders below.
          </div>
        </div>
        <div className='folders'>
          {props.folders.map((folder, index) => (
            <Folder
              hideDropdown={true}
              {...folder}
              onClick={() => {
                props.onAddConversationToFolder?.(folder);
              }}
              size='full-size'
              onFolderDelete={() => {
                props.onFolderDelete?.(folder);
              }}
              onFolderRename={(newName: string) => {
                props.onFolderRename?.(folder, newName);
              }}
              onFolderChange={(color: string, name: string) => {
                props.onFolderChange?.(folder, color, name);
              }}
              key={folder.id}
              isOneOfFirstTwo={index < 2}
            />
          ))}
        </div>
      </div>
      <hr />
      <div className='buttons-wrapper'>
        {props.modalItemInFolder && (
          <Button
            onClick={() => {
              props.onAddConversationToFolder?.(null);
            }}
          >
            <TrashIcon style={{ width: 20, height: 20 }} />
            Remove from folder
          </Button>
        )}

        <Button
          onClick={() => {
            props.onFolderCreate?.();
          }}
        >
          <PlusIcon />
          New folder
        </Button>
      </div>
    </Modal>
  );
};
