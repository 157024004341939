import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const RightAlignmentIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M3.95801 11.0422L3.95801 8.95866C3.95801 8.03818 4.7042 7.29199 5.62467 7.29199L11.0413 7.29199C11.9618 7.29199 12.708 8.03819 12.708 8.95866V11.0422C12.708 11.9627 11.9618 12.7089 11.0413 12.7089L5.62467 12.7089C4.7042 12.7089 3.95801 11.9627 3.95801 11.0422Z"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.042 3.95801V16.0413"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
