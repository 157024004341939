import './Sidebar.external.scss';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LogOutIcon } from '../../assets/svg/LogOutIcon';
import { PuzzleIcon } from '../../assets/svg/PuzzleIcon';
import { BlobTheme } from '../../external/extension/interfaces';
import { ExtensionEvents } from '../../external/extension/service/events';
import { ExtensionEventType } from '../../external/extension/service/events/types';
import { HelpIcon } from '../../external/extension/ui/design/assets/svg/icons/HelpIcon';
import ImageIcon from '../../external/extension/ui/design/assets/svg/icons/ImageIcon';
import SettingsIconAlt from '../../external/extension/ui/design/assets/svg/icons/SettingsIconAlt';
import { Avatar } from '../../external/extension/ui/design/components/avatar';
import { Dropdown } from '../../external/extension/ui/design/components/dropdown';
import { confirm } from '../../external/extension/ui/design/components/modal/confirmation';
import { useLogOut } from '../../hooks/useLogOut';
import { getIsUserAuthorizedWithGoogle } from '../../services/storageService';
import { PAGES_ENUM } from '../../utils/constants';
import * as styles from './Sidebar.module.scss';
import { SidebarNavigation } from './SidebarNavigation';
import { Button } from '../../external/extension/ui/design/components/button';

export const AnonymousSidebar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

  const fullName = 'Alchemist';

  const isGoogleAuthenticate = getIsUserAuthorizedWithGoogle();
  const { onLogout, onGoogleLogout } = useLogOut();
  const navigate = useNavigate();

  const handleLogout = () => {
    confirm({
      title: 'Log out',
      theme: 'blob',
      text: 'Are you sure you want to sign out?',
      primary: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, sign out',
      handleConfirm: async () => {
        ExtensionEvents.dispatch(ExtensionEventType.TurnOffLightTheme);
        setTheme(BlobTheme.Dark);

        if (isGoogleAuthenticate) {
          onGoogleLogout();
        } else {
          onLogout();
        }

        ExtensionEvents.dispatch(ExtensionEventType.BlobExtensionInstantLogout);
      }
    });
  };

  useEffect(() => {
    if (isSidebarCollapsed) {
      document.body.classList.add('sidebar-collapsed');
    } else {
      document.body.classList.remove('sidebar-collapsed');
    }
  }, [isSidebarCollapsed]);

  const [theme, setTheme] = useState<BlobTheme>(
    localStorage.getItem('app-theme') === 'light'
      ? BlobTheme.Light
      : BlobTheme.Dark
  );

  useEffect(() => {
    setTheme(
      localStorage.getItem('app-theme') === 'light'
        ? BlobTheme.Light
        : BlobTheme.Dark
    );
  });

  return (
    <div
      className={classNames(styles.sidebar, {
        [styles.sidebarCollapsed]: isSidebarCollapsed
      })}
    >
      <div className={styles.sidebarNavContainer}>
        <SidebarNavigation
          isAnonymous={true}
          isSidebarCollapsed={isSidebarCollapsed}
          isLoading={false}
          isUpgradeNeeded={false}
          isSubscribtionFetching={false}
          onCollapseButtonClicked={() => setIsSidebarCollapsed((prev) => !prev)}
        />
      </div>

      <div className="anonymous-login">
        <Button isLink={true} linkTo={'/sign-in'}>Log In</Button>
        <Button active={true} isLink={true} linkTo={'/sign-up'}>Sign Up</Button>
      </div>
    </div>
  );
};
