import { useSearchParams } from 'react-router-dom';

import { MainContentLayout } from '../../components/Layouts/MainContentLayout';
import { useLoading } from '../../hooks/useGlobalLoading';
import { IUser } from '../../models/ISettings';
import { errorMessages } from '../../services/errors';
import {
  useChangePasswordMutation,
  useGetSettingsQuery,
  useSetSettingsMutation
} from '../../services/settings';
import { handleError, handleSuccess } from '../../utils/notifications';
import { BillingAndPlansTabContent } from './components/BillingAndPlansTabContent';
import { GeneralTabContent } from './components/GeneralTabContent';
import { SettingTab } from './components/SettingTab';
import { SettingsTabs } from './components/SettingTab/types';
import * as styles from './SettingsPage.module.scss';

interface ISettings extends IUser {
  new_password?: string;
  old_password?: string;
  confirmPassword?: string;
  newUsername?: string;
  newEmail?: string;
  avatarFile?: File;
}

const TABS = [
  {
    id: 1,
    title: 'General',
    type: SettingsTabs.GENERAL
  },
  {
    id: 2,
    title: 'Billing & Plans',
    type: SettingsTabs.BILLING_AND_PLANS
  }
];

export const SettingsPage = ({ currTab = null }) => {
  const [searchParams] = useSearchParams();
  const tab = currTab ?? (searchParams.get('tab') || SettingsTabs.GENERAL);
  const { data, isLoading } = useGetSettingsQuery();

  useLoading(isLoading);

  const keyGeneralTab = data ? 0 : 1;

  const generalTabProps = {
    first_name: data?.first_name,
    last_name: data?.last_name,
    username: data?.username,
    avatar: data?.avatar,
    email: data?.email,
    language_preference: data?.language_preference
  };

  const [setSettings] = useSetSettingsMutation();
  const [changePassword] = useChangePasswordMutation();

  const deletePasswordPropsFromSettings = (settings: ISettings): IUser => {
    delete settings.new_password;
    delete settings.old_password;
    delete settings.confirmPassword;

    return settings;
  };

  const onSetSettings = async (settings: any) => {
    try {
      if (settings?.new_password && settings?.old_password) {
        await changePassword({
          new_password: settings.new_password,
          old_password: settings.old_password
        }).unwrap();
      }

      if (!settings?.avatar) {
        delete settings?.avatar;
        delete settings?.avatarFile;
      }

      if (settings?.newUsername)
        settings = { ...settings, username: settings?.newUsername };

      if (settings?.newEmail) {
        settings = { ...settings, email: settings?.newEmail };
      }

      delete settings?.newUsername;
      delete settings?.newEmail;

      const settingsWithoutPasswordProps =
        deletePasswordPropsFromSettings(settings);

      const resSettings = { ...settingsWithoutPasswordProps } as any;

      const formData = new FormData();

      for (const key in resSettings) {
        formData.append(key, resSettings[key]);
      }

      await setSettings(formData).unwrap();
      handleSuccess('Settings successfully updated');
    } catch (error: any) {
      if (error?.data?.non_field_errors?.[0] === 'Incorrect Credentials') {
        handleError(errorMessages.PASSWORD_UPDATE_FAILED);
      } else {
        handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
      }
    }
  };

  return (
    <MainContentLayout title='Settings' isRenderSearch={false}>
      <div className={styles.settings}>
        <div className={styles.settingsTabsContainer}>
          {TABS.map((t) => {
            const { id, title, type } = t;

            return (
              <SettingTab
                key={id}
                title={title}
                isActive={tab === type}
                isLoading={isLoading}
                type={type}
              />
            );
          })}
        </div>
        {tab === SettingsTabs.GENERAL && (
          <GeneralTabContent
            key={keyGeneralTab}
            generalTabProps={generalTabProps as any}
            onSetSettings={onSetSettings}
            isLoading={isLoading}
          />
        )}
        {tab === SettingsTabs.BILLING_AND_PLANS && (
          <BillingAndPlansTabContent />
        )}
      </div>
    </MainContentLayout>
  );
};
