import './TextEditor.scss';

import { EditorContent } from '@tiptap/react';
import cn from 'classnames';
import React from 'react';

import { ITextEditor } from './types';

export const TextEditor: React.FC<ITextEditor> = ({
  editor,
  containerClassName
}) => {
  if (!editor) return null;

  const handleEditorClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    let element = e.target as HTMLElement;

    while (element && element.tagName !== 'A' && element !== e.currentTarget) {
      element = element.parentElement as any;
    }

    if (element && element.tagName === 'A') {
      const linkElement = document.createElement('a');

      linkElement.setAttribute('target', '_blank');
      linkElement.setAttribute('rel', 'noopener noreferrer nofollow');
      linkElement.href = (element as HTMLAnchorElement).href;
      linkElement.click();
    }
  };

  return (
    <div
      className={cn(containerClassName, 'custom-editor')}
      onClick={(e) => handleEditorClick(e)}
    >
      <EditorContent editor={editor} />
    </div>
  );
};
