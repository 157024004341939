import { Editor } from '@tiptap/react';
import { useCallback, useState } from 'react';

import { BoldFontIcon } from '../../../../assets/svg/BoldFontIcon';
import { HeadingIcon } from '../../../../assets/svg/HeadingIcon';
import { HorizontalAlignmentIcon } from '../../../../assets/svg/HorizontalAlignmentIcon';
import { ItalicFontIcon } from '../../../../assets/svg/ItalicFontIcon';
import { LeftAlignmentIcon } from '../../../../assets/svg/LeftAlignmentIcon';
import { LinkIcon } from '../../../../assets/svg/LinkIcon';
import { OrderedListIcon } from '../../../../assets/svg/OrderedListIcon';
import { RightAlignmentIcon } from '../../../../assets/svg/RightAlignmentIcon';
import { TrashIcon } from '../../../../assets/svg/TrashIcon';
import { UnderlineIcon } from '../../../../assets/svg/UnderlineIcon';
import { UnorderedListIcon } from '../../../../assets/svg/UnorderedListIcon';
import { VerticalAlignmentIcon } from '../../../../assets/svg/VerticalAlignmentIcon';
import { setModal } from '../../../../components/Modal/ModalSlice';
import { ModalTypes } from '../../../../components/Modal/types/enums/ModalTypes';
import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { EMITTER_EVENTS } from '../../../../utils/eventEmitter';
import { ToolbarButton } from '../ToolbarButton';
import { LinkModal } from './LinkModal';
import * as styles from './MenuBarTipTap.module.scss';

interface IMenuBarTipTap {
  editor: Editor;
  documentId: number;
  isTrash?: boolean;
}
export const MenuBarTipTap: React.FC<IMenuBarTipTap> = ({ editor, documentId, isTrash }) => {
  const dispatch = useTypedDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState<string>('');

  const openModal = useCallback(() => {
    setUrl(editor.getAttributes('link').href || '');
    setIsOpen(true);
  }, [editor]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setUrl('');
  }, []);

  const onDelete = () => {
    dispatch(
      setModal({
        modalType: ModalTypes.DELETE_DOCUMENT_MODAL,
        modalProps: { id: documentId, isTrash, emmitType: EMITTER_EVENTS.MODAL_DELETE_DOCUMENT },
      })
    );
  };

  const saveLink = useCallback(() => {
    if (url) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run();
    } else {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }
    closeModal();
  }, [editor, url, closeModal]);

  const removeLink = useCallback(() => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    closeModal();
  }, [editor, closeModal]);

  return (
    <div className={styles.bookmarkToolbarButtonsContainer}>
      <div className={styles.bookmarkToolbarFontButtons}>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={isTrash || !editor.can().chain().focus().toggleBold().run()}
          isActive={editor.isActive('bold') ? true : false}
          icon={<BoldFontIcon />}
        />
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={isTrash || !editor.can().chain().focus().toggleItalic().run()}
          isActive={editor.isActive('italic') ? true : false}
          icon={<ItalicFontIcon />}
        />
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={isTrash || !editor.can().chain().focus().toggleUnderline().run()}
          isActive={editor.isActive('underline') ? true : false}
          icon={<UnderlineIcon />}
        />
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          disabled={isTrash || !editor.can().chain().focus().toggleHeading({ level: 2 }).run()}
          isActive={editor.isActive('heading') ? true : false}
          icon={<HeadingIcon />}
        />
      </div>
      <div className={styles.bookmarkToolbarFontButtons}>
        <ToolbarButton
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          disabled={isTrash}
          isActive={editor.isActive({ textAlign: 'center' }) ? true : false}
          icon={<HorizontalAlignmentIcon />}
        />
        <ToolbarButton
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          disabled={isTrash}
          isActive={editor.isActive({ textAlign: 'left' }) ? true : false}
          icon={<LeftAlignmentIcon />}
        />
        <ToolbarButton
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          disabled={isTrash}
          isActive={editor.isActive({ textAlign: 'right' }) ? true : false}
          icon={<RightAlignmentIcon />}
        />
        <ToolbarButton
          onClick={() => editor.chain().focus().setTextAlign('justify').run()}
          disabled={isTrash}
          isActive={editor.isActive({ textAlign: 'justify' }) ? true : false}
          icon={<VerticalAlignmentIcon />}
        />
      </div>
      <div className={styles.bookmarkToolbarFontButtons}>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isActive={false}
          disabled={isTrash}
          icon={<UnorderedListIcon />}
        />
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={false}
          disabled={isTrash}
          icon={<OrderedListIcon />}
        />
      </div>

      <div className={styles.bookmarkToolbarCopyLinkButton}>
        <ToolbarButton
          isActive={editor.isActive('link')}
          onClick={openModal}
          icon={<LinkIcon />}
          disabled={isTrash}
        />
      </div>

      <div className={styles.bookmarkToolbarDeleteButton}>
        <ToolbarButton icon={<TrashIcon />} isActive={false} onClick={onDelete} />
      </div>

      <LinkModal
        url={url}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Link Modal"
        closeModal={closeModal}
        onChangeUrl={e => setUrl(e.target.value)}
        onSaveLink={saveLink}
        onRemoveLink={removeLink}
      />
    </div>
  );
};
