// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button-module__button___b_aG7{display:flex;justify-content:center;align-items:center;gap:4px;font-family:var(--font-Inter);font-size:14px;line-height:20px;font-weight:300;color:var(--primary-1000);padding:10px 16px;border-radius:999px;text-align:center;background:var(--button-gradient);max-width:248px}.Button-module__purple___cHdV1{background:var(--app-accent-gradient) !important;color:var(--app-text-on-accent) !important;border:1px solid hsla(0,0%,100%,.08);padding:9px 20px;border-radius:999px !important}.Button-module__purple___cHdV1:hover{background:radial-gradient(127.76% 230.29% at 5.64% -71.25%, #6e37ff 0%, #8d62ff 29.09%, #63e 88.59%)}.Button-module__secondary___sMAVa{color:var(--arsenic-100) !important;background:var(--button-surface) !important}.Button-module__secondary___sMAVa:hover{background:hsla(0,0%,100%,.12)}.Button-module__red___R0esw{background:var(--error-500);color:var(--base-white);box-shadow:0px 0px 0px .75px rgba(16,24,40,.08) inset}.Button-module__red___R0esw:hover{background:var(--error-500);color:var(--base-white)}.Button-module__disabled___HULEw{color:var(--arsenic-500);background:var(--button-surface);box-shadow:0px 0px 0px .5px hsla(0,0%,100%,.16) inset,0px 0px 0px 1px rgba(0,0,0,.25)}.Button-module__disabled___HULEw:hover{background:var(--button-surface);color:var(--arsenic-500)}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,OAAA,CACA,6BAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,yBAAA,CACA,iBAAA,CACA,mBAAA,CACA,iBAAA,CACA,iCAAA,CACA,eAAA,CAGF,+BACE,gDAAA,CACA,0CAAA,CACA,oCAAA,CACA,gBAAA,CACA,8BAAA,CAEA,qCACE,qGAAA,CASJ,kCACE,mCAAA,CACA,2CAAA,CAEA,wCACE,8BAAA,CAIJ,4BACE,2BAAA,CACA,uBAAA,CACA,qDAAA,CAEA,kCACE,2BAAA,CACA,uBAAA,CAIJ,iCACE,wBAAA,CACA,gCAAA,CACA,qFAAA,CAEA,uCACE,gCAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
export var button = `Button-module__button___b_aG7`;
export var purple = `Button-module__purple___cHdV1`;
export var secondary = `Button-module__secondary___sMAVa`;
export var red = `Button-module__red___R0esw`;
export var disabled = `Button-module__disabled___HULEw`;
export default ___CSS_LOADER_EXPORT___;
