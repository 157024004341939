export interface ICommandIcon {
  color?: string;
  size?: number;
}
export default function CommandIcon(props: ICommandIcon) {
  return (
    <svg
      width={props.size ?? '21'}
      height={props.size ?? '21'}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26823 5.95312C5.34775 5.95312 4.60156 6.69932 4.60156 7.61979V13.8698C4.60156 14.7903 5.34775 15.5365 6.26823 15.5365H12.8307H15.0182C15.9387 15.5365 16.6849 14.7903 16.6849 13.8698V7.61979C16.6849 6.69932 15.9387 5.95312 15.0182 5.95312H6.26823Z"
        stroke={props.color ?? 'currentColor'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3125 8.96452L9.22937 10.7445L7.3125 12.5244"
        stroke={props.color ?? 'currentColor'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
