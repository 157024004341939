import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { EmptyPageAlert } from '../../../../components/EmptyPageAlert';
import { IPrompt } from '../../../../models/IPromptLibrary';
import { promptsPreview } from '../../../../utils/skeletonPreview';
import { Prompt } from '../Prompt';
import * as styles from './FavoritePromptsTabContent.module.scss';

interface IFavoritePrompts {
  favoritePrompts?: IPrompt[];
  onFavorite: (prompt: IPrompt) => () => void;
  onAddToCommand: (prompt: IPrompt, promptType: number) => void;
  isLoading: boolean;
  onPromptSelect: (id: number) => void;
}

export const FavoritePromptsTabContent: React.FC<IFavoritePrompts> = ({
  favoritePrompts,
  onFavorite,
  onAddToCommand,
  isLoading,
  onPromptSelect
}) => {
  return (
    <>
      {isLoading ? (
        <div className={styles.promptLibraryPromptsPreviewContainer}>
          {promptsPreview.map((preview) => (
            <div className={styles.promptPreview} key={preview.id}>
              <div className={styles.header}>
                <div className={styles.leftPart}>
                  <Skeleton />
                </div>

                <div className={styles.rightPart}>
                  <Skeleton />
                  <Skeleton />
                </div>
              </div>
              <hr />

              <div className={styles.title}>
                <Skeleton />
              </div>
              <div className={styles.description}>
                <Skeleton />
                <Skeleton className='short' />
              </div>
              <div className={styles.otherInfo}>
                <Skeleton />
                <Skeleton />
              </div>
            </div>
          ))}
        </div>
      ) : (favoritePrompts?.length ?? 0) > 0 ? (
        <div className={styles.promptLibraryPromptsContainer}>
          {favoritePrompts?.map((prompt) => {
            return (
              <Prompt
                key={prompt.id}
                prompt={prompt as IPrompt}
                onFavorite={onFavorite(prompt)}
                onAddToCommand={onAddToCommand}
                onPromptSelect={onPromptSelect}
              />
            );
          })}
        </div>
      ) : (
        <div className={styles.promptLibraryAlertContainer}>
          <EmptyPageAlert
            title='No Favourite Prompt Available'
            description='Favourite a prompt and access it easily from this section.'
          />
        </div>
      )}
    </>
  );
};
