import { createRef, useState } from 'react';

import { GraphicTools } from '../../../../../../tools/graphics';
import DownloadIconAlt from '../../../../../design/assets/svg/icons/DownloadIconAlt';
import { LoadingOverlay } from '../../../../../design/components/loading';
import SmoothImage from '../../../../../design/components/smoothImage/SmoothImage';
import { SmoothVisibility } from '../../../../../design/components/smoothVisibility';

export interface IMessageImage {
  url?: string;
  isDalle?: boolean;
  isAvatar?: boolean;
  onClick?: () => void;
}

export function MessageImage(props: IMessageImage) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const imageRef = createRef<HTMLDivElement>();

  const placeholderImg =
    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

  return (
    <div
      className={
        'image-wrapper' + (props.isDalle ? ' dalle-image' : '') + (props.isAvatar ? ' avatar' : '')
      }
      ref={imageRef}
      style={{ width: '280px' }}
      onClick={props.onClick}
    >
      <SmoothVisibility
        className="download-button"
        as="button"
        visible={!!props.url}
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();

          setIsLoading(true);

          if (props.url) await GraphicTools.downloadImage(props.url); // This will use an MD5 hash-based filename by default

          setIsLoading(false);
        }}
      >
        <DownloadIconAlt />
      </SmoothVisibility>
      <div className="blurred-image">
        {props.url ? (
          <SmoothImage
            alt=""
            onLoad={() => setIsLoading(false)}
            src={props.url}
            transitionTime={0.3}
          />
        ) : (
          <img src={placeholderImg} style={{ width: '100%', height: '100%' }} alt="message" />
        )}
      </div>
      <div className="main-image">
        {props.url ? (
          <SmoothImage
            alt=""
            onLoad={() => setIsLoading(false)}
            src={props.url}
            transitionTime={0.3}
          />
        ) : (
          <img src={placeholderImg} style={{ width: '100%', height: '100%' }} alt="message" />
        )}

        <LoadingOverlay active={!props.url || isLoading} />
      </div>
    </div>
  );
}
