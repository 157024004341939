import React from 'react';

import { useTypedDispatch } from '../../hooks/useTypedDispatch';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { DeleteAccountModal } from '../../pages/SettingsPage/components/DeleteAccountModal';
import { Modal } from './Modal';
import { AccountDowngradeModal } from './modals/AccountDowngradeModal';
import { CreateCategoryModal } from './modals/CreateCategoryModal/CreateCategoryModal';
import { CreateDocumentModal } from './modals/CreateDocumentModal/CreateDocumentModal';
import { DeleteDocumentModal } from './modals/DeleteDocumentModal/DeleteDocumentModal';
import { DeleteSnippetModal } from './modals/DeleteSnippetModal';
import { LogoutModal } from './modals/LogoutModal';
import { ProSubscribtionModal } from './modals/ProSubscribtionModal';
import { deleteModal, selectModal } from './ModalSlice';
import { ModalTypes } from './types/enums/ModalTypes';

const MODAL_COMPONENTS = {
  [ModalTypes.DELETE_ACCOUNT]: {
    component: DeleteAccountModal,
    ariaLabel: 'delete account modal'
  },
  [ModalTypes.DELETE_DOCUMENT_MODAL]: {
    component: DeleteDocumentModal,
    ariaLabel: 'delete document modal'
  },
  [ModalTypes.CREATE_CATEGORY_MODAL]: {
    component: CreateCategoryModal
  },
  [ModalTypes.CREATE_DOCUMENT_MODAL]: {
    component: CreateDocumentModal
  },
  [ModalTypes.LOG_OUT_MODAL]: {
    component: LogoutModal
  },
  [ModalTypes.PRO_SUBSCRIBTION_MODAL]: {
    component: ProSubscribtionModal,
    ariaLabel: 'pro subscribtion',
    contentClassName: 'proModalContent'
  },
  [ModalTypes.DELETE_SNIPPET_MODAL]: {
    component: DeleteSnippetModal,
    ariaLabel: 'delete snippet'
  },
  [ModalTypes.ACCOUNT_DOWNGRADE_WARNING_MODAL]: {
    component: AccountDowngradeModal,
    ariaLabel: 'account downgrade'
  }
};

export const ModalsRoot: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { modalType, modalProps } = useTypedSelector(selectModal);

  const handleClose = () => {
    dispatch(deleteModal());
  };

  const ModalComponent = (MODAL_COMPONENTS as any)[modalType]?.component;
  const ariaLabel = (MODAL_COMPONENTS as any)[modalType]?.ariaLabel || '';
  const contentClassName =
    (MODAL_COMPONENTS as any)[modalType]?.contentClassName || '';

  return (
    <Modal
      isOpen={!!modalType}
      ariaLabel={ariaLabel}
      onRequestClose={handleClose}
      contentClassName={contentClassName}
    >
      {modalType && (
        <ModalComponent
          {...modalProps}
          dispatch={dispatch}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
};
