import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const VerticalAlignmentIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M3.95801 3.95801H16.0413"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.95801 16.042H16.0413"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 11.0422L5.625 8.95866C5.625 8.03818 6.37119 7.29199 7.29167 7.29199L12.7083 7.29199C13.6288 7.29199 14.375 8.03819 14.375 8.95866V11.0422C14.375 11.9627 13.6288 12.7089 12.7083 12.7089L7.29167 12.7089C6.37119 12.7089 5.625 11.9627 5.625 11.0422Z"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
