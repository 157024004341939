import './KnowledgeTypeSwitch.scss';

import classNames from 'classnames';

export enum KnowledgeType {
  Knowledge = 'knowledge',
  SourceMaterial = 'source-material',
}

export interface IKnowledgeTypeSwitch {
  type: KnowledgeType;
  onChange: (type: KnowledgeType) => void;
}

export function KnowledgeTypeSwitch(props: IKnowledgeTypeSwitch) {
  const buttons = [
    { label: 'Knowledge', value: KnowledgeType.Knowledge },
    { label: 'Source Material', value: KnowledgeType.SourceMaterial },
  ];

  return (
    <div className="knowledge-type-switch">
      <div className={`primary-background highlight-${props.type}`}></div>
      {buttons.map(button => (
        <button
          key={button.value}
          className={classNames({
            active: props.type === button.value,
          })}
          onClick={() => props.onChange(button.value)}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
}
