import cn from 'classnames';
import { FC } from 'react';

import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import { selectTheme, ThemeOptions } from '../../../../../../redux/Theme';
import * as styles from './PopularLabel.module.scss';

type Props = {
  className?: string;
};

export const PopularLabel: FC<Props> = ({ className }) => {
  const theme = useTypedSelector(selectTheme);
  const isPurpleTheme = theme === ThemeOptions.Purple;

  return (
    <div className={cn(styles.popularLabel, className)}>
      <div
        className={cn({
          [styles.purpleBorderGradient]: isPurpleTheme,
        })}
      />
      <div
        className={cn(styles.popularLabelBackgroundGradient, {
          [styles.purpleBackgroundGradient]: isPurpleTheme,
        })}
      />
      <div
        className={cn(styles.popularLabelText, {
          [styles.purpleText]: isPurpleTheme,
        })}
      >
        Most Popular
      </div>
    </div>
  );
};
