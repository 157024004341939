/* eslint-disable @typescript-eslint/no-unused-vars */
import './PromptLibraryPage.external.scss';

import cn from 'classnames';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DownloadExtensionReminder } from '../../components/DownloadExtensionReminder';
import { MainContentLayout } from '../../components/Layouts/MainContentLayout/MainContentLayout';
import { ExtensionService } from '../../external/extension/service';
import { useExtensionServiceContext } from '../../external/extension/service/context';
import {
  TempAppMemory,
  TempAppMemoryKey
} from '../../external/extension/service/temp/memory';
import { useAsyncProcessManagerContext } from '../../external/extension/tools/async/context';
import { PromptDetailsModal } from '../../external/extension/ui/components/auth/modal/prompt';
import AllCategoryIcon from '../../external/extension/ui/design/assets/svg/icons/AllCategoryIcon';
import ArrowDownIcon from '../../external/extension/ui/design/assets/svg/icons/ArrowDownIcon';
import ArrowRightAltIcon from '../../external/extension/ui/design/assets/svg/icons/ArrowRightAltIcon';
import {
  Dropdown,
  IDropdownItem
} from '../../external/extension/ui/design/components/dropdown';
import { confirm } from '../../external/extension/ui/design/components/modal/confirmation';
import { useLoading } from '../../hooks/useGlobalLoading';
import {
  NOTIFICATION_TYPES,
  useNotification
} from '../../hooks/useNotification';
import {
  IAddToCommand,
  ICustomPrompt,
  IPrompt,
  ISetFavoriteRequest
} from '../../models/IPromptLibrary';
import { ICategory } from '../../models/IShared';
import {
  useAddToCommandMutation,
  useGetCategoriesQuery,
  useGetCustomPromptsQuery,
  useGetPromptsQuery,
  useGetSubCategoriesByCategoryQuery,
  useLazyGetCustomPromptsQuery,
  useLazyGetPromptsQuery,
  useSetFavoriteMutation
} from '../../services/promptLibrary';
import { useGetSubscriptionLevelQuery } from '../../services/subscribtion';
import { ErrorNode } from '../../utils/notifications';
import { subCategoriesPreview } from '../../utils/skeletonPreview';
import { AllPromptsTabContent } from './components/AllPromptsTabContent/AllPromptsTabContent';
import { CreateCustomPrompt } from './components/CreateCustomPrompt';
import { CustomPromptsTabContent } from './components/CustomPromptsTabContent';
import { FavoritePromptsTabContent } from './components/FavoritePromptsTabContent';
import { Tag } from './components/Tag';
import * as styles from './PromptLibraryPage.module.scss';
import { PromptTabs } from './types';

export interface IParams {
  categories?: number | string | null;
  text: string;
  sort: number | string;
  favorite?: boolean;
}

interface IChangeObjByFavorite {
  pre: IPrompt[] | ICustomPrompt[] | undefined;
  item: IPrompt | ICustomPrompt;
}

export const changeObjByProps =
  (willChangeProps: string) =>
  ({ pre, item }: IChangeObjByFavorite) =>
    pre!.map((currItem) =>
      currItem.id === item.id
        ? {
            ...currItem,
            [willChangeProps]: !(item as any)[willChangeProps]
          }
        : currItem
    );

export const PromptLibraryPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isFetching: isFetchingSubscriptionLevel } =
    useGetSubscriptionLevelQuery();
  const [selectedPromptId, setSelectedPromptId] = useState<number | null>(null);
  const [isPromptDetailsModalVisible, setIsPromptDetailsModalVisible] =
    useState<boolean>(false);
  const [params, setParams] = useState<IParams>({
    text: '',
    sort: 0,
    categories: null
  });
  const [customPromptsParams, setCustomPromptsParams] = useState<IParams>({
    text: '',
    sort: 0
  });
  const [favoritePromptsParams, setFavoritePromptsParams] = useState<IParams>({
    text: '',
    sort: 0,
    favorite: true
  });

  const showDownloadExtensionLink = JSON.parse(
    localStorage.getItem('show_download_extension') ?? ''
  );

  const handlePromptSelection = (promptId: number) => {
    setSelectedPromptId(promptId);
  };

  const {
    data: dataCategories = { results: [] },
    isLoading: isLoadingCategories,
    isFetching: isFetchingCategories
  } = useGetCategoriesQuery();

  const {
    data: dataSubCategories = { results: [] },
    isLoading: isLoadingSubCategories,
    isFetching: isFetchingSubCategories
  } = useGetSubCategoriesByCategoryQuery(params?.categories ?? (null as any), {
    skip: !params?.categories
  });

  const [selectedSubCategory, setSelectedSubCategory] = useState<number | null>(
    null
  );

  const changedCategories: IDropdownItem[] = useMemo(
    () => [
      {
        kind: 'item' as any,
        active: !params.categories,
        onClick: () => {
          onChangeCategory(null);
        },
        text: 'All',
        icon: <AllCategoryIcon />
      },
      ...(dataCategories?.results?.map((category: ICategory) => ({
        kind: 'item' as any,
        active: category.id?.toString() === params.categories?.toString(),
        onClick: () => {
          onChangeCategory(category.id.toString());
        },
        text: category.name,
        icon: <img src={category.white_icon ?? ''} alt='' />
      })) ?? [])
    ],
    [dataCategories, params]
  );

  const tab = searchParams.get('tab') || PromptTabs.ALL_PROMTS;
  const isAllPromptsTab = tab === PromptTabs.ALL_PROMTS;
  const isCustomPromptsTab = tab === PromptTabs.CUSTOM_PROMTS;
  const isFavoritePromptsTab = tab === PromptTabs.FAVORITE_PROMTS;
  const [
    isCustomPromptCreationTemplateOpened,
    setIsCustomPromptCreationTemplateOpened
  ] = useState(false);

  const onAddNewCustomPrompt = () => {
    setIsCustomPromptCreationTemplateOpened(true);
  };

  const [currEditPrompt, setCurrEditPrompt] = useState<null | ICustomPrompt>(
    null
  );

  const onChangeSearch = (text: string) => {
    if (isAllPromptsTab) {
      if (text !== params?.text) {
        setParams((pre) => ({ ...pre, text }));
      }
    } else if (isCustomPromptsTab) {
      if (text !== customPromptsParams?.text) {
        setCustomPromptsParams((pre) => ({ ...pre, text }));
      }
    } else if (isFavoritePromptsTab) {
      if (text !== favoritePromptsParams?.text) {
        setFavoritePromptsParams((pre) => ({ ...pre, text }));
      }
    }
  };

  const onChangeCategory = (categories: string | null) => {
    setParams((pre) => ({ ...pre, categories }));
    setSelectedSubCategory(null);
  };

  const [setFavorite, resSetFavorite] = useSetFavoriteMutation();
  const [addToCommand, resAddToCommand] = useAddToCommandMutation();

  const [sortDesc, setSortDesc] = useState<boolean>(false);

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resSetFavorite.isError,
    error: resSetFavorite.error as ErrorNode
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resAddToCommand.isError,
    error: resAddToCommand.error as ErrorNode
  });

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>all prompt <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const [
    getAllPrompts,
    {
      data: dataPrompts = { results: [] },
      isLoading: isLoadingDataPrompts,
      isFetching: isFetchingDataPrompts
    }
  ] = useLazyGetPromptsQuery();

  const [allPrompt, setAllPrompt] = useState(dataPrompts.results);

  useLayoutEffect(() => {
    if (isFetchingDataPrompts) return;
    setAllPrompt(dataPrompts?.results);
  }, [isFetchingDataPrompts]);

  useEffect(() => {
    if (selectedSubCategory) {
      getAllPrompts({ ...params, categories: selectedSubCategory });
    } else {
      getAllPrompts(params);
    }
  }, [params]);

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>all prompt <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>favorite prompt <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const {
    data: dataFavoritePrompts = { results: [] },
    isLoading: isLoadingDataFavoritePrompts,
    isFetching: isFetchingDataFavoritePrompts,
    refetch: refetchFavorites
  } = useGetPromptsQuery(favoritePromptsParams, {
    refetchOnMountOrArgChange: true
  });

  const [favoritePrompts, setFavoritePrompts] = useState(
    dataFavoritePrompts?.results
  );

  useEffect(() => {
    if (isFetchingDataFavoritePrompts) return;
    setFavoritePrompts(dataFavoritePrompts?.results);
  }, [isFetchingDataFavoritePrompts]);

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>favorite prompt <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>custom prompt <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const {
    data: dataCustomPrompts = { results: [] },
    isLoading: isLoadingDataCustomPrompts,
    isFetching: isFetchingDataCustomPrompts
  } = useGetCustomPromptsQuery(customPromptsParams, {
    refetchOnMountOrArgChange: true
  });

  const [customPrompts, setCustomPrompts] = useState(
    dataCustomPrompts?.results
  );

  useEffect(() => {
    if (isFetchingDataCustomPrompts) return;
    setCustomPrompts(dataCustomPrompts?.results);
  }, [isFetchingDataCustomPrompts]);

  useEffect(() => {
    if (
      tab !== PromptTabs.CUSTOM_PROMTS &&
      isCustomPromptCreationTemplateOpened
    ) {
      onCloseCustomPromptCreationTemplate();
    }
  }, [tab, isCustomPromptCreationTemplateOpened]);

  const [getCustomPrompts] = useLazyGetCustomPromptsQuery();
  const onGetCustomPromptsWithParams = () =>
    getCustomPrompts(customPromptsParams);

  const onCloseCustomPromptCreationTemplate = () => {
    setIsCustomPromptCreationTemplateOpened(false);
    setCurrEditPrompt(null);
  };

  const onSuccessCreateOrEditCustomPrompt = () => {
    onGetCustomPromptsWithParams();
    onCloseCustomPromptCreationTemplate();
    window.scrollTo(0, 0);
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>custom prompt <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const onFavorite = (prompt: IPrompt, setPromptFunc: any) => async () => {
    const favoriteRequest: ISetFavoriteRequest = {
      prompt_id: prompt?.id,
      favourite: !prompt?.is_favourite
    };
    const res = (await setFavorite(favoriteRequest)) as {
      data: { status: number };
    };

    if (res?.data?.status >= 200 && res?.data?.status < 400) {
      setPromptFunc((pre: any) =>
        changeObjByProps('is_favourite')({ pre, item: prompt })
      );

      if (isAllPromptsTab) {
        if (!prompt?.is_favourite) {
          const newFavPrompt = { ...prompt, is_favourite: true };

          setFavoritePrompts((pre) => [...pre, newFavPrompt]);
        } else {
          const filteredFavPrompts = favoritePrompts?.filter(
            (favPrompt) => favPrompt.id !== prompt.id
          );

          setFavoritePrompts(filteredFavPrompts);
        }
      }

      if (isFavoritePromptsTab && prompt?.is_favourite) {
        const filteredFavPrompts = favoritePrompts?.filter(
          (favPrompt) => favPrompt.id !== prompt.id
        );

        setFavoritePrompts(filteredFavPrompts);

        setAllPrompt(
          (pre) =>
            changeObjByProps('is_favourite')({ pre, item: prompt }) as any
        );
      }
    }
  };

  const onAddToFavorites = (prompt: IPrompt) =>
    onFavorite(prompt, setAllPrompt);
  const onRemoveFromFavorites = (prompt: IPrompt) =>
    onFavorite(prompt, setFavoritePrompts);

  const onAddToCommand = async (
    prompt: IPrompt | ICustomPrompt,
    promptType: number,
    setPromptFunc: React.Dispatch<
      React.SetStateAction<ICustomPrompt[] | IPrompt[]>
    >
  ) => {
    if (!prompt) return;
    const addCommandBody: IAddToCommand = {
      prompt_pk: prompt.id,
      prompt_type: promptType,
      command: prompt.name,
      is_add: !prompt.is_in_command
    };

    const res = (await addToCommand(addCommandBody)) as {
      data: { status: number };
    };

    if (res?.data?.status >= 200 && res?.data?.status < 400) {
      setPromptFunc(
        (pre) => changeObjByProps('is_in_command')({ pre, item: prompt }) as any
      );

      if (isAllPromptsTab) {
        refetchFavorites();
      }

      if (isFavoritePromptsTab) {
        setAllPrompt(
          (pre) =>
            changeObjByProps('is_in_command')({ pre, item: prompt }) as any
        );
      }
    }
  };

  const onAddToCommandPrompt = (prompt: IPrompt, promptType: number) => {
    onAddToCommand(prompt, promptType, setAllPrompt as any);
  };

  const onAddToCommandCustomPrompt = (
    prompt: ICustomPrompt,
    promptType: number
  ) => {
    onAddToCommand(prompt, promptType, setCustomPrompts);
  };

  const onAddToCommandFavoritePrompt = (
    prompt: IPrompt,
    promptType: number
  ) => {
    onAddToCommand(prompt, promptType, setFavoritePrompts as any);
  };

  const onEdit = (currPrompt: any) => () => {
    setIsCustomPromptCreationTemplateOpened(true);
    setCurrEditPrompt(currPrompt);
  };

  const asyncProccessManager = useAsyncProcessManagerContext();

  const onDelete = () => {
    confirm({
      text: 'Are you sure you want delete this custom prompt?',
      primary: true,
      danger: true,
      theme: 'blob',
      handleConfirm: async () => {
        await asyncProccessManager?.doProcess({
          name: 'Delete custom prompt',
          action: async () => {
            toast.error('Backend endpoint missing');
          }
        });
      }
    });
  };

  const isAllPropmtsDataLoading =
    isFetchingCategories || isFetchingSubCategories || isFetchingDataPrompts;
  const isCustomPromptsDataLoading =
    isFetchingDataCustomPrompts || isFetchingSubscriptionLevel;
  const isFavoritePromptsDataLoading =
    isFetchingDataFavoritePrompts || isFetchingSubscriptionLevel;

  let isPageDataLoading: boolean = false;
  let isSortingVisible: boolean = false;

  if (isAllPromptsTab || !tab) {
    isPageDataLoading = isAllPropmtsDataLoading;
    isSortingVisible = allPrompt?.length > 0;
  } else if (isCustomPromptsTab) {
    isPageDataLoading = isCustomPromptsDataLoading;
    isSortingVisible = customPrompts?.length > 0;
  } else if (isFavoritePromptsTab) {
    isPageDataLoading = isFavoritePromptsDataLoading;
    isSortingVisible = favoritePrompts?.length > 0;
  }

  const isWholePageLoadingFirstTime =
    isLoadingCategories ||
    isLoadingDataCustomPrompts ||
    isLoadingSubCategories ||
    isLoadingDataFavoritePrompts ||
    isLoadingDataPrompts;

  const selectedPrompt = useMemo(() => {
    if (selectedPromptId) {
      if (isCustomPromptsTab) {
        return getSelectedPrompt(customPrompts, selectedPromptId);
      } else if (isFavoritePromptsTab) {
        return getSelectedPrompt(favoritePrompts, selectedPromptId);
      } else {
        return getSelectedPrompt(allPrompt, selectedPromptId);
      }
    }
  }, [
    selectedPromptId,
    allPrompt,
    customPrompts,
    favoritePrompts,
    isCustomPromptsTab,
    isFavoritePromptsTab
  ]);

  useEffect(() => {
    if (selectedPrompt) {
      setIsPromptDetailsModalVisible(true);
    }
  }, [selectedPrompt]);

  useLoading(isPageDataLoading);

  const activeCategoryItem = dataCategories?.results.find(
    (c) => c.id?.toString() === params.categories?.toString()
  ) ?? {
    name: 'All',
    white_icon: null
  };

  const filteredAllPrompt = useMemo(
    () =>
      (allPrompt ?? [])
        ?.slice()
        .sort((a: any, b: any) =>
          a.name > b.name
            ? sortDesc
              ? -1
              : 1
            : b.name > a.name
              ? sortDesc
                ? 1
                : -1
              : 0
        ),
    [allPrompt, sortDesc]
  );

  const filteredCustomPrompts = useMemo(
    () =>
      (customPrompts ?? [])
        ?.slice()
        .sort((a: any, b: any) =>
          a.name > b.name
            ? sortDesc
              ? -1
              : 1
            : b.name > a.name
              ? sortDesc
                ? 1
                : -1
              : 0
        ),
    [customPrompts, sortDesc]
  );

  const filteredFavoritePrompts = useMemo(
    () =>
      (favoritePrompts ?? [])
        ?.slice()
        .sort((a: any, b: any) =>
          a.name > b.name
            ? sortDesc
              ? -1
              : 1
            : b.name > a.name
              ? sortDesc
                ? 1
                : -1
              : 0
        ),
    [favoritePrompts, sortDesc]
  );

  return (
    <>
      <MainContentLayout
        title='Prompt Library'
        onChange={onChangeSearch}
        searchValue={
          isAllPromptsTab
            ? params?.text
            : isCustomPromptsTab
              ? customPromptsParams?.text
              : isFavoritePromptsTab
                ? favoritePromptsParams?.text
                : ''
        }
        searchBarName='prompts'
        searchBarPlaceholder='Search Prompts'
        isLoading={isAllPromptsTab ? isWholePageLoadingFirstTime : false}
        isSearchDisabled={isPageDataLoading}
      >
        <div className={styles.promptLibraryContainer}>
          {isCustomPromptCreationTemplateOpened && isCustomPromptsTab ? (
            <CreateCustomPrompt
              currEditPrompt={currEditPrompt ?? undefined}
              onSuccessCreateOrEditCustomPrompt={
                onSuccessCreateOrEditCustomPrompt
              }
            />
          ) : (
            <>
              {!isWholePageLoadingFirstTime && showDownloadExtensionLink && (
                <DownloadExtensionReminder />
              )}
              <div
                className={cn(
                  styles.promptLibrarySelectorsContainer,
                  'prompt-library-selections-container',
                  {
                    [styles.promptLibraryFlexEnd]: tab !== PromptTabs.ALL_PROMTS
                  }
                )}
              >
                {isAllPromptsTab && (
                  <Dropdown
                    horizontalPosition='left'
                    className='blob-dropdown category-selection'
                    key={isLoadingCategories ? 0 : 1}
                    items={changedCategories}
                  >
                    <>
                      {activeCategoryItem?.white_icon !== null ? (
                        <img src={activeCategoryItem?.white_icon} alt='' />
                      ) : (
                        <AllCategoryIcon />
                      )}

                      {activeCategoryItem?.name}

                      <ArrowDownIcon />
                    </>
                  </Dropdown>
                )}
                {isSortingVisible && (
                  <Dropdown
                    horizontalPosition='right'
                    className='blob-dropdown sort-selection'
                    items={[
                      {
                        kind: 'item',
                        onClick: () => setSortDesc(false),
                        active: !sortDesc,
                        text: (
                          <>
                            <span>A</span>
                            <ArrowRightAltIcon />
                            <span>Z</span>
                          </>
                        )
                      },
                      {
                        kind: 'item',
                        onClick: () => setSortDesc(true),
                        active: sortDesc,
                        text: (
                          <>
                            <span>Z</span>
                            <ArrowRightAltIcon />
                            <span>A</span>
                          </>
                        )
                      }
                    ]}
                  >
                    <>
                      <span>{sortDesc ? 'Z' : 'A'}</span>
                      <ArrowRightAltIcon />
                      <span>{sortDesc ? 'A' : 'Z'}</span>

                      <ArrowDownIcon />
                    </>
                  </Dropdown>
                )}
              </div>

              {isWholePageLoadingFirstTime && isAllPromptsTab ? (
                <div className={styles.promptLibraryTagsContainer}>
                  {subCategoriesPreview.map((_, idx) => (
                    <div key={idx} className={styles.subCategoryLoading}>
                      <Skeleton />
                    </div>
                  ))}
                </div>
              ) : isAllPromptsTab && dataSubCategories?.results?.length > 0 ? (
                <div className={styles.promptLibraryTagsContainer}>
                  {dataSubCategories.results.map((subCategory) => (
                    <Tag
                      key={subCategory?.id}
                      title={subCategory?.name}
                      onClick={() => {
                        if (subCategory?.id !== selectedSubCategory) {
                          setParams((pre) => ({ ...pre }));
                          setSelectedSubCategory(subCategory?.id);
                        } else {
                          setParams((pre) => ({ ...pre }));
                          setSelectedSubCategory(null);
                        }
                      }}
                      isActive={selectedSubCategory === subCategory?.id}
                      isLoading={isWholePageLoadingFirstTime}
                      isDisabled={isPageDataLoading}
                    />
                  ))}
                </div>
              ) : null}

              <div className={styles.promptsWrapper}>
                {isAllPromptsTab && (
                  <AllPromptsTabContent
                    onFavorite={onAddToFavorites}
                    onAddToCommand={onAddToCommandPrompt}
                    prompts={filteredAllPrompt}
                    isLoading={isWholePageLoadingFirstTime || isPageDataLoading}
                    onPromptSelect={handlePromptSelection}
                  />
                )}

                {isCustomPromptsTab && (
                  <CustomPromptsTabContent
                    customPrompts={filteredCustomPrompts}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    isLoading={isPageDataLoading}
                    onAdd={onAddNewCustomPrompt}
                    onAddToCommand={onAddToCommandCustomPrompt}
                    onPromptSelect={handlePromptSelection}
                  />
                )}

                {isFavoritePromptsTab && (
                  <FavoritePromptsTabContent
                    favoritePrompts={filteredFavoritePrompts}
                    onFavorite={onRemoveFromFavorites}
                    onAddToCommand={onAddToCommandFavoritePrompt}
                    isLoading={isPageDataLoading}
                    onPromptSelect={handlePromptSelection}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </MainContentLayout>
      {!!selectedPrompt && (
        <PromptDetailsModal
          visible={isPromptDetailsModalVisible}
          onClose={() => {
            setIsPromptDetailsModalVisible(false);

            setTimeout(() => {
              setSelectedPromptId(null);
            }, 300);
          }}
          prompt={selectedPrompt as any}
          onEdit={onEdit}
          isCustomCategory={isCustomPromptsTab}
          onPromptUpdate={(newState, update) => {
            if (!selectedPrompt) return;

            if (update === 'favorite') {
              if (isFavoritePromptsTab) {
                onRemoveFromFavorites(selectedPrompt as any)();
              } else {
                onAddToFavorites(selectedPrompt as any)();
              }
            } else if (update === 'command') {
              if (isCustomPromptsTab) {
                onAddToCommandCustomPrompt(selectedPrompt as any, 1);
              } else {
                if (isFavoritePromptsTab) {
                  onAddToCommandFavoritePrompt(selectedPrompt as any, 2);
                } else {
                  onAddToCommandPrompt(selectedPrompt as any, 2);
                }
              }
            }
          }}
          onClickSendPrompt={function (inputs, addedKnowledge): void {
            const text = ExtensionService.fillPromptTemplate(
              selectedPrompt as any,
              inputs
            );
            const alias = ExtensionService.generatePromptAlias(
              selectedPrompt as any,
              inputs
            );

            TempAppMemory.write(TempAppMemoryKey.PromptToSend, {
              text,
              alias,
              addedKnowledge
            });

            navigate('/chat');
          }}
        />
      )}
    </>
  );
};

function getSelectedPrompt<T extends IPrompt | ICustomPrompt>(
  promptsList: T[],
  promptId: number
): T | undefined {
  return promptsList?.find((prompt) => prompt.id === promptId);
}
