import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const LinkIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M13.958 11.042L14.9997 10.0004C16.3804 8.61967 16.3804 6.38109 14.9997 5.00038C13.619 3.61967 11.3804 3.61967 9.99968 5.00038L8.95801 6.04204"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.04204 8.95801L5.00038 9.99968C3.61967 11.3804 3.61967 13.619 5.00038 14.9997C6.38109 16.3804 8.61967 16.3804 10.0004 14.9997L11.042 13.958"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 8.125L8.125 11.875"
        stroke="var(--color-titanium-050)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
