import * as DOMPurify from 'dompurify';
import moment from 'moment';

import { IDocument } from '../models/IMyContent';

export function formatDate(utcDateInput: string): string {
  const now = moment();
  const inputDate = moment.utc(utcDateInput).local();
  const diffMinutes = now.diff(inputDate, 'minutes');
  const diffHours = now.diff(inputDate, 'hours');
  const diffDays = now.diff(inputDate, 'days');
  const diffYears = now.diff(inputDate, 'years');

  if (diffMinutes < 60 && diffMinutes > 0) {
    return `${diffMinutes} min ago`;
  } else if (diffMinutes === 0) {
    return 'Just now';
  } else if (diffHours < 24) {
    return diffHours === 1 ? 'An hour ago' : `${diffHours} hours ago`;
  } else if (diffDays === 1) {
    return 'Yesterday';
  } else if (diffDays < 365) {
    return inputDate.format('DD MMM');
  } else if (diffYears >= 1) {
    return inputDate.format('DD.MM.YYYY');
  } else {
    return 'Some time ago';
  }
}

export const searchDocuments = (items: IDocument[], query: string) => {
  if (!query?.trim() || !items?.length) {
    return items;
  }

  const lowerCaseQuery = query?.toLowerCase()?.trim();

  return items.filter(item => {
    const nameMatch = item.name?.toLowerCase().includes(lowerCaseQuery);
    const contentMatch = item.file?.toLowerCase().includes(lowerCaseQuery);

    return nameMatch || contentMatch;
  });
};

export const highlightAndSanitizeHtml = (text: string, query: string, isEditor?: boolean) => {
  if (!query) {
    return DOMPurify.sanitize(text, { ALLOWED_TAGS: ['span'], ALLOWED_ATTR: ['style'] });
  }

  const regex = new RegExp(`(${DOMPurify.sanitize(query)})`, 'gi');
  const highlightedText = text.replace(
    regex,
    isEditor
      ? `<mark background-color: transparent">$1</mark>`
      : '<span style="color: var(--app-accent);">$1</span>'
  );

  return DOMPurify.sanitize(highlightedText, {
    ALLOWED_TAGS: ['span', 'mark', 'p'],
    ALLOWED_ATTR: ['style'],
  });
};
