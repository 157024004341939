import { SetStateAction, useEffect, useMemo, useState } from 'react';
import './AnonymousDashboard.scss';
import { AnonymousChatScreen } from '../../external/extension/ui/screens/chat/anonymous';
import { useAsyncProcessManagerContext } from '../../external/extension/tools/async/context';
import { BlobToolsAPI } from './api';
import { IPrompt } from './api/interfaces';
import { toast } from 'react-toastify';
import { AIService } from './api/ai';
import { useParams } from 'react-router-dom';

export function AnonymousDashboard() {
  const asyncProcessManager = useAsyncProcessManagerContext();

  const { slug } = useParams();

  const aiService = useMemo(() => new AIService(), []);

  const [isPromptModalVisible, setIsPromptModalVisible] =
    useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isLimitReached, setIsLimitReached] = useState<boolean>(false);
  const [modalPrompt, setModalPrompt] = useState<IPrompt | null>(null);
  const [generatedResponse, setGeneratedResponse] = useState<string | null>(
    null
  );

  const redirectToHome = () => {
    document.location.href = 'https://alchemy-app.com/';
  };

  useEffect(() => {
    asyncProcessManager?.doProcess({
      name: 'Get main data',
      action: async () => {
        const newData = await BlobToolsAPI.getMainData();

        if (newData) {
          const allPrompts = newData.results.map((r) => r.prompts).flat();

          const prompt = allPrompts?.find((p) => p.slug === slug) ?? null;

          if (prompt) {
            setModalPrompt(prompt);
            setIsPromptModalVisible(true);
          } else {
            redirectToHome();
          }
        }
      }
    });
  }, []);

  const handleSend = (textAreaValues: { [key: string]: string }) => {
    if (!modalPrompt) {
      toast.error('No active prompt');
      return;
    }
    asyncProcessManager?.doProcess({
      name: 'Start generation',
      action: async () => {
        setIsLimitReached(false);
        setIsPromptModalVisible(false);
        setIsGenerating(true);
        setGeneratedResponse('');

        await aiService.generate({
          request: {
            prompt: modalPrompt?.id,
            input_fields: textAreaValues
          },
          onGenerationChunkReceived: (chunk) => {
            setGeneratedResponse((prev) => prev + chunk.diff);
          },
          onGenerationEnded: () => {
            setIsGenerating(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.querySelector('.anonymous-dashboard .message-content .text');

      if(element) {
        setIsLimitReached(element.scrollHeight > element.clientHeight + 50);
      }
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='anonymous-dashboard'>
      <div className='blob-sidebar-wrapper'>
        <AnonymousChatScreen
          renderingMessage={
            generatedResponse
              ? {
                  id: 0,
                  message: generatedResponse,
                  model_message: true,
                  created_at: new Date().toISOString(),
                  attachments: []
                }
              : null
          }
          isGenerating={isGenerating && !isLimitReached}
          setIsGenerating={function (value: SetStateAction<boolean>): void {
            throw new Error('Function not implemented.');
          }}
          onCancel={function (): void {
            redirectToHome();
          }}
          onSend={function (_, __, inputs): void {
            handleSend(inputs);
          }}
          modalPrompt={
            modalPrompt
              ? {
                  ...modalPrompt,
                  steps: [],
                  categories: [],
                  is_favourite: false,
                  is_liked: false,
                  is_in_command: false,
                  created_at: new Date().toUTCString(),
                  amount_of_lookups: 0,
                  click_amount: 0,
                  plugins: [],
                  currentChainStep: 0,
                  like_amount: 0,
                  inputs: (modalPrompt?.inputs ?? []) as any,
                  creator: {
                    pk: -1,
                    avatar: null,
                    username: 'N/A',
                    first_name: '',
                    last_name: ''
                  }
                }
              : null
          }
          isPromptModalVisible={isPromptModalVisible}
          isLimitReached={isLimitReached}
        />
      </div>
    </div>
  );
}
