import cn from 'classnames';
import { FieldAttributes, useField } from 'formik';
import { FC } from 'react';

import { RadioIcon } from '../../../../../assets/svg/RadioIcon';
import { RadioIconChecked } from '../../../../../assets/svg/RadioIconChecked';
import * as styles from './RadioButton.module.scss';

export const RadioButton: FC<FieldAttributes<any>> = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'radio' });
  const isShowError = meta.touched && meta.error;

  return (
    <div className={styles.radioButtonWrapper}>
      <label
        className={cn(
          styles.radioButtonLabel,
          { [styles.radioButtonLabelChecked]: field.checked },
          { [styles.radioButtonLabelError]: isShowError }
        )}
      >
        <input type="radio" {...field} {...props} />
        {field.checked ? <RadioIconChecked /> : <RadioIcon />}
        <span>{label}</span>
      </label>
      {isShowError ? <div className={styles.radioButtonErrorText}>{meta.error}</div> : null}
    </div>
  );
};
