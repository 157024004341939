// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SnippetContent-module__snippetForm___qidpq{display:flex;flex-direction:column;gap:24px;width:100%;max-width:412px;padding-top:12px}.SnippetContent-module__snippetFormBtnsWrapper___LUF1s{display:flex;justify-content:flex-end;align-items:center;gap:8px}.SnippetContent-module__snippetFormBtnsWrapper___LUF1s button{font-size:16px;font-weight:500;line-height:20px}`, "",{"version":3,"sources":["webpack://./src/pages/SnippetsPage/SnippetContent/SnippetContent.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CACA,gBAAA,CAGF,uDACE,YAAA,CACA,wBAAA,CACA,kBAAA,CACA,OAAA,CAEA,8DACE,cAAA,CACA,eAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
export var snippetForm = `SnippetContent-module__snippetForm___qidpq`;
export var snippetFormBtnsWrapper = `SnippetContent-module__snippetFormBtnsWrapper___LUF1s`;
export default ___CSS_LOADER_EXPORT___;
