import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import { IAuthResponseWithStatus } from '../../models/IAuth';
import { useGoogleLoginMutation } from '../../services/auth';
import { errorMessages } from '../../services/errors';
import { PAGES_ENUM } from '../../utils/constants';
import { handleError, handleSuccess } from '../../utils/notifications';

export const useGoogleSignIn = () => {
  const [googleLog] = useGoogleLoginMutation();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const data = (await googleLog(
          codeResponse
        ).unwrap()) as IAuthResponseWithStatus;

        if (data?.status >= 200 && data?.status < 400) {
          const userData = data.user as any;
          const token = data.auth_token;

          if (userData && token) {
            localStorage.setItem('is_google_auth', 'true');
            localStorage.setItem('auth_token', token);
            localStorage.setItem('ref_code', userData.referral_code);
            localStorage.setItem(
              'show_download_extension',
              String(userData.show_again)
            );
            localStorage.setItem(
              'onboarding_passed',
              String(userData.onboarding_passed)
            );

            if (userData.onboarding_passed) {
              navigate(PAGES_ENUM.HOME, { replace: true });
              handleSuccess('Successfully logged in');
            } else if (!userData.onboarding_passed) {
              navigate(PAGES_ENUM.ONBOARDING_FIRST_STEP);
            }
          }
        }
      } catch (error: any) {
        if (error?.data?.status === 'GOOGLE_ACCOUNT_IS_NOT_REGISTERED') {
          handleError(errorMessages.GOOGLE_ACCOUNT_IS_NOT_REGISTERED);
        } else if (error?.data?.status === 'already exists email') {
          handleError(errorMessages.USER_ALREADY_EXISTS);
        } else {
          handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
          console.error('Login failed:', error);
        }
      }
    },
    onError: () => {
      console.error('Login Failed');
    }
  });

  return { googleLogin };
};
