// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag-module__tag___Tuwop{display:flex;justify-content:center;align-items:center;font-size:12px;font-weight:var(--font-medium);text-align:center;color:var(--arsenic-400);padding:6px 10px;border-radius:999px;background:var(--app-light-1a);transition:all .3s ease}.Tag-module__tag___Tuwop:hover{color:var(--titanium-300);background:var(--app-light-2a)}.Tag-module__activeTag___ErQQ8{background:var(--app-accent);color:var(--app-text-on-accent) !important;border:none}.Tag-module__activeTag___ErQQ8:hover{background:var(--app-accent);color:var(--titanium-50)}.Tag-module__disabled___eplJc{background:linear-gradient(120deg, rgba(255, 255, 255, 0.04) 13.15%, rgba(255, 255, 255, 0) 75.74%);color:var(--titanium-700);border-color:hsla(0,0%,100%,.08)}.Tag-module__tagPreview___nROgi{color:rgba(0,0,0,0);background:var(--preview-background-gradient);box-shadow:none;border:none;height:28px}`, "",{"version":3,"sources":["webpack://./src/pages/PromptLibraryPage/components/Tag/Tag.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,8BAAA,CACA,iBAAA,CACA,wBAAA,CACA,gBAAA,CACA,mBAAA,CACA,8BAAA,CACA,uBAAA,CAEA,+BACE,yBAAA,CACA,8BAAA,CAIJ,+BACE,4BAAA,CACA,0CAAA,CACA,WAAA,CAEA,qCACE,4BAAA,CACA,wBAAA,CAIJ,8BACE,mGAAA,CAKA,yBAAA,CACA,gCAAA,CAGF,gCACE,mBAAA,CACA,6CAAA,CACA,eAAA,CACA,WAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
export var tag = `Tag-module__tag___Tuwop`;
export var activeTag = `Tag-module__activeTag___ErQQ8`;
export var disabled = `Tag-module__disabled___eplJc`;
export var tagPreview = `Tag-module__tagPreview___nROgi`;
export default ___CSS_LOADER_EXPORT___;
