export const HelpIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0416 9.99992C16.0416 13.3366 13.3366 16.0416 9.99992 16.0416C6.6632 16.0416 3.95825 13.3366 3.95825 9.99992C3.95825 6.6632 6.6632 3.95825 9.99992 3.95825C13.3366 3.95825 16.0416 6.6632 16.0416 9.99992Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 8.33325C8.125 8.33325 8.33333 6.45825 10 6.45825C11.6667 6.45825 11.875 7.49992 11.875 8.33325C11.875 8.95938 11.5222 9.5855 10.8165 9.85816C10.3872 10.024 10 10.373 10 10.8333V11.0416"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4166 13.3334C10.4166 13.5635 10.23 13.7501 9.99992 13.7501C9.7698 13.7501 9.58325 13.5635 9.58325 13.3334C9.58325 13.1033 9.7698 12.9167 9.99992 12.9167C10.23 12.9167 10.4166 13.1033 10.4166 13.3334Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
