import cn from 'classnames';
import { FieldAttributes, useField } from 'formik';

import * as styles from './AccountFormInput.module.scss';

export const AccountFormInput: React.FC<FieldAttributes<any>> = ({
  className,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isShowError = meta.touched && meta.error;

  return (
    <label className={cn(styles.accountFormLabel, className)} htmlFor={props.id}>
      {label}
      <input
        className={cn(styles.accountFormInput, { [styles.accountFormInputError]: isShowError })}
        {...field}
        {...props}
        maxLength={100}
      />
      {isShowError ? <div className={styles.accountFormError}>{meta.error}</div> : null}
    </label>
  );
};
