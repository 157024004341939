import errToJSON from 'error-to-json';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DeviceRecommendation } from './components/DeviceRecommendation';
import { BlobTheme } from './external/extension/interfaces';
import { ExtensionService } from './external/extension/service';
import { ExtensionServiceContext } from './external/extension/service/context';
import {
  ExtensionEvents,
  useExtensionEventListener
} from './external/extension/service/events';
import { ExtensionEventType } from './external/extension/service/events/types';
import { useAsyncProcessManager } from './external/extension/tools/async';
import { AsyncProcessManagerContext } from './external/extension/tools/async/context';
import { confirm } from './external/extension/ui/design/components/modal/confirmation';
import { useStripeRedirectToStripePage } from './hooks/useStripeRedirectToStripePage';
import { Routes } from './routes';
import { useSetSettingsMutation } from './services/settings';
import { PAGES_ENUM } from './utils/constants';

// This routes are adapted for mobile, tablet and desktop versions
const MOBILE_ROUTES = [
  PAGES_ENUM.SIGN_IN,
  PAGES_ENUM.SIGN_UP,
  PAGES_ENUM.ONBOARDING_FIRST_STEP,
  PAGES_ENUM.ONBOARDING_SECOND_STEP,
  PAGES_ENUM.ONBOARDING_PAYMENT,
  PAGES_ENUM.TERMS_OF_SERVICE,
  PAGES_ENUM.FORGOT_PASSWORD,
  PAGES_ENUM.RESET_PASSWORD,
  PAGES_ENUM.CODE_CONFIRMATION
];

const App = () => {
  const location = useLocation();
  const [setSettings] = useSetSettingsMutation();
  const authToken =
    localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');

  const [theme, setTheme] = useState<BlobTheme>(
    localStorage.getItem('app-theme') === 'light'
      ? BlobTheme.Light
      : BlobTheme.Dark
  );

  useExtensionEventListener(
    ExtensionEventType.TurnOnLightTheme,
    () => {
      setTheme(BlobTheme.Light);
    },
    []
  );

  useExtensionEventListener(
    ExtensionEventType.TurnOffLightTheme,
    () => {
      setTheme(BlobTheme.Dark);
    },
    []
  );

  useEffect(() => {
    localStorage.setItem(
      'app-theme',
      theme === BlobTheme.Light ? 'light' : 'dark'
    );

    if (theme === BlobTheme.Light) {
      document.body.classList.remove('light-theme');
      document.body.classList.add('light-theme');
    } else {
      document.body.classList.remove('light-theme');
    }
  }, [theme]);

  // This will be deleted after mobile and desktop design will be implemented for all pages
  const isMobileAdaptedRoute = useMemo(
    () =>
      MOBILE_ROUTES.some((route) => location.pathname === route) ||
      location.pathname.includes('/prompt/shared/'),
    [location.pathname]
  );

  const isDev = process.env.NODE_ENV === 'development';

  const { onStripeRedirectToStripePage } = useStripeRedirectToStripePage();

  const redirectFromExtensionByPriceToPayPage = async () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      localStorage.setItem('auth_token', token);
      localStorage.setItem('onboarding_passed', 'true');
      sessionStorage.setItem('onboarding_passed', 'true');
      setSettings({ onboarding_passed: true });
      onStripeRedirectToStripePage();
    }
  };

  useEffect(() => {
    if (location.pathname.includes('extension-pay-page')) {
      redirectFromExtensionByPriceToPayPage();
    }
  }, []);

  const navigate = useNavigate();

  const asyncProcessManager = useAsyncProcessManager({
    onFirstActiveStart: () => {
      // setIsLoading(true);
    },
    onAllFinished: () => {
      // setIsLoading(false);
    },
    onError: (e, _, message, process) => {
      if (e.message === 'Network Error') {
        document.dispatchEvent(new CustomEvent('alchemyConnectionLost'));
      } else {
        ExtensionService.reportError({
          user: 'unknown',
          error_section: 'sidebar-async-process',
          error_json: {
            message,
            process,
            detail: errToJSON(e)
          },
          status_code: 500
        });

        if (message === 'You reached your subscription limit') {
          setTimeout(() => {
            if (
              window.location.pathname.startsWith('/chat') ||
              window.location.pathname.startsWith('/synthesize')
            ) {
              navigate('/chat');
            }
          }, 100);
          confirm({
            title: 'You reached your subscription limit',
            text: 'Expand your capabilities with Alchemy Pro subscription',
            cancelButtonText: 'Close',
            confirmButtonText: 'Upgrade',
            primary: true,
            theme: 'blob',
            handleConfirm: async () => {
              ExtensionEvents.dispatch(
                ExtensionEventType.ShowUpgradePriceWindow
              );
            }
          });
        } else {
          console.error(`🤖 API error '${message}' on ${process.name}`);
        }
      }
    }
  });

  const extensionService = useMemo(
    () => new ExtensionService({ token: authToken ?? '', tier: null }),
    [authToken]
  );

  if (location.pathname.includes('extension-pay-page')) return null;

  return (
    <>
      <div
        className={`${isMobileAdaptedRoute || isDev ? '' : 'app-content-hidden'}`}
      >
        <AsyncProcessManagerContext.Provider value={asyncProcessManager}>
          <ExtensionServiceContext.Provider value={extensionService}>
            <Routes />
          </ExtensionServiceContext.Provider>
        </AsyncProcessManagerContext.Provider>
      </div>
      {isMobileAdaptedRoute || isDev ? null : <DeviceRecommendation />}
    </>
  );
};

export default App;
