import classNames from 'classnames';
import { Outlet } from 'react-router-dom';

import { Sidebar } from '../../Sidebar';
import { MaxWidthContainer } from '../MaxWidthContainer';
import * as styles from '../DashboardLayout/DashboardLayout.module.scss';
import { AnonymousSidebar } from '../../Sidebar/AnonymousSidebar';

export const AnonymousDashboardLayout = () => {
  return (
    <>
      <MaxWidthContainer>
        <div
          className={classNames(
            styles.dashboardLayoutContent,
            'dashboard-layout'
          )}
        >
          <AnonymousSidebar />
          <div
            className={classNames(
              styles.dashboardLayoutMainContent,
              'dashboard-layout-main'
            )}
          >
            <Outlet />
          </div>
        </div>
      </MaxWidthContainer>
    </>
  );
};
