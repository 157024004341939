import cn from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FC } from 'react';

import { errorMessages } from '../../../../services/errors';
import { useCreateDocumentMutation } from '../../../../services/myContent';
import Emitter, { EMITTER_EVENTS } from '../../../../utils/eventEmitter';
import { handleError } from '../../../../utils/notifications';
import { Button } from '../../../Button';
import * as styles from './CreateDocumentModal.module.scss';
import { validationSchema } from './validationService';

interface IProps {
  onClose: () => void;
  emmitType: EMITTER_EVENTS.MODAL_CREATE_DOCUMENT;
}

interface IFormValues {
  title: string;
}

export const CreateDocumentModal: FC<IProps> = ({ onClose, emmitType }) => {
  const [createDocument] = useCreateDocumentMutation();

  const handleCreateDocument = async (values: IFormValues) => {
    try {
      await createDocument({
        name: values.title,
        file: ' '
      }).unwrap();
      Emitter.emit(emmitType, {});
      onClose();
    } catch (error: any) {
      if (error?.data?.detail) {
        handleError(error.data.detail);
      } else {
        handleError(errorMessages.DOCUMENT_NOT_CREATED);
      }
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={{ title: '' }}
      onSubmit={handleCreateDocument}
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <h1 className={styles.title}>Create document</h1>
            </div>
            <div className={styles.content}>
              <label htmlFor='title' className={styles.inpTitle}>
                Document name
              </label>
              <Field
                name='title'
                type='text'
                id='title'
                placeholder='Untitled document'
                className={cn(styles.inpName, {
                  [styles.inpError]: errors['title'] && touched['title']
                })}
                autoComplete='off'
              />
              <ErrorMessage
                component='div'
                name='title'
                className={styles.inputErrorMessage}
              />
            </div>
            <div className={styles.action}>
              <Button>Create document</Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
