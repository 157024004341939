import cn from 'classnames';
import detectUrlChange from 'detect-url-change';
import {
  FC,
  ReactElement,
  SVGProps,
  useEffect,
  useMemo,
  useState
} from 'react';
import { NavLink } from 'react-router-dom';

import ArrowDownIcon from '../../../../external/extension/ui/design/assets/svg/icons/ArrowDownIcon';
import PlusIcon from '../../../../external/extension/ui/design/assets/svg/icons/PlusIcon';
import { Button } from '../../../../external/extension/ui/design/components/button';
import * as styles from './NavList.module.scss';

export interface INavItem {
  id: number;
  icon?: ReactElement<FC<SVGProps<SVGSVGElement>>>;
  title: string;
  link?: string;
  externalLink?: string;
}

interface IItem {
  icon?: ReactElement<FC<SVGProps<SVGSVGElement>>>;
  title: string;
  link?: string;
  externalLink?: string;
  subItems?: IItem[];
  hasNewButton?: boolean;
  onClickOnNewButton?: () => void;
}

type Props = {
  items?: IItem[];
  isUpgradeNeeded?: boolean;
  isLoading: boolean;
  isSubscribtionFetching?: boolean;
  isSidebarCollapsed?: boolean;
  className?: string;
};

export const NavList: FC<Props> = ({
  items,
  className,
  isLoading,
  isSidebarCollapsed
}) => {
  const [expandedItem, setExpandedItem] = useState<number | null>(null);

  const [currentURL, setCurrentURL] = useState<string>(window.location.href);

  const location = useMemo(() => {
    return window.location;
  }, [currentURL]);

  useEffect(() => {
    const updateURL = () => setCurrentURL(window.location.href);

    detectUrlChange.on('change', updateURL);

    return () => {
      detectUrlChange.off('change', updateURL);
    };
  }, []);

  const getNavLinkClassName = (
    path: string,
    withSubItemsAndExpanded?: boolean
  ) => {
    const pathWithSearchParams = location.pathname + location.search;

    return cn(styles.navLink, 'sidebar-nav-link', {
      [styles.navActiveLink]:
        pathWithSearchParams === path ||
        ((path === '/chat' || path === '/synthesize') &&
          (isSidebarCollapsed
            ? pathWithSearchParams.startsWith(path)
            : pathWithSearchParams === path)) ||
        (path === '/prompts' &&
          (location.search.includes('all') ||
            location.search.includes('favorite') ||
            location.search.includes('custom')) &&
          !(withSubItemsAndExpanded && !isSidebarCollapsed)),
      [styles.navLinkInCollapsedSidebar]: isSidebarCollapsed
    });
  };

  const toggleExpand = (index: number, event: React.MouseEvent) => {
    event.preventDefault();
    setExpandedItem(expandedItem === index ? null : index);
  };

  useEffect(() => {
    if ((location.pathname + location.search).startsWith('/prompts?tab=')) {
      setExpandedItem(items?.findIndex((item) => !!item.subItems) ?? null);
    }
  }, [location]);

  return (
    <div className={cn(styles.navList, className)}>
      {items?.map((item, index) => (
        <div key={index} className={styles.navSection}>
          <nav className={styles.navLinks}>
            <NavLink
              to={item.link ?? ''}
              className={getNavLinkClassName(
                item.link || '',
                !!item.subItems && expandedItem === index
              )}
              onClick={(event) =>
                item.subItems && !isSidebarCollapsed
                  ? toggleExpand(index, event)
                  : undefined
              }
              style={
                isLoading
                  ? { pointerEvents: 'none' }
                  : { pointerEvents: 'auto' }
              }
            >
              {item.icon}
              {!isSidebarCollapsed && item.title}
              {item.hasNewButton && !isSidebarCollapsed && (
                <Button size='mini'>
                  <PlusIcon />
                </Button>
              )}
              {item.subItems && !isSidebarCollapsed && (
                <ArrowDownIcon
                  className={cn(styles.arrowIcon, {
                    [styles.expanded]: expandedItem === index
                  })}
                />
              )}
            </NavLink>
            <div
              className={cn(styles.subItems, {
                [styles.expanded]: expandedItem === index && !isSidebarCollapsed
              })}
            >
              {item.subItems?.map((link, subIndex) => (
                <NavLink
                  key={subIndex}
                  to={link.link ?? ''}
                  className={getNavLinkClassName(link.link ?? '')}
                  style={
                    isLoading
                      ? { pointerEvents: 'none' }
                      : { pointerEvents: 'auto' }
                  }
                >
                  {link.icon}
                  {!isSidebarCollapsed && link.title}
                </NavLink>
              ))}
            </div>
          </nav>
        </div>
      ))}
    </div>
  );
};
