import cn from 'classnames';
import { FC } from 'react';

import * as styles from './MaxWidthContainer.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const MaxWidthContainer: FC<Props> = ({ children, className }) => {
  return <div className={cn(styles.container, className)}>{children}</div>;
};
