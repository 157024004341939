export interface IImageIcon {
  color?: string;
  size?: number;
}
export default function ImageIcon(props: IImageIcon) {
  return (
    <svg
      width={props.size ?? 22}
      height={props.size ?? 22}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.16602 11.001L4.99681 8.67211C5.51595 8.01171 6.50904 7.99011 7.05642 8.62731L8.66602 10.501M7.27602 8.88298C7.96748 8.00344 8.93088 6.75734 8.99362 6.67627C8.99582 6.67337 8.99795 6.67063 9.00022 6.66776C9.52035 6.01163 10.5102 5.99152 11.0564 6.62729L12.666 8.50098M4.49935 13.1676H11.4993C12.2357 13.1676 12.8327 12.5707 12.8327 11.8343V4.83431C12.8327 4.09793 12.2357 3.50098 11.4993 3.50098H4.49935C3.76297 3.50098 3.16602 4.09793 3.16602 4.83431V11.8343C3.16602 12.5707 3.76297 13.1676 4.49935 13.1676Z"
        stroke={props.color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
