import './KnowledgeModal.scss';

import { ArticleData } from '@extractus/article-extractor';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { useExtensionServiceContext } from '../../../external/extension/service/context';
import {
  IKnowledge,
  IKnowledgeBase,
  IKnowledgeCategory
} from '../../../external/extension/service/interfaces';
import { useAsyncProcessManagerContext } from '../../../external/extension/tools/async/context';
import { StringTools } from '../../../external/extension/tools/string';
import ArrowDownIcon from '../../../external/extension/ui/design/assets/svg/icons/ArrowDownIcon';
import { HelpIcon } from '../../../external/extension/ui/design/assets/svg/icons/HelpIcon';
import { Button } from '../../../external/extension/ui/design/components/button';
import {
  Dropdown,
  IDropdownItem
} from '../../../external/extension/ui/design/components/dropdown';
import { LoadingOverlay } from '../../../external/extension/ui/design/components/loading';
import { Modal } from '../../../external/extension/ui/design/components/modal';
import {
  KnowledgeType,
  KnowledgeTypeSwitch
} from './components/KnowledgeTypeSwitch';
import mockData from './data/categories.json';

export interface IKnowledgeModal {
  visible: boolean;
  item: IKnowledge | null;
  onCancel: () => void;
  onSubmit: (item: IKnowledgeBase, isSource: boolean) => Promise<void>;
}
export function KnowledgeModal(props: IKnowledgeModal) {
  const asyncProcessManager = useAsyncProcessManagerContext();
  const extensionService = useExtensionServiceContext();

  const [activeType, setActiveType] = useState<KnowledgeType>(
    KnowledgeType.Knowledge
  );
  const [activeCategory, setActiveCategory] = useState<number | null>(
    props.item?.category ?? null
  );

  const [text, setText] = useState<string>('');
  const [url, setURL] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const categories = mockData.categories as IKnowledgeCategory[];

  const dropdownSelectedCategory = useMemo(() => {
    return activeCategory
      ? categories.find((category) => category.id === activeCategory)
      : categories?.[0];
  }, [categories, activeCategory]);

  const dropdownItems = useMemo(
    () =>
      categories.map((category) => ({
        kind: 'item',
        icon: <span className='emoji'>{category.emoji}</span>,
        text: category.title,
        active: dropdownSelectedCategory?.id === category.id,
        onClick: () => setActiveCategory(category.id)
      })) as IDropdownItem[],
    [categories, dropdownSelectedCategory]
  );

  const validateForm = () => {
    if (activeType === KnowledgeType.Knowledge) {
      if (text.trim().length === 0) {
        toast.error('Text field must be not empty');

        return false;
      }
    } else if (activeType === KnowledgeType.SourceMaterial) {
      if (url.trim().length === 0) {
        toast.error('URL field must be not empty');

        return false;
      } else if (!StringTools.isValidURL(url)) {
        toast.error('URL in field is invalid');

        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoadingMessage(null);
    setIsLoading(true);

    if (activeType === KnowledgeType.Knowledge) {
      await props.onSubmit(
        {
          title:
            dropdownSelectedCategory?.title
              ?.toLowerCase()
              ?.replaceAll(' ', '') ?? '',
          text,
          category: dropdownSelectedCategory?.id
        },
        false
      );
    } else if (activeType === KnowledgeType.SourceMaterial) {
      const fullURL = StringTools.ensureHttps(url);
      const urlObject = new URL(fullURL);

      let websiteInfo: ArticleData | null = null;

      setLoadingMessage('Browsing ' + urlObject.hostname);
      await asyncProcessManager?.doProcess({
        name: 'Parsing website data',
        action: async () => {
          websiteInfo = await extensionService.getWebsiteInfo(fullURL);
        }
      });

      let preparedText = 'Failed to parse';

      if (websiteInfo) {
        let generationFailed = false;
        let timeout1: any = null;
        let timeout2: any = null;
        let timeout3: any = null;

        setLoadingMessage('Waiting for the start of AI generation');
        await asyncProcessManager?.doProcess({
          name: 'Generate prompt for website',
          onError: () => {
            generationFailed = true;
          },
          action: async () => {
            timeout1 = setTimeout(() => {
              setLoadingMessage(
                'Please wait, the process will complete shortly'
              );
            }, 10000);

            timeout2 = setTimeout(() => {
              setLoadingMessage('Waiting time is exceeding the usual');
            }, 15000);

            timeout3 = setTimeout(() => {
              setLoadingMessage('This might take a bit longer than usual');
            }, 20000);

            preparedText = websiteInfo
              ? ((await extensionService?.ai?.generateSummaryForWebsiteData(
                  websiteInfo,
                  () => {
                    clearTimeout(timeout1);
                    clearTimeout(timeout2);
                    clearTimeout(timeout3);

                    setLoadingMessage('✨ Processing data using AI');

                    timeout1 = setTimeout(() => {
                      setLoadingMessage(
                        'Please wait, the process will complete shortly'
                      );
                    }, 10000);

                    timeout2 = setTimeout(() => {
                      setLoadingMessage(
                        'Generation time is exceeding the usual'
                      );
                    }, 15000);

                    timeout3 = setTimeout(() => {
                      setLoadingMessage(
                        'This might take a bit longer than usual'
                      );
                    }, 20000);
                  }
                )) ?? '')
              : '';
          }
        });

        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);

        if (!generationFailed) {
          await props.onSubmit(
            {
              title: urlObject.hostname.split('.').slice(0, -1).join(''),
              text: preparedText,
              category: null,
              url: fullURL
            },
            true
          );
        } else {
          toast.error('Generation failed');
        }
      } else {
        toast.error('Parsing is not allowed on this website');
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setActiveType(
      props.item?.url ? KnowledgeType.SourceMaterial : KnowledgeType.Knowledge
    );
    setActiveCategory(props.item?.category ?? null);
    setText(props.item?.text ?? '');
    setURL(props.item?.url ?? '');
  }, [props.item, props.visible]);

  useEffect(() => {
    setActiveCategory(props.item?.category ?? null);
    setText(props.item?.text ?? '');
    setURL(props.item?.url ?? '');
  }, [activeType]);

  return (
    <Modal
      className='knowledge-modal'
      title={
        <>
          <span>{props.item ? 'Edit' : 'New'} Knowledge</span>
          <HelpIcon />
        </>
      }
      onClose={props.onCancel}
      visible={props.visible}
    >
      <KnowledgeTypeSwitch
        type={activeType}
        onChange={(type) => setActiveType(type)}
      />

      <div className='form-content'>
        {activeType === KnowledgeType.Knowledge ? (
          <>
            <div className='form-group'>
              <p>Select a knowledge</p>
              <Dropdown verticalPosition='bottom' items={dropdownItems}>
                <span className='emoji'>{dropdownSelectedCategory?.emoji}</span>
                <span className='title'>
                  {dropdownSelectedCategory?.title ?? ''}
                </span>
                <ArrowDownIcon />
              </Dropdown>
            </div>

            <div className='form-group'>
              <p>Add your text</p>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder='Write or paste your text here...'
              />
            </div>
          </>
        ) : (
          <>
            <div className='form-group'>
              <p>Add a URL</p>
              <input
                type='text'
                value={url}
                onChange={(e) => setURL(e.target.value)}
                placeholder='Add a link to your source'
              />
            </div>
          </>
        )}
      </div>

      <div className='footer-section'>
        <Button text='Cancel' onClick={props.onCancel} size='mini' />
        <Button
          onClick={handleSubmit}
          text={props.item ? 'Save' : 'Create'}
          active={true}
          size='mini'
        />
      </div>

      <LoadingOverlay
        loadingMessage={loadingMessage ?? ''}
        active={isLoading}
      />
    </Modal>
  );
}
