import { useNavigate } from 'react-router';

import lights from '../../assets/images/lights.png';
import { MainLogo } from '../../assets/svg/MainLogo';
import { Button } from '../../components/Button';
import { useTypedDispatch } from '../../hooks/useTypedDispatch';
import { setRedirectTo404 } from '../../redux/Auth';
import * as styles from './NotFoundPage.module.scss';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  dispatch(setRedirectTo404(false));

  return (
    <div className={styles.notFoundPage}>
      <div className={styles.notFoundPageHeader}>
        <MainLogo />
      </div>
      <div className={styles.notFoundPageContent}>
        <img src={lights} alt="gradient" width="618" height="430" />
        <div className={styles.notFoundPageInfo}>
          <h1 className={styles.notFoundPageTitle}>Oops! You stumbled upon a rarity</h1>
          <p className={styles.notFoundPageDescription}>
            This page isn`t available. Sorry about that.Let`s find a better place for you to go.
          </p>
          <Button onClick={() => navigate('/', { replace: true })}>Go to homepage</Button>
        </div>
      </div>
    </div>
  );
};
