import './index.scss';

import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState
} from 'react';

import { useAsyncProcessManagerContext } from '../../../tools/async/context';
import { LoadingOverlay } from '../../design/components/loading';
import { ILoginFormState, LoginBlock } from './blocks/login';
import {
  IResetPasswordFormState,
  ResetPasswordBlock
} from './blocks/resetPassword';
import { ISignUpFormState, SignUpBlock } from './blocks/signup';
import { IValidationFieldErrors } from './interfaces';

interface IProps {
  size?: 'mini' | 'default';
  isBlob?: boolean;
  isDashboard?: boolean;
  defaultBlock?: AuthSubBlock;
  onGoogle: (newAccount: boolean) => void;
  onLogin: (data: any) => Promise<any>;
  onSignUp: (data: any) => Promise<any>;
  sendResetPasswordCode: (
    data: any,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >,
    setIsLocalLoading: Dispatch<SetStateAction<boolean>>
  ) => Promise<any>;
  checkResetPasswordCode: (
    data: any,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >,
    setIsLocalLoading: Dispatch<SetStateAction<boolean>>
  ) => Promise<any>;
  resetPassword: (
    data: any,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >,
    setIsLocalLoading: Dispatch<SetStateAction<boolean>>
  ) => Promise<any>;
  onClose?: () => void;
  onChangeBlock?: (block: AuthSubBlock) => void;
}

export enum AuthSubBlock {
  Login,
  SignUp,
  ResetPassword
}

export const AuthBlock: React.FC<IProps> = ({
  onClose,
  size = 'default',
  ...props
}: IProps) => {
  const asyncProcessManager = useAsyncProcessManagerContext();

  function handleGoogleButtonClick(newAccount: boolean) {
    props.onGoogle(newAccount);
  }

  const [activeBlock, setActiveBlock] = useState<AuthSubBlock>(
    props.defaultBlock ?? AuthSubBlock.SignUp
  );

  useEffect(() => {
    props.onChangeBlock?.(activeBlock);
  }, [activeBlock]);

  useEffect(() => {
    setActiveBlock((prev) => props.defaultBlock ?? prev);
  }, [props.defaultBlock]);

  const [isLoading] = useState<boolean>(false);
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);

  function onLogin(
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: ILoginFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    hasErrors: boolean
  ) {
    e.preventDefault();

    if (!formRef.current) return;
    formRef.current.classList.add('submitted');

    if (hasErrors) return;
    const form = formRef.current;

    asyncProcessManager?.doProcess({
      name: 'Login',
      onError: () => {
        setIsLocalLoading(false);
      },
      action: async () => {
        setIsLocalLoading(true);

        const body = { ...formState };

        try {
          await props.onLogin?.(body);
        } catch (error) {
          console.error(error);
          setIsLocalLoading(false);

          setErrors({
            email: 'Invalid email',
            password: 'Invalid password'
          });

          const emailInput = form.elements.namedItem(
            'email'
          ) as HTMLInputElement;

          const passwordInput = form.elements.namedItem(
            'password'
          ) as HTMLInputElement;

          emailInput.setCustomValidity('Invalid email');
          passwordInput.setCustomValidity('Invalid password');
        }

        setIsLocalLoading(false);
      }
    });
  }

  function onSignUp(
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: ISignUpFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    hasErrors: boolean
  ) {
    e.preventDefault();
    if (!formRef.current) return;
    formRef.current.classList.add('submitted');

    if (hasErrors) return;

    const form = formRef.current;

    asyncProcessManager?.doProcess({
      name: 'Register',
      onError: () => {
        setIsLocalLoading(false);
      },
      action: async () => {
        setIsLocalLoading(true);

        const body = {
          email: formState.email.trim(),
          password: formState.password.trim(),
          username: formState.username.trim(),
          collect_data: formState.collectData
        };

        try {
          const result = await props.onSignUp(body);

          if (result?.auth_token) {
            onClose?.();
          }
        } catch (error: any) {
          setIsLocalLoading(false);

          if (error.response?.data?.info === 'USER_EMAIL_ALREADY_EXISTS') {
            setErrors((prevErrors) => ({
              ...prevErrors,
              email: 'User already exists'
            }));

            const emailInput = form.elements.namedItem(
              'email'
            ) as HTMLInputElement;

            emailInput.setCustomValidity('User already exists');
          } else if (
            error.response?.data?.info === 'USER_USERNAME_ALREADY_EXISTS'
          ) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              username: 'Username already exists'
            }));

            const usernameInput = form.elements.namedItem(
              'username'
            ) as HTMLInputElement;

            usernameInput.setCustomValidity('User already exists');
          }
        }

        setIsLocalLoading(false);
      }
    });
  }

  function onSubmitEmail(
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >
  ) {
    e.preventDefault();

    if (!formRef.current) return;
    formRef.current.classList.add('submitted');

    const body = { email: formState.email };

    props.sendResetPasswordCode(
      body,
      formRef,
      formState,
      setErrors,
      setActiveStep,
      setIsLocalLoading
    );
  }

  function onSubmitCode(
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >
  ) {
    e.preventDefault();

    if (!formRef.current) return;
    formRef.current.classList.add('submitted');

    const body = { email: formState.email, code: formState.code };

    props.checkResetPasswordCode(
      body,
      formRef,
      formState,
      setErrors,
      setActiveStep,
      setIsLocalLoading
    );
  }

  function onSubmitPasswords(
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >
  ) {
    e.preventDefault();

    if (!formRef.current) return;
    formRef.current.classList.add('submitted');

    if (
      formState.password !== formState.confirmation ||
      formState.password.trim().length === 0
    )
      return;

    const body = {
      email: formState.email,
      code: formState.code,
      password: formState.password
    };

    props.resetPassword(
      body,
      formRef,
      formState,
      setErrors,
      setActiveStep,
      setIsLocalLoading
    );
  }

  return (
    <>
      <div className='auth-block-wrapper'>
        <LoadingOverlay isGlobal={true} active={isLoading} />

        {activeBlock === AuthSubBlock.Login ? (
          <LoginBlock
            isBlob={props.isBlob}
            isDashboard={props.isDashboard}
            isLoading={isLocalLoading}
            size={size}
            onGoogle={() => handleGoogleButtonClick(false)}
            onLogin={onLogin}
            onNavigateToForgotPassword={function (): void {
              setActiveBlock(AuthSubBlock.ResetPassword);
            }}
            onNavigateToSignUp={function (): void {
              setActiveBlock(AuthSubBlock.SignUp);
            }}
          />
        ) : activeBlock === AuthSubBlock.SignUp ? (
          <SignUpBlock
            isBlob={props.isBlob}
            isDashboard={props.isDashboard}
            isLoading={isLocalLoading}
            size={size}
            onGoogle={() => handleGoogleButtonClick(true)}
            onSignUp={onSignUp}
            onNavigateToForgotPassword={function (): void {
              setActiveBlock(AuthSubBlock.ResetPassword);
            }}
            onNavigateToLogin={function (): void {
              setActiveBlock(AuthSubBlock.Login);
            }}
          />
        ) : activeBlock === AuthSubBlock.ResetPassword ? (
          <ResetPasswordBlock
            isLoading={isLocalLoading}
            onNavigateToLogin={() => setActiveBlock(AuthSubBlock.Login)}
            onSubmitEmail={onSubmitEmail}
            onSubmitCode={onSubmitCode}
            onSubmitPasswords={onSubmitPasswords}
          />
        ) : null}
      </div>
    </>
  );
};
