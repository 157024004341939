import './index.scss';

import { createRef, CSSProperties, useEffect, useMemo, useState } from 'react';
import { HexAlphaColorPicker } from 'react-colorful';

import { IChatFolder } from '../../../../../../../../../external/extension/service/base/interfaces';
import { useOutsideClickDetector } from '../../../../../../../../../external/extension/tools/events';
import { GraphicTools } from '../../../../../../../../../external/extension/tools/graphics';
import { GradientWheelIcon } from '../../../../../../../../../external/extension/ui/design/assets/svg/icons/GradientWheelIcon';
import SettingsIcon from '../../../../../../../../../external/extension/ui/design/assets/svg/icons/SettingsIcon';
import { Button } from '../../../../../../../../../external/extension/ui/design/components/button';
import { Modal } from '../../../../../../../../../external/extension/ui/design/components/modal';
import { SmoothVisibility } from '../../../../../../../../../external/extension/ui/design/components/smoothVisibility';
import { folderColors } from '../../../../Folder';

export interface IFolderEditModal {
  folder: IChatFolder;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSave: (color: string, name: string) => void;
  onClose?: () => void;
}
interface INameValidation {
  tooShort: boolean;
  tooLong: boolean;
  onlySpaces: boolean;
}
export const FolderEditModal = (props: IFolderEditModal) => {
  const [activeColor, setActiveColor] = useState<string>(props.folder.color);
  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);

  const colorPickerWrapperRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    setActiveColor(props.folder.color);
  }, [props.folder.color, props.visible]);

  useOutsideClickDetector(colorPickerWrapperRef, () => {
    props.onClose?.();
    setColorPickerVisible(false);
  });

  const activeColorHex = useMemo(() => GraphicTools.everythingToHex(activeColor), [activeColor]);
  const isHexValid = activeColorHex !== null;

  const customColorUsed = !folderColors.some(color => activeColor === color);

  const customColorIconTint = useMemo(
    () => GraphicTools.chooseTextColorFor(activeColorHex ?? '#000000', 'white', 'black'),
    [activeColorHex]
  );

  const [folderName, setFolderName] = useState<string>(props.folder.name ?? '');
  const initValidationState = {
    tooShort: false,
    tooLong: false,
    onlySpaces: false,
  };
  const [validationState, setValidationState] = useState<INameValidation>(initValidationState);

  const handleSubmit = async () => {
    if (folderName.length === 0) {
      setValidationState(prev => ({ ...prev, tooShort: true }));

      return;
    }
    if (folderName.trim().length > 255) {
      setValidationState(prev => ({ ...prev, tooLong: true }));

      return;
    }
    if (folderName.length && folderName.trim().length === 0) {
      setValidationState(prev => ({ ...prev, onlySpaces: true }));

      return;
    }
    if (folderName.trim().length > 0) {
      document.dispatchEvent(new CustomEvent('alchemyRefreshChatFolders'));

      setFolderName('');
      setValidationState(initValidationState);
      props.setVisible(false);
    }
  };

  useEffect(() => {
    setFolderName(props.folder.name);
    setValidationState(initValidationState);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Modal
      title="Edit Folder"
      visible={props.visible}
      onClose={() => {
        props.onClose?.();
        if (!colorPickerVisible) props.setVisible(false);
      }}
      theme="blob"
      hasCloseButton={true}
      className="edit-folder-modal"
    >
      <input
        className="folder-name-input"
        onKeyDown={handleKeyDown}
        type="text"
        onChange={e => {
          setFolderName(e.currentTarget.value);
          setValidationState(initValidationState);
        }}
        placeholder="Enter folder name here"
        defaultValue={props.folder.name}
      />
      {validationState.tooShort && (
        <div className="error-message">Folder name cannot be shorter than 1 character.</div>
      )}
      {validationState.tooLong && (
        <>
          <div className="error-message">
            Folder name cannot be longer than 255 characters. <br />
            Your name is {folderName.length} characters long.
          </div>
        </>
      )}
      {validationState.onlySpaces && (
        <>
          <div className="error-message">Folder name cannot consist only of spaces.</div>
        </>
      )}
      <hr />
      <section>
        <div className="colors">
          {folderColors.map(color => {
            const isActive = activeColor === color;

            return (
              <button
                className={'color' + (isActive ? ' active' : '')}
                onClick={() => setActiveColor(color)}
                style={{ '--color': color } as CSSProperties}
                key={color}
              />
            );
          })}

          <button
            ref={colorPickerWrapperRef}
            className={'color custom color-picker-wrapper' + (customColorUsed ? ' active' : '')}
            style={
              {
                '--color': customColorUsed ? activeColor : undefined,
              } as CSSProperties
            }
          >
            <div
              className="color-picker-trigger"
              onClick={() => setColorPickerVisible(prev => !prev)}
            >
              <SmoothVisibility className="dynamic-icon" visible={!customColorUsed}>
                <GradientWheelIcon />
              </SmoothVisibility>

              <SmoothVisibility className="dynamic-icon" visible={customColorUsed}>
                <SettingsIcon color={customColorIconTint} />
              </SmoothVisibility>
            </div>

            <SmoothVisibility visible={colorPickerVisible} className="color-picker">
              <HexAlphaColorPicker
                color={activeColorHex ?? activeColor}
                onChange={color => setActiveColor(color)}
              />

              <div className="flex">
                <div className="flex-item">
                  <span>Hex code</span>
                </div>
                <div className="flex-item">
                  <input
                    className={!!activeColorHex && activeColorHex.length > 7 ? 'long' : undefined}
                    type="text"
                    value={activeColorHex ?? activeColor}
                    onChange={e => setActiveColor(e.target.value)}
                  />
                </div>
              </div>
            </SmoothVisibility>
          </button>
        </div>
      </section>
      <div className="alchemy-footer">
        <Button
          onClick={() => {
            props.setVisible(false);
            props.onSave(activeColor, folderName);
          }}
          text="Save"
          size="mini"
          disabled={!isHexValid}
          active={isHexValid}
        />
      </div>
    </Modal>
  );
};
